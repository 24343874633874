.button:not(.button-close) {
  cursor: pointer;
  font-weight: 500;
  left: 3px;
  line-height: inherit;
  position: relative;
  text-decoration: none;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
}

.button--small {
  padding: 10px 20px;
  font-size: 0.875rem;
}

.button--green {
  outline: none;
  background-color: #64d18a;
  border-color: #64d18a;
  color: white;
  transition: all 200ms ease;
}

.button--green:hover {
  background-color: #8bdda8;
  color: white;
}
.select__dropdown-scroll{
  overflow: scroll;
}
.file-drop-dragging-over-frame  .upload-content__drag-drop,
.file-drop-dragging-over-frame  .upload-content__files
{
  border: 3px dashed #7AB8EC;
  background: #FBFDFF;
}
.new__permissions{
  width: 100%;
}
.tag__search .search__dropdown{
  z-index: 5;
}
.wrap-btn-add{
  border: none;
}
.upload-content__drag-drop__old {
  padding: 40px 0 100px;
}
.upload-content__drag-drop svg {
  margin-left: auto;
  margin-right: auto;
}
.new__permissions input{
  background: #fff;
}
.upload-content__files{
  padding-top: 0;
}
.upload-content__files:after{
  content: none;
}
@media(max-width: 767px) {
  .upload-content__files-list .form-group:hover .upload-edit{
    display: block;
  }
}
.upload-content__files-list__options .button{
  padding: 0;
}

.checkout__long .content__guest > .guest__content--side .guest__summary .summary__total > .summary__total--button > button {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  background: #0E7D7D;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
  border-radius: 3px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  transition: 0.4s ease all;
}
.search__select--field input{
  background: #fff;
}
@media (max-width: 768px) {
  .select__two .select__multi--field .multi__dropdown .multi__list {
    max-height: 200px;
  }
}
#payment-element iframe{
  min-height: 250px!important;
}
.paymentTesting #payment-element iframe{
  min-height: unset!important;
}
.select__once--field .select__main--dropdown .search__select--dropdown,
.select__two .select__multi--field .multi__dropdown .multi__list{
  max-height: 195px;
}
.checkout__long .content__guest > .guest__content--main{
  & .summary__total--button{
    width: 100%;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    display:none;
    margin-bottom: 1rem;
    &>.summary__back--button{
      margin-top:10px;
      width: 100%;
      &>a.summary__back{
        width: 100%;
        display: inline-flex;
        align-items:center;
        justify-content:center;
        min-height:40px;
        background: #EBF1F1;
        border-radius: 3px;
        font-weight:600;
        transition:.4s ease all;
        &:hover{
          opacity:.7;
        }
        color:#0E7D7D;
        & span{
          color:#7DB7B7;
        }
      }
    }
    &>a{
      display: inline-flex;
      width: 100%;
      align-items:center;
      justify-content:center;
      min-height:40px;
      background: #0E7D7D;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      font-size:14px;
      line-height:20px;
      font-weight:600;
      color:#fff;
      text-decoration: none;
      transition:.4s ease all;
      &:active{
        background-color: #094f4f;
      }
      &:focus{
        color: #FFFFFF;
        background-color: #0a5858;

        box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
      }
      &:hover{
        background-color: #0a5858;
      }
      & span{
        color: rgba(255, 255, 255, 0.5);
        margin-right:4px;
      }
    }
  }
}

@media(max-width: 1024px) {
  .checkout__long .content__guest > .guest__content--main{
    & .summary__total--button{
      display: flex;
      margin-top: 5rem;
    }
  }
  .checkout__long {
    & .content__guest.step-1,
    & .content__guest.step-2
    {
      &> .guest__content--side .guest__summary .summary__total{
        & .summary__total--button{
          display: none;
        }
      }
    }
  }
}

.payment__method--grid{
  .payment-type{
    .payment-type-select{
      display: flex;
      justify-content: space-between;
    }
  }
}

