body, html{
	margin:0px;
	padding:0px;
}
body{
	background-color:#EBEBEF;
}
body *{
	box-sizing: border-box;
	font-family:"Open Sans" ,sans-serif;
}
.container-fluid{
	max-width:100%;
	width: 100%;
	padding-left:15px;
	padding-right:15px;
}
header{
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	background-color:#fff;
	position: fixed;
	top:0px;
	left: 0px;
	width: 	100%;
	z-index: 	16;
}
header .outer__header{
	padding-top:5px;
	padding-bottom:5px;
	display: flex;
	justify-content:space-between;
	align-items:center;
}
header .outer__header .left__header{
	display: flex;
	align-items:center;
	width: 100%;
}
header .outer__header .left__header .search__header form input[type="text"]{
	width: 100%;
	height: 38px;
	border-radius:50px;
	padding-left:20px;
	padding-right:50px;
	outline:none;
	border:0px;
	background-color:#F0F0F0;
}
header .outer__header .left__header .search__header form input[type="submit"]:hover{
	opacity:.7;
}
header .outer__header .left__header .search__header form input[type="submit"]{
	position: absolute;
	right: 20px;
	min-width:18px;
	max-width:18px;
	min-height:18px;
	max-height:18px;
	background:url('../images/searchicon.svg');
	background-repeat: no-repeat;
	background-size:auto;
	background-position:center;
	outline:none;
	border:0px;
	transition:.4s ease all;
	cursor:pointer;
}
header .outer__header .left__header .search__header form{
	display: flex;
	align-items:center;
	position: relative;
	width: 100%;
	max-width:425px;
}
header .outer__header .left__header .search__header{
	margin-left:70px;
	width: 100%;
	max-width:425px;
}
header .outer__header .left__header .logo__wrapper>a{
	display: flex;
	align-items:center;
	justify-content:center;
	transition:.4s ease all;
}
header .outer__header .left__header .logo__wrapper>a>img{
	margin-right:15px;
	max-width: 40px;
}
header .outer__header .left__header .logo__wrapper>a:hover{
	opacity:.7;
}
header .outer__header .left__header .logo__wrapper>a{
	color:#003737;
	font-size:16px;
	font-weight:600;
	text-decoration: none;
	transition:.4s ease all;
}
header .outer__header .left__header .logo__wrapper>a:hover{
	opacity:.7;
}
header .right__header .profile__header>a{
	display: flex;
	align-items: center;
	justify-content:flex-start;
	text-decoration: none;
}
header .right__header .profile__header>a .profile__pict{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	margin-right: 10px;
}
header .right__header{
	display: flex;
	align-items:center;
}
header .right__header .language__picker .lang__image{
	margin-right:5px;
}
header .right__header .language__picker>a:hover{
	opacity:.7;
}
header .right__header .language__picker>a{
	text-decoration: none;
	transition:.4s ease all;
}
header .right__header .language__picker>a .lang__image>span{
	display: flex;
	align-items:center;
}
header .right__header .language__picker>a .lang__image{
	margin-right:10px;
	display: flex;
	align-items:center;
}
header .right__header .language__picker>a .lang__info p{
	margin:0px;
	font-weight:600;
	font-size:13px;
	line-height:17px;
	color:rgba(59, 59, 59, 1);
}
header .right__header .language__picker>a .lang__arrow{
	display: flex;
	align-items:center;
	justify-content:center;
	margin-left:10px;
}
header .right__header .header__notification a{
	text-decoration: none;
	transition:.4s ease all;
}
header .right__header .header__notification a:hover{
	opacity:.7;
}
header .notifications-dropdown__toggle-title{
	min-width:27px;
	min-height:22px;
	display: inline-flex;
	font-size:12px;
	font-weight:700;
	color:rgba(14, 125, 125, 1);
	align-items:center;
	justify-content:center;
	background: rgba(14, 125, 125, 0.1);
	border-radius: 6px;
}
header .right__header .header__notification a .notification__icon{
	display: flex;
	align-items:center;
	justify-content:center;
	margin-right:10px;
}
header .right__header .header__notification a{
	display: flex;
	align-items:center;
}
header .right__header .header__notification{
	margin-right:10px;
}
header .right__header .language__picker>a{
	display: flex;
	align-items:center;
}
header .right__header .language__picker{
	display: flex;
	align-items:center;
	margin-right:10px;
}
header .right__header .profile__header>a{
	transition:.4s ease all;
}
header .right__header .profile__header>a:hover{
	opacity:.7;
}
header .right__header .profile__header>a .profile__name p{
	margin:0px;
	font-size:13px;
	font-weight:600;
	color:#3B3B3B;
}
header .right__header .profile__header>a .profile__pict img{
	max-width:40px;
}
header .right__header .profile__header>a>span{
	display: inline-flex;
	margin-left:10px;
}
header .right__header .profile__header>a>span>img{
	min-width: 9px;
}
header .right__header .language__picker>a .lang__arrow>img{
	min-width: 9px;
}
.side__menu{
	padding-left:5px;
	padding-right:5px;
	position: fixed;
	top:60px;
	height: calc(100vh - 60px);
	left: 0px;
	min-width:200px;
	max-width:200px;
	padding-top:20px;
	background: #003737;
	overflow-y:auto;
}
.side__menu .head__side>ul>li>a , .menu__main--side>ul>li>a{
	display: flex;
	align-items:center;
	justify-content:space-between;
	text-decoration: none;
	padding:10px 15px;
}
.side__menu .head__side>ul , .menu__main--side>ul{
	padding:0px;
	list-style-type: none;
	margin:0px;
}
.side__menu .head__side>ul>li>a>.image__side , .menu__main--side>ul>li>a>.image__side{
	display: flex;
	align-items:center;
	justify-content:center;
	margin-right:12px;
}
.side__menu .head__side>ul>li>a>.image__side>img , .menu__main--side>ul>li>a>.image__side>img{
	max-width:16px;
}

.side__menu .head__side>ul>li>a , .menu__main--side>ul>li>a{
	font-size:14px;
	justify-content:flex-start;
	color:rgba(255,255,255,.5);
	display: flex;
	width: 100%;
}
.side__menu .head__side>ul>li>a>span.numb__span , .menu__main--side>ul>li>a>span.numb__span{
	background: rgba(255, 255, 255, 0.1);
	border-radius: 6px;
	min-width:27px;
	min-height:22px;
	display: inline-flex;
	align-items:center;
	justify-content:center;
	font-size:12px;
	font-weight:700;
	color:#fff;
	margin-left:10px;
	padding:3px 10px;
	margin-left:auto;
}
.side__menu{
	padding-top:25px;
}
.side__menu .head__side{
	padding-bottom:20px;
	border-bottom:2px solid rgba(0,0,0,.1);
}
.menu__main--side ul{
	padding:0px;
	list-style-type: none;
}
.menu__main--side {
	margin-top:20px;
	padding-bottom:12px;
	border-bottom:2px solid rgba(0,0,0,.1);
}
.head__side>ul>li>a , .menu__main--side>ul>li>a{
	transition:.4s ease all;
	border-radius:3px;
}
.head__side>ul>li>a img , .menu__main--side>ul>li img{
	opacity:.5;
	transition:.4s ease all;
}
.head__side>ul>li>a:hover img , .menu__main--side>ul>li>a:hover img{
	opacity: 1;
}
.head__side>ul>li>a:hover , .menu__main--side>ul>li>a:hover{
	background: rgba(255, 255, 255, 0.1);
}
.head__side>ul>li>a:hover , .menu__main--side>ul>li>a:hover{
	color:#fff;
}
.head__side>ul>li>a .numb__span , .menu__main--side>ul>li>a .numb__span{
	transition:.4s ease all;
}

.side__menu .head__side>ul>li>a:hover .numb__span , .menu__main--side>ul>li>a:hover .numb__span{
	color:rgba(14, 125, 125, 1);
	background-color:#fff;
}
.content__main{
	padding-left:200px;
	width: 100%;
	min-height:100vh;
}
.menu__button--header{
	display: none;
}
.content__main>.menu__small>a:hover{
	opacity:.7;
}
.content__main>.menu__small>a{
	font-size:24px;
	color:#003737;
	transition:.4s ease all;
}
.content__main>.menu__small{
	display: none;
}
.content__main{
	padding-right:20px;
	padding-bottom:20px;
	padding-top: 80px;
	padding-left:220px;
}
.menu__main--side>p{
	margin:0px;
	padding-left:15px;
	padding-right: 15px;
	font-weight:600;
	font-size:12px;
	letter-spacing:0.15em;
	margin-bottom:10px;
	text-transform: uppercase;
	color:rgba(255,255,255,.6);
}
.notification__icon img{
	min-width: 16px;
}
header .right__header .language__picker>a .lang__image>span>img{
	min-width: 21px;
}
.content__main>.application-page__row>.application-page__form{
	margin-top: 	10px;
}
.application-form__item>.button , .dropdown__toggle >.button  , .column-group__item>.button{
	    background-color: #0E7D7D;
}
.container__top{
	padding-top: 45px;
}
.header__notification{
	position: 	relative;
}
.header__notification .dropdown__box{
	right: 	0px;
	left: 	auto;
	min-height: 250px;
	max-height: 250px;
}
.user-dropdown__toggle{
	padding:5px 10px;
}
.form__wrapper{
	padding-top:80px;
	padding-left:15px;
	padding-right:15px;
	max-width:1280px;
	margin-left:auto;
	margin-right:auto;
}
.menu__main--side>ul>li.current__menu>a img{
	opacity:1;
}
.menu__main--side>ul>li.current__menu>a{
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
}
.outer__header{
	min-height:60px;
}
.upload__block.upload-content__files{
	max-width:540px;
}
.column-group__item>.button--color-brick-red{
	background-color:#fff;
}
.column-group__item>.button--color-brick-red:hover{
	background-color:#f2f2f2;
}
.dropdown__toggle >.button{
	background-color:#fff;
}
.button--border-surfie-green:hover , .button--border-surfie-green:active{
	background-color:#0a5858;
}
.button--border-surfie-green:active .svg-icon--surfie-green{
	fill:#fff;
}
.manage-skills-dropdown__toggle .button--border-surfie-green:focus{
	background-color:#0E7D7D!important;
}
.invite-employee-dropdown__toggle>.button--surfie-green{
	background-color: #0E7D7D;
}
.notifications-dropdown__toggle-icon>svg{
	max-width:16px;
}

.manage-position-dropdown__toggle>button:focus{
	background-color:#0E7D7D!important;
}
.skills-dropdown>.dropdown__toggle >button:focus{
	background-color:#0E7D7D!important;
}
.text-waiting-yellow{
	background: #FBE7D5!important;
	color:#F09000!important;
	border-radius:3px;
	display: inline-flex;
	align-items:center;
	justify-content:center;
	padding:10px;
}
.catalog__content-item .table-holder table tr>th{
	color: #9E9E9E;
	font-weight: 600;
	font-size: 14px;
}
.id__info>p{
	margin:0px;
	color:#2D2D2D;
	font-size:14px;
	line-height:21px;
	font-weight:400;
}
.catalog__tab{
	margin-right:5px;
	background: rgba(255, 255, 255, 0.5);
}
.catalog__tab:last-child{
	margin-right: 0px;
}
.catalog__right .button__filter svg {
	width: 16px;
}
.catalog__right .button__filter{
	padding-left:5px;
	padding-right:5px;
	min-width:54px;
}
.lang-combinations-dropdown__box .column-group__item p{
	color:rgba(61, 61, 61, 1);
}
.lang-combinations-dropdown__box{
	background: #FFFFFF;
	box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
}
.choose-actions-dropdown__box .actions__action{
	border-radius:4px;
	width:calc(100% - 20px);
	margin-left:10px;
	padding-left:15px;
	padding-right:15px;
	min-height:40px;
	
}
.choose-actions-dropdown__box .actions__action:hover{
	background: #EBF1F1;
}
.choose-actions-dropdown__box .actions__action-title{
	transition:.4s ease all;
	color:rgba(14, 125, 125, 1);
}
.lang-combinations-dropdown__toggle{
	border:1px solid #0E7D7D;
}
.choose-actions-dropdown__box .actions__reject .actions__action-title{
	color:rgba(255, 50, 50, 1);
}
.choose-actions-dropdown__box .actions__reject:hover{
	background: #FFD6D6;
}
.employers-page__heading{
	margin-bottom:20px;
}
.centered__table>h5{
	text-align:center;
}
.centered__table--content>.row-group{
	justify-content:center;
}
.waiting__block>.text-suspended{
	background: #DAE9F5;
	border-radius: 3px;
	color:rgba(4, 104, 188, 1)!important;
	padding:10px;
}
.waiting__block>.text-temporary{
	background: #DAE9F5;
	color:rgba(4, 104, 188, 1)!important;
	border-radius:3px;
	padding:10px;
}
.waiting__block>.text-approved{
	background: rgba(0, 200, 104, 0.1);
	border-radius: 3px;
	padding:10px;
	color:rgba(0, 200, 104, 1)!important;
	font-weight:600!important;
}
.waiting__block>p{
	width: 100%;
	/*max-width:165px;*/
	width: 	auto;
	min-width: 170px;
	font-weight:600!important;
	display: flex;
	justify-content:center;
	text-align:center;
	align-items:center;
	white-space: nowrap;
}
.centered__table--content>.drop__wrapper{
	display: flex;
	justify-content:center;
	align-items:center;
}
.centered__table--content>.waiting__block{
	display: flex;
	justify-content:center;
	align-items:center;
}
.catalog__content .table td{
	padding:7px 15px;
}
.button__action{
	width: 19px;
	height: 19px;
	border:1px solid #0E7D7D;
	display: inline-flex;
	align-items:center;
	justify-content:center;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
	border-radius: 2px;
}
.button__action:hover{
	background-color:#0E7D7D;
}
.dropdown__toggle.is-active .button__action svg{
	position: relative;
	left: -1px;
}
.dropdown__toggle.is-active .button__action svg path{
	fill:#fff;
}
.dropdown__toggle.is-active .button__action{
	background-color:#0e7D7d;
}
.button__action svg path{
	transition:.3s ease all;
}
.button__action:hover svg path{
	fill:#fff;
}
.button.button--no-events{
	padding:10px 15px;
}
.catalog__content>.catalog__content-item>.catalog__content-wrap{
	padding-left:45px;
	padding-right:45px;
	padding-bottom:55px;
}
.catalog__content-wrap>a , .catalog__content-wrap>.row>a{
	display: none;
}
.wrapper__general--info .column-heading>h5{
	color:rgba(45, 45, 45, 1);
	font-size:16px;
	font-weight:700;
}
.wrapper__general--info .column-heading{
	border-bottom:0px;
	margin-bottom:10px;
}
.wrapper__general--info{
	display: flex;
	align-items:flex-start;
	justify-content:space-between;
	width: 100%;
	margin-top:30px;
}
.wrapper__general--info .bank__block{
	min-width:260px;
	max-width:260px;
	margin-left:50px;
	margin-right:50px;
}
.wrapper__general--info .preffered__client{
	width: 100%;
	max-width:320px;
}
.wrapper__general--info .preffered__client .chip--border-silver-chalice .chip__title{
	color:#3F88C5;
}
.wrapper__general--info .preffered__client .chip--border-silver-chalice{
	border-color:#3F88C5;
}
.wrapper__general--info .general__block .info-list__right>p , .wrapper__general--info .bank__block .info-list__right>p{
	font-size:14px;
	font-weight:600;
	color:#3B3B3B;
}
.profile__head{
	display: flex;
	align-items:center;
	flex-direction:row;
	margin-right:25px!important;
	width: 100%;
	justify-content:space-between;
}
.profile__head.column-group--sm > .column-group__item:not(:last-child){
	margin-bottom: 0px;
}
.profile__head>.left__profile{
	display: flex;
	align-items:center;
}
.profile__head .avatar--xl{
	width: 100px;
	height: 100px;
}
.profile__head .edit-avatar{
	margin-right:30px;
}
.name__profile .head__title--profile{
	margin-bottom:15px;
	display: flex;
	align-items:flex-end;
	justify-content:flex-start;
}
.name__profile .head__title--profile>p{
	margin:0px;
	margin-left:15px;
	color:rgba(0, 0, 0, 1);
	font-size:14px;
	line-height:21px;
	font-weight:400;
}
.name__profile .chip .chip__dots{
	border-left:1px solid rgba(14,125,125,.25);
}
.name__profile .chip__title{
	color:rgba(14, 125, 125, 1);
}
.name__profile .chip{
	min-height:40px;
	border-color:#0E7D7D;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
}
.name__profile .chip__title{
	display: flex;
	align-items:center;
}
.name__profile .head__title--profile>h6{
	margin-bottom:0px;
	font-size:18px;
	font-weight:700;
	line-height:24px;
	color:rgba(45, 45, 45, 1);
}
.profile__head .name__profile{
	display: flex;
	flex-direction:column;
	justify-content:flex-start;
}
.profile__head .edit-avatar__button button{
	min-width: 40px;
	min-height: 40px;
	max-width:40px;
	max-height:40px;
	padding:0px;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}
.profile__head .edit-avatar__button{
	right: -5px;
	bottom:-5px;
	padding:0px;
}
.columns__head--profile.reset__profile>.column-group__item>.link:hover{
	opacity:.7;
}
.columns__head--profile.reset__profile>.column-group__item>.link{
	text-decoration: underline;
	margin-left:20px;
	transition:.4s ease all;
}
.reset__profile .buttons__columns .js-suspend-buttons{
	margin-right: 20px;
}
.reset__profile .buttons__columns .column-group__item{
	display: flex;
	align-items:center;
}
.settings-page__catalog .catalog__content{
	padding-bottom: 0px;
}
.settings-page__catalog .catalog__tab {
	height: 42px;
}
.settings-page__catalog .catalog__tab.is-active>.catalog__tab-title{
	font-weight:700;
}
.settings-page__catalog .catalog__tab>.catalog__tab-title{
	font-size:16px;
	font-weight:600;
}
.w-full{
	width: 100%;
}
.wrap__another--drop{
	position: relative;
}
div.wrap__another--drop .btn-add__dropdown{
	min-width:100px;
}
.settings-page__wrap .settings-page__heading .settings-page__heading-title>a svg , .permissions-page__heading .permissions-page__heading-title>a svg{
	transition:.4s ease all;
}
.settings-page__wrap .settings-page__heading .settings-page__heading-title>a svg path , .permissions-page__heading .permissions-page__heading-title>a svg path{
	fill:#fff;
}

.settings-page__wrap .settings-page__heading .settings-page__heading-title>a:focus , .permissions-page__heading .permissions-page__heading-title>a:focus {
	    box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
}
.settings-page__wrap .settings-page__heading .settings-page__heading-title>a , .permissions-page__heading .permissions-page__heading-title>a{
	min-width:32px;
	max-width:32px;
	min-height:32px;
	max-height:32px;
	display: inline-flex;
	justify-content:center;
	align-items:center;
	margin-right: 10px;
	background: #FFFFFF;
	border: 1px solid #0E7D7D;
	border-radius: 4px;
	transition:.4s ease all;
}
.settings-page__wrap .settings-page__heading .settings-page__heading-title>a , .permissions-page__heading .permissions-page__heading-title>a{
	background-color:#0E7D7D;
}
.settings-page__wrap .settings-page__heading .settings-page__heading-title , .permissions-page__heading .permissions-page__heading-title{
	margin-bottom: 0px;
	display: flex;
	align-items:center;
}
.settings-page__wrap .settings-page__heading .settings-page__heading-title>h2>span{
	display: inline-flex;
	margin-left:25px;
	padding:5px 10px;
	background: #FFFFFF;
	border-radius: 5px;
	font-size:20px;
	line-height:27px;
	font-weight:700;
	color:rgba(45, 45, 45, 1);
}
.settings-page__wrap .settings-page__heading{
	display: flex;
	justify-content:space-between;
	align-items:center;
	margin-bottom:20px;
}
.settings-page__heading-status{
	display: flex;
	align-items:center;
}
.settings-page__heading-status>h3{
	font-size:14px;
}
.settings-page__heading-status>.approved__wrapper>span{
	font-size:14px;
	font-weight:700;
	color:rgba(0, 200, 104, 1);
}
.settings-page__heading-status>.approved__wrapper{
	border: 1px solid #00C868;
	border-radius: 5px;
	padding:15px 20px;
	background-color:#fff;
	margin-left:20px;
}
.wrapper__general--info .general__block{
	max-width:365px;
}
.preffered-list .preffered-list__item .chip__title{
	color:rgba(45, 45, 45, 1);
}
.catalog__content-wrap .column-heading{
	border-bottom: 0px;
}
.catalog__content-wrap .row-group__item .manage-skills-dropdown__toggle>button>svg{
	width: 14px;
	height: 14px;
}
.catalog__content-wrap .dropdown__toggle >.button{
	min-width:30px;
	max-width:30px;
	min-height:30px;
	max-height:30px;
}
.catalog__content-wrap .column-heading .row-group__item>h5 , .catalog__content-wrap .column-heading>h5{
	color:rgba(59, 59, 59, 1);
	font-size:16px;
	font-weight:700;
}
.preffered-list .preffered-list__item .chip--border-silver-chalice{
	border-color:rgba(14, 125, 125, 1);
}
.catalog__content-wrap .column-heading .dropdown__toggle svg{
	width: 14px;
	height: 14px;
}
.catalog__content-wrap .column-heading>h5{
	margin-right: 20px;
}
.catalog__content-wrap .column-heading{
	display: flex;
	align-items:center;
}
.catalog__content-wrap .info-list__left{
	padding-right:20px;
}
.catalog__content-wrap .upload__block.upload-content__drag-drop__old{
	max-width:340px!important;
}
.right__header .header__communication{
	margin-right:20px;

}
.right__header .header__communication a{
	transition:.4s ease all;
	border-radius: 10px;
    padding:10px;
    transition:.4s ease all;
}
.right__header .header__communication a:hover{
    background-color: #F7F7F7;
}
.right__header .header__communication a>span{
	margin-left:10px;
	display: inline-flex;
	align-items:center;
    min-width: 27px;
    min-height: 22px;
    font-size: 12px;
    font-weight: 700;
    color: rgba(14, 125, 125, 1);
    background: rgba(14, 125, 125, 0.1);
    border-radius: 6px;
	justify-content:center;
}
.right__header .header__communication a>img{
	width: 18px;
	min-width:18px;
}
.right__header .header__communication a{
	display: flex;
	align-items:center;
	justify-content:center;
}
.permissions-page__heading{
	display: flex;
	align-items:center;
	justify-content:space-between;
	margin-bottom:25px;
}
.permissions-page__heading-title>h2>span{
	display: inline-flex;
    margin-left: 25px;
    padding: 5px 10px;
    background: #FFFFFF;
    border-radius: 5px;
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
    color: rgba(45, 45, 45, 1);
}
.main__stat .icon-title__icon{
	margin-right:15px;
	min-width:27px;
	display: flex;
	align-items:center;
	justify-content:center;
}
.table__stat .row-group__item>button{
	padding:10px 2px;
}
.end__buttons{
	display: flex;
	justify-content:flex-end;
}
.table__stat th>h5{
	color:#3D3D3D;
	font-size:14px;
	padding-bottom:5px;
	font-weight:600;
}
.main__stat p{
	font-weight:600;
	color:#2D2D2D;
	font-size:14px;
}
.permissions-page__heading>.permissions-page__heading-title{
	margin-bottom: 0px;
}
.table__stat{
	padding-left:15px;
	padding-right:15px;
}
.table__stat .table th:first-child{
	padding-left: 15px;
}
.table__stat .center__stat>.row-group{
	display: flex;
	justify-content:center;
	align-items:center;
}
.table__stat .center__stat{
	text-align:center;
	position: relative;
}
.center__stat .float__fee .elem__fee:last-child{
	margin-bottom: 0px;
}
.urgent__fee p span{
	color:rgba(0, 200, 104, 1);
	font-size:18px;
	line-height:24px;
}

.rep__fee p span{
	color:rgba(255, 50, 50, 1);
}
.center__stat .float__fee .elem__fee p{
	margin:0px;
	font-size:18px;
	line-height:24px;
	font-weight:400;
	color:rgba(45, 45, 45, 1);
}
.center__stat .float__fee .elem__fee{
	margin-bottom:12px;
}
.center__stat .float__fee .elem__fee:last-child{
	margin-bottom: 0px;
}
.center__stat .float__fee{
	position: absolute;
	left: 0px;
	top:5px;
	padding:15px 20px;
	background: #FFFFFF;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	right: 0px;
	margin-left:auto;
	margin-right:auto;
	max-width:175px;
}
.table__buttons .close__button:hover{
	background: rgba(255, 50, 50, 0.1);
}
.table__buttons .close__button{
	min-width:30px;
	max-width:30px;
	min-height:30px;
	max-height:30px;
}
.table__buttons .close__button:focus{
	border-color:#FF3232;
	background: rgba(255, 50, 50, 0.1);
}
.table__buttons .default__button:focus{
	border-color:#0E7D7D;
	background: rgba(14, 125, 125, 0.1);
}
.table__buttons .default__button:hover{
	background: rgba(14, 125, 125, 0.1);
}
.table__buttons .default__button{
	min-width:30px;
	border:1px solid transparent;
	max-width:30px;
	min-height:30px;
	max-height:30px;
	display: inline-flex;
	align-items:center;
	justify-content:center;
	border-radius:3px;
	transition:.4s ease all;
}
.icon__wrapper{
	display: flex;
	align-items:center;
}
.icon__wrapper p span{
	display: inline-flex;
	margin-right:12px;
	min-width: 27px;
	justify-content:center;
	align-items:center;
}
.icon__wrapper p{
	margin:0px;
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.table__stat .width__stat{
	min-width:175px;
}
.create__service>a>span{
	display: inline-flex;
	margin-right:20px;
}
.create__service>a{
	min-width:175px;
	min-height:45px;
	background: #FFFFFF;
	border: 1px solid #0E7D7D;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	display: flex;
	color:#0E7D7D;
	font-weight:600;
	font-size:14px;
	transition:.4s ease all;
	align-items:center;
	justify-content:flex-start;
	padding-left:20px;
	padding-right:20px;
	background:url('../images/svg/svg-sprite/symbol/buttonchevron.svg');
	background-repeat: no-repeat;
	background-size:auto;
	background-position:92% center;
	background-color:#fff;
}
.create__service>a:hover{
	opacity:.7;
}
.create__service{
	margin-top:25px;
}
.modal__create{
	position: fixed;
	top:0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 15;
	background-color:rgba(0,0,0,.7);
}
.inner__modal>a:active , .inner__modal>a:focus{
	border-color:#fff;
}
.inner__modal>a:hover{
	background: #5C5A64;
}
.inner__modal>a{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	position: absolute;
	left: -75px;
	top:20px;
	min-width:50px;
	max-width:50px;
	min-height:50px;
	max-height:50px;
	transition:.4s ease all;
}
.inner__modal>form>h2{
	font-size:25px;
	font-weight:700;
	color:rgba(14, 125, 125, 1);
	margin:0px;
	margin-bottom:30px;
}
.inner__modal{
	width: 100%;
	max-width:660px;
	height: calc(100% - 60px);
	margin-left:auto;
	margin-top:60px;
	background: #FFFFFF;
	box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	position: relative;
	overflow-y:auto;
	padding:50px 45px;
}
.inner__modal .row__form .form-group .form-group__header{
	margin-bottom:15px;
}
.inner__modal .row__form .form-group .form-group__header>p{
	color:rgba(45, 45, 45, 1);
	font-size:14px;
	font-weight:600;
	margin-bottom: 0px;
}
.inner__modal .row__form{
	display: grid;
	grid-template-columns:repeat(3, 1fr);
	grid-column-gap:15px;
	align-items:flex-end;
	margin-bottom:30px;
}
.inner__modal .row__form:last-child{
	margin-bottom: 0px;
}
.price__item--form>p{
	margin:0px;
	position: absolute;
	left: 66px;
	color:rgba(45, 45, 45, 1);
	font-size:14px;
	line-height:21px;
	font-weight:400;
}
.price__item--form{
	position: relative;
	display: flex;
	align-items:center;
}
.price__item--form>input{
	padding-left:25px;
}
.price__item--form>span{
	position: absolute;
	left: 10px;
}
.inner__modal .row__form.row__form--group>.form-group{
	width: 100%;
}
.inner__modal .row__form.row__form--group .float__span span{
	font-size:18px;
	line-height:24px;
	font-weight:400;
}
.inner__modal .row__form.row__form--group .float__span{
	position: relative;
	bottom:-15px;
}
.fee__group{
	max-width:156px;
}
.inner__modal .row__form.row__form--group{
	display: flex;
	align-items:center;
	grid-template-columns:none;
	width: 100%;
	position: relative;
}
.add__more span{
	display: inline-flex;
	margin-right: 10px;
}
.add__more:hover{
	opacity:.7;
}
.add__more{
	color:rgba(14, 125, 125, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
	transition:.4s ease all;
	display: flex;
	align-items:center;
}
.wrapper__row{
	margin-bottom:30px;
}
.wrapper__row:last-child{
	margin-bottom: 0px;
}
.wrapper__smaller.wrapper__smaller .form-group__header p{
	font-size:16px;
}
.wrapper__smaller.wrapper__smaller{
	max-width:340px;
}
.controls__create{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.controls__create>button{
	min-width:125px;
}
.row__form .form-group>input{
	height: 50px;
}
.row__form .form-group .select__label , .price__item--form>input{
	height: 50px;
}
.controls__create>input[type="submit"]{
	margin-right:15px;
	max-width:125px;
}
.controls__create>input[type="submit"]:hover{
    background-color: #0a5858;
}
.controls__create>input[type="submit"]:focus{
    color: #FFFFFF;
    background-color: #0a5858;
    -webkit-box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
    box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
}
.controls__create>input[type="submit"]:active{
	color: #FFFFFF;
    background-color: #094f4f!important;
}
.modal__create>a{
	position: absolute;
	top:70px;
	right: 675px;
}
.login-page .form-box{
	max-width:500px;
	margin-left:auto;
	margin-right:auto;
}
.login-page .form-box__icon>img{
	max-width:50px;
}
.login-page:before{
	opacity: 1;
}
.login-page .form-box{
	height: auto;
	min-height:600px;
}
.login-page .form-box__icon{
	margin-bottom:15px;
}
.login-page .input , .login-page .button--fluid{
	height: 45px;
}
div.login-page__lang{
	top:95px;
}
div.login-page:before{
	width: 60%;
	height: 60%;
}
.login-page .form-box__form{
	margin-bottom:20px;
}
.form-divider__text{
	font-weight:600;
	color:#2D2D2D;
}
.login-page .form-box{
	padding-left: 50px;
	padding-right:50px;
	padding-bottom:45px;
}
.login-page .input-animation.is-active .input-animation__label{
	color:#2D2D2D;
	font-weight:400;
	font-size:12px;
}
.login-page .form-box__title>h2{
	font-size:25px;
	font-weight:bold;
}
.status__login{
	display: flex;
	align-items:center;
}
header .header__dropdown-lang .select__dropdown{
	margin-top: 0px;
}
.service__creation.upload__block{
	display: none;
}

.choose-actions-dropdown__box .actions__action{
	border:1px solid transparent;
}
.choose-actions-dropdown__box .actions__action:focus , .choose-actions-dropdown__box .actions__action:active{
    background-color: #FFF;
    border-color: #B7D8D8;
    text-decoration: none;
}
.choose-actions-dropdown__box .actions__action.actions__reject:focus , .choose-actions-dropdown__box .actions__action.actions__reject:active{
	border-color:#FFD6D6;
	background-color:#fff;
	text-decoration: none;
}
.upload__block .upload-content__files-list .form-group .button-close{
	padding:0px!important;
	top:0px!important;
}
.upload__block .upload-content__files-list .form-group .button-close:focus{
	    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgZmlsbD0iI2ZmNWI1YiI+PHBhdGggZD0iTTE0LjY1OSAxMy4xOTNMMTIuODUxIDE1IDAgMi4xNDkgMS44MDcuMzQxeiIvPjxwYXRoIGQ9Ik0xMi44NTIgMGwxLjgwNyAxLjgwN0wxLjgwOCAxNC42NTkuMDAxIDEyLjg1MXoiLz48L3N2Zz4=) center no-repeat;
    background-size: 13px auto;
}
.settings__profile.reset__profile .column-group__item>div{
	margin-left: 0px!important;
}
.left__profile>.column-group__item{
	margin-bottom: 0px;
}
.settings__general--info.wrapper__general--info{
	justify-content:flex-start;
}
.upload__block .upload-content__files-list .form-group{
	padding:5px;
}
.plus__button{
	display: flex;
	align-items:center;
	min-height:25px;
}
.edit__dropdown--toggle>div>.button{
	width: 100%;
	justify-content:flex-start;
	padding-left:10px;
}
.edit__dropdown--toggle .btn-add__icon{
	margin-left:auto;
	    width: 45px;
    height: 45px;
    cursor: pointer;
    border-radius: 4px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjUgNi43NzUxM0wwIDAuODUyMDUxSDExTDUuNSA2Ljc3NTEzWiIgZmlsbD0iIzBFN0Q3RCIvPgo8L3N2Zz4K) center no-repeat;
    transition: all 0.4s ease;
    position: relative;
}
.edit__dropdown--toggle>div{
	width: 100%;
}
.edit__dropdown--toggle{
	min-width:90px;
}
.permissions__button{
	margin-bottom: 0px;
}
.edit__dropdown--toggle svg{
	width: 20px;
	height: 20px;
}
.controls__permissions.upload__block .wrap-btn-add{
	padding-left: 0px!important;
	padding-right: 0px!important;
}
.controls__permissions .btn-add__dropdown .btn-add__list li a{
	padding-left:10px;
}
.permissions__catalog{
	display: flex;
	align-items:center;
}
.controls__permissions.upload__block .btn-add__input_add .btn-add__input-label{
	padding-right: 0px;
	height: 40px;
}
.controls__permissions.upload__block .btn-add__icon{
	height: 40px;
}
.permissions__catalog .catalog__header .catalog__tabs{
	margin-right:7px;
}
.upload__block.controls__permissions .dropdown__box.manage-position-dropdown__box{
	display: block;
}
.upload__block.controls__permissions .dropdown__box.manage-position-dropdown__box .button{
	background-color:#0E7D7D;
}
.manage-position-dropdown__box{
	margin-top: 0px;
}

.service__creation .create__service{
	margin-top: 0px;
}
.settings-page__heading{
	min-height:55px;
}
.clients__table.catalog__content-item .table-holder tr>th:nth-child(1) , .clients__table.catalog__content-item .table-holder tr>td:nth-child(1){
	width: 140px;
}
.catalog__create--client .catalog__box{
	height: auto;
	min-height:65vh;
}
.client__heading.employers-page__heading-title>a svg path{
	fill:#fff;
}
.client__heading.employers-page__heading-title>a>span{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	margin-left:15px;
	padding-bottom:2px;
}
.create__project:focus , .create__project:active , .create__order:focus , .create__order:active{
	color:#fff;
}
.create__project  path , .create__order path{
	fill:#fff;
}
.create__project span , .create__order span{
	margin-left:15px;
}
.client__heading.employers-page__heading-title>a:hover{
    background-color: #0a5858;
}
.client__heading.employers-page__heading-title>a:focus{
    color: #FFFFFF;
    background-color: #0a5858;
    -webkit-box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
    box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
}
.client__heading.employers-page__heading-title>a:active{
	color: #FFFFFF;
    background-color: #094f4f!important;
}
.client__heading.employers-page__heading-title>a , .create__project , .create__order{
	display: inline-flex;
	font-size: 14px;
	font-weight:600;
	color:#fff;
	justify-content:center;
	align-items:center;
	min-width:160px;
	max-width:160px;
	min-height:40px;
	background: #0E7D7D;
	border: 1px solid #0E7D7D;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	width: auto;
	transition:.2s ease all;
}
.offer__deadline--progress .deadline__offer--form form>input[type="submit"]:hover{
    background-color: #0a5858;
}
.offer__deadline--progress .deadline__offer--form form>input[type="submit"]:focus{
    color: #FFFFFF;
    background-color: #0a5858;
    -webkit-box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
    box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
}
.offer__deadline--progress .deadline__offer--form form>input[type="submit"]:active{
	color: #FFFFFF;
    background-color: #094f4f!important;
}
a.create__order:hover{
    background-color: #0a5858;
}
a.create__order:focus{
    color: #FFFFFF;
    background-color: #0a5858;
    -webkit-box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
    box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
}
a.create__order:active{
	color: #FFFFFF;
    background-color: #094f4f!important;
}
.client__heading.employers-page__heading-title{
	display: flex;
	align-items:center;
	justify-content:space-between;
	margin-right: 0px;
	width: 100%;
}
.modal__client{
	position: fixed;
	top:0px;
	left: 0px;
	width: 100%;
	height: 100%;
    z-index: 15;
    background-color: rgba(0,0,0,.7);
}
.modal__client>.inner__modal--client>form>h2{
	font-size: 25px;
    font-weight: 700;
    color: rgba(14, 125, 125, 1);
    margin: 0px;
    margin-bottom: 30px;
}
.modal__client>.inner__modal--client{
    width: 100%;
    max-width: 660px;
    right: -670px;
    height: calc(100% - 60px);
    margin-left: auto;
    margin-top: 60px;
    background: #FFFFFF;
    box-shadow: 5px 10px 30px rgb(0 0 0 / 10%);
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    position: relative;
    overflow-y: auto;
    padding: 50px 45px;
    transition:.4s ease all;
}
.modal__client>a{
    position: absolute;
    top: 70px;
    right: 675px;
    transition:.4s ease all;
    opacity:0;
    min-width:50px;
    max-width:50px;
    min-height:50px;
    max-height:50px;
    border-radius:5px;
    display: inline-flex;
    align-items:center;
    justify-content:center;
    border:1px solid transparent;
}
.modal__client>a:active , .modal__client>a:focus{
	border-color:#fff;
}
.modal__client>a:hover{
	background: #5C5A64;
}
.modal__client .double__client:last-child{
	margin-bottom: 0px;
}
.modal__client .double__client>.or__field span{
	display: inline-flex;
	color:rgba(14, 125, 125, 1);
	font-size:14px;
	font-weight:600;
}
.modal__client .form-group input{
	padding-left:20px;
	padding-right:20px;
}
.modal__client .form-group label{
	padding-left:10px;
	padding-right:10px;
}
.modal__client .double__client>.or__field{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	position: absolute;
	left: 0px;
	right: 0px;
	margin-left:auto;
	margin-right:auto;
	z-index: 2;
	min-width:30px;
	max-width:30px;
	min-height:30px;
	max-height:30px;
	border: 1px solid #9E9E9E;
	background-color:#fff;
	border-radius: 5px;
}
.modal__client form>.form-group{
	margin-bottom:20px;
}
.modal__client .double__client{
	display: grid;
	grid-template-columns:repeat(2 , 1fr);
	grid-column-gap:10px;
	margin-bottom:20px;
	position: relative;
	align-items:center;

}
.modal__client .select__client{
	margin:30px 0px;
	margin-top:10px;
}
.inner__modal--client>form{
	display: flex;
	flex-direction:column;
	justify-content:flex-start;
}
.inner__modal--client .select__client .select.js-select{
	max-width:200px;
}
.inner__modal--client .select__client>p{
	margin:0px;
	margin-right:30px;
	color:#3B3B3B;
	font-size:14px;
	font-weight:600;
	line-height:24px;
}
.inner__modal--client .select__client{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.modal__client form>.portal__wrapper.form-group{
	display: flex;
	align-items:center;
	justify-content:flex-start;
	margin-bottom:95px;
}
.portal__wrapper.form-group .form-group__header{
	margin-bottom: 0px;
	margin-right:20px;
}
.settings__dropdown>.settings__button>a span{
	display: inline-flex;
	margin-right:10px;
}
.settings__dropdown>.settings__button>a{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	min-width:120px;
	font-size:14px;
	font-weight:600;
	line-height:19px;
	color:rgba(14, 125, 125, 1);
	min-height:40px;
	padding:5px 10px;
	background: #FFFFFF;
	border: 1px solid transparent;
	transition:.4s ease all;
}
.settings__dropdown .settings__button{
	border:1px solid #0E7D7D;
	border-radius:3px;
	display: flex;
	align-items:center;
	justify-content:center;
	transition:.4s ease all;
}
.settings__dropdown>.settings__button>a:hover{
	border-color:#0E7D7D;
}
.settings__dropdown>.settings__button:focus , .settings__dropdown>.settings__button:active , .settings__dropdown>.settings__button:focus>a , .settings__dropdown>.settings__button:active>a{
	border-color: rgba(14, 125, 125, 0.3);
}
.settings__head.column-group.profile__head{
	align-items:flex-start;
}
.settings__head .settings__dropdown{
	position: relative;
}
.settings__dropdown--wrapper{
	position: absolute;
	right: 0px;
	top:55px;
	padding:25px;
	padding-bottom:30px;
	max-width:300px;
	min-width: 300px;
	width: 100%;
	background: #FFFFFF;
	box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	z-index: 3;
}
.settings__dropdown--wrapper .elem__settings--dropdown:last-child{
	margin-bottom: 0px;
}
.settings__dropdown--wrapper .elem__settings--dropdown>p{
	margin:0px;
	color:rgba(105, 91, 91, 1);
	font-size:14px;
	line-height:19px;
	font-weight:600;
	min-width:90px;
	margin-right:20px;
}
.settings__dropdown--wrapper .elem__settings--dropdown .select__label{
	height: 35px;
}
.settings__dropdown--wrapper .elem__settings--dropdown.dropdown__container{
	align-items:center;
}
.settings__dropdown--wrapper .elem__settings--dropdown:last-child{
	margin-bottom: 0px;
}
.settings__dropdown--wrapper .elem__settings--dropdown>span{
	font-size: 14px;
    font-weight: 600;
    color: rgba(59, 59, 59, 1);
}
.settings__dropdown--wrapper .elem__settings--dropdown{
	margin-bottom:15px;
	display: flex;
	align-items:flex-start;
	justify-content:flex-start;
}
.settings__dropdown--wrapper .elem__responsible .responsible__avatar img{
	max-width:30px;
}
.settings__dropdown--wrapper .elem__responsible p{
	margin:0px;
	color:#3B3B3B;
	font-size:12px;
	font-weight:400;
}
.settings__dropdown--wrapper .elem__responsible{
	margin-bottom:10px;
}
.settings__dropdown--wrapper .elem__responsible:last-child{
	margin-bottom:0px;
}
.settings__dropdown--wrapper .elem__responsible .responsible__avatar{
	display: flex;
	align-items:center;
	justify-content:center;
	margin-right:10px;
}
.settings__dropdown--wrapper .elem__responsible{
	display: flex;
	align-items:center;
}
.details__list{
	display: grid;
	grid-template-columns:auto;
	grid-column-gap:20px;
	margin-top:15px;
	grid-row-gap:10px;
}
.details__list .elem__details{
	display: flex;
	text-align:left;
}
.details__container .details__picker>.form-group{
	display: flex;
	align-items:center;
}
.details__list .elem__details .details__info{
	min-width:140px;
	max-width:140px;
}
.details__list .elem__details .details__info>p{
	margin:0px;
	color:#787878;
	font-size:14px;
	font-weight:600;
}
.details__list .elem__details .details__info--main>p span{
	color:#0E7D7D;
}
.details__list .elem__details .details__info--main>p{
	margin:0px;
	color:#3B3B3B;
	font-size:14px;
	font-weight:600;
	word-break: break-all;
}
.details__picker .details__radio>.form-group>.form-group__header>h5{
	color:#2D2D2D;
	font-size:16px;
	font-weight:bold;
}
.details__picker .details__radio>.form-group>.form-group__header{
	margin-bottom: 0px;
	min-width:140px;
	max-width:140px;
	margin-right: 0px;
}
.details__picker .details__radio>.form-group{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.details__container>.element__details:last-child{
	margin-right: 0px;
}
.details__container>.element__details{
	margin-right:95px;
}
.details__container{
	display: flex;
	align-items:flex-start;
	justify-content:flex-start;
}
.parent__company--button{
	margin-top:0px;
	display: flex;
	justify-content:flex-start;
}
.parent__company--button>a:focus , .parent__company--button>a:active{
	border-color: rgba(14, 125, 125, 0.3);
}
.parent__company--button>a span img{
	max-width:14px;
}
.parent__company--button>a span{
	display: inline-flex;
	margin-left:15px;
}
.parent__company--button>a{
	color:rgba(14, 125, 125, 1);
	font-size:14px;
	line-height:19px;
	font-weight:600;
	display: inline-flex;
	align-items:center;
	justify-content:center;
	transition:.4s ease all;
	min-width:180px;
	min-height:40px;
	padding:7px 20px;
	border: 1px solid #0E7D7D;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
}
.responsible__table{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.responsible__table .el__responsible img{
	max-width:25px;
	min-width:25px;
	border-radius:250px;
}
.responsible__table .more__responsible{
	margin-left:15px;
}
.responsible__table .more__responsible>a{
	min-width:25px;
	max-width:25px;
	min-height:25px;
	max-height:25px;
	border-radius:250px;
	border: 1px solid #0E7D7D;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
	display: flex;
	align-items:center;
	justify-content:center;
}
.responsible__table .el__responsible{
	display: inline-flex;
	align-self:center;
	margin-right:-10px;
	justify-content:center;
}
.project__table tr td p{
	margin:0px;
	color:rgba(45, 45, 45, 1);
	font-size:14px;
	line-height:19px;
}
p.weight__table  ,a.weight__table{
	font-weight:600;
	color:rgba(45, 45, 45, 1);
}
.project__table tr td.date__project p{
	font-weight:600;
}
.catalog__content .project__table.table td{
	padding-top:4px;
	padding-bottom:4px;
	height: 50px
}
	
.project__table .project__logo{
	min-width:60px;
	width: 60px;
	max-width:60px;
}
.project__table .project__logo>img{
	max-width:100%;
}
.project__table .choose-actions-dropdown__toggle.is-active .button__action{
	padding-left:1px;
}
.project__table .button__action{
	padding-top:1px;
	padding-right:1px;
}
.project__table .centered__table--content>p{
	margin:0px;
	text-align:center;
	color:rgba(45, 45, 45, 1);
}
.project__table tr th h5{
	color:rgba(45, 45, 45, 1);
	font-size:14px;
}
.create__project--modal {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 15;
    background-color: rgba(0,0,0,.7);
}
.create__project--modal>a{
    position: absolute;
    top: 70px;
    right: 600px;
    opacity:0;
    min-width:50px;
    max-width:50px;
    min-height:50px;
    max-height:50px;
    border-radius:5px;
    display: inline-flex;
    align-items:center;
    justify-content:center;
    border:1px solid transparent;
    transition:.4s ease all;
}
.create__project--modal>a:active , .create__project--modal>a:focus{
	border-color:#fff;
}
.create__project--modal>a:hover{
	background: #5C5A64;
}
.create__project--modal .inner__modal--project{
    width: 100%;
    max-width: 590px;
    right: -600px;
    height: calc(100% - 60px);
    margin-left: auto;
    margin-top: 60px;
    background: #FFFFFF;
    box-shadow: 5px 10px 30px rgb(0 0 0 / 10%);
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    position: relative;
    overflow-y: auto;
    transition:.4s ease all;
    padding: 50px 45px;
}

.create__project--modal .inner__modal--project>form>h2{
	color:#0E7D7D;
	margin:0px;
	font-size:25px;
	margin-bottom:15px;
}
.create__project--modal .project__dates{
	display: grid;
	grid-template-columns:repeat(2 , 1fr);
	grid-column-gap:30px;
	margin-top:20px;
}
.create__project--modal .project__date--elem .datepicker:after{
	min-width:40px;
	max-width:40px;
	min-height:40px;
	max-height:40px;
	background-color: #FFFFFF;
	background-size:22px;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	right: 0px;
}
.create__project--modal .form-group{
	margin-top:20px;
}
.create__project--modal .project__date--elem .datepicker>span{
	display: inline-flex;
	min-width:60px;
	color:#2D2D2D;
	font-weight:600;
	font-size:14px;
}
.create__project--modal .project__date--elem .input--border-alto{
	background: #F7F7F7;
	border:0px;
	border-radius: 3px;
}
.create__project--modal .project__date--elem .datepicker{
	display: flex;
	align-items:center;
}
.create__project--modal .project__date--elem .input-animation--required:before{
	content:none;
}
.create__project--modal .project__surname .form-group{
	margin-top: 0px;
}
.create__project--modal .controls__create{
	margin-top:35px;
}
.create__project--modal .project__surname{
	display: grid;
	grid-template-columns:repeat(2 , 1fr);
	grid-column-gap:30px;
	margin-top:20px;
}
.create__project--modal .project__image .edit-avatar .button{
	min-width:50px;
	max-width:50px;
	min-height:50px;
	max-height:50px;
}
.create__project--modal .project__image{
	margin-bottom:25px!important;
}
.heading__my--projects{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.heading__my--projects .projects__dropdown .projects__main{
	cursor:pointer;
}
.heading__my--projects .projects__dropdown .projects__dropdown--container ul{
	padding:0px;
	list-style-type: none;
	margin:0px;
	max-height: 200px;
	overflow-y:auto;
}
.heading__my--projects .projects__dropdown .projects__dropdown--container ul::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
.heading__my--projects .projects__dropdown .projects__dropdown--container ul::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.heading__my--projects .projects__dropdown .projects__dropdown--container ul::-webkit-scrollbar-thumb {
  background: #EBEBEF;
  border-radius:5px;
}
.heading__my--projects .projects__dropdown .projects__dropdown--container ul li>a:hover{
	background: #0E7D7D;
	color:#fff;
}
.heading__my--projects .projects__dropdown .projects__dropdown--container ul li>a{
	padding:9px 15px;
	box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
	display: flex;
	color:rgba(45, 45, 45, 1);
	font-size:14px;
	line-height:21px;
	border-radius: 4px;
	transition:.4s ease all;
	font-weight:400;
}
.heading__my--projects .projects__dropdown>.projects__main{
	transition:.4s ease all;
}
.heading__my--projects .projects__dropdown>.projects__main:hover{
    box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
}
.heading__my--projects .projects__dropdown .projects__dropdown--container{
	position: absolute;
	top:40px;
	z-index: 2;
	background: #FFFFFF;
	display: none;
	min-width:220px;
	max-width:220px;
	padding:10px;
	box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	left: 50%;
    transform: translate(-50%, 0);
}
.heading__my--projects .projects__dropdown{
	position: relative;
}
.heading__my--projects .projects__dropdown .projects__main>p{
	margin:0px;
	color:#2D2D2D;
	font-size:12px;
	font-weight:600;
}
.heading__my--projects .projects__dropdown .projects__main{
	background: #FFFFFF;
	border: 1px solid #0E7D7D;
	border-radius: 3px;
	padding-left:20px;
	padding-right:40px;
	min-height:30px;
	display: flex;
	align-items:center;
	position: relative;
	justify-content:center;
}
.heading__my--projects .projects__dropdown .projects__main>span{
	display: inline-flex;
	position: absolute;

	right: 15px;
}
.heading__my--projects .projects__dropdown{
	position: relative;
}
.heading__my--projects>h2{
	margin-right:20px;
}
.jobs__table .table__head h5{
	color:#787878;
	font-size:14px;
	font-weight:600;
}

.jobs__table .table__head{
	display: flex;
	justify-content:space-between;
	align-items:center;
	padding-bottom:15px;
	border-bottom:1px solid #E3E3E3;
}
.elem__job--main{
	position: relative;
}
.elem__job--main:before{
	    content: "";
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    position: absolute;
    left: -1px;
    top: 0px;
    height: calc(100% + 2px);
    width: 0px;
    background-color:#0E7D7D;
    transition: .2s ease all;
    z-index: 2;
    top: -1px;
}
.elem__job.active__job .elem__job--main:before{
	width:5px;
}
.elem__job--main{
	display: flex;
	justify-content:space-between;
	align-items:center;
	padding:10px 0px;
	border-top:1px solid #E3E3E3;
	position: relative;
	border-bottom:1px solid #E3E3E3;
	border-top:0px;
}
.id__table>a{
	display: inline-flex;
	text-decoration: underline;
	color:#0E7D7D;
	font-size:16px;
	font-weight:600;
	transition:.4s ease all;
}
.deadline__table>p span{
	color:rgba(158, 158, 158, 1);
}
.client__table>a{
	text-decoration: underline;
	color:rgba(24, 142, 240, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
	transition:.4s ease all;
}
.client__table>a:hover{
	opacity:.7;
}
.workers__table>.workers__clients .elem__worker img{
	max-width:24px;
	border-radius:250px;
}
.workers__table>.workers__clients .elem__worker{
	display: flex;
	align-items:center;
	justify-content:center;
	margin-right:-10px;
}
.workers__table>.workers__clients{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.jobs__table .workers__table>h5{
	text-align:left;
}
 .main__status--table .status__arrow{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	position: absolute;
	right: 15px;
}
.show__table .show__main span{
	display: inline-flex;
	margin-left:10px;
	justify-content:center;
	align-items:center;
}
.show__table .show__main{
	display: flex;
	position: relative;
	align-items:center;
	justify-content:center;
	border: 1px solid #E3E3E3;
	border-radius: 4px;
	min-width:85px;
	max-width: 85px;
	min-height:30px;
	cursor:pointer;
	transition: .4s ease all;
}
.show__table .show__main:hover{
	border-color:#0E7D7D;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}
 .main__status--table{
	min-width:175px;
	max-width:175px;
	cursor:pointer;
	min-height:30px;
	padding:5px 20px;
	border: 1px solid #E3E3E3;
	border-radius: 100px;
	position: relative;
	display: flex;
	align-items:center;
	justify-content:flex-start;
	transition: .4s ease all;
}
.main__status--table.active__status{
    box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
}
 .main__status--table:hover{
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}
.deadline__table>p{
	margin:0px;
	color:#3B3B3B;
	font-size: 14px;
	line-height:19px;
	font-weight:600;
}
.progress__table>p{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	border: 1px solid #3F88C5;
	border-radius:100px;
	font-size:14px;
	font-weight:600;
	line-height:21px;
	color:rgba(63, 136, 197, 1);
	padding:5px 15px;
}
.id__table>a:hover{
	opacity:.7;
}
.jobs__table{
	overflow-x: auto;
}
.jobs__table>div{
	min-width:1170px;
}
.jobs__table .id__table{
	min-width:120px;
	width: 120px;
	max-width:120px;
	text-align:left;
}
.jobs__table .progress__table{
	width: 145px;
	min-width:145px;
	max-width: 145px;
	text-align:center;
}
.jobs__table .workers__table{
	min-width:155px;
	max-width:155px;
	width: 155px;
	text-align:center;
}
.jobs__table .show__table{
	min-width:135px;
	max-width:135px;
	width: 135px;
}
.jobs__table .status__table{
	width: 230px;
	max-width:230px;
	min-width:230px;
	text-align:left;
}
.jobs__table .deadline__table{
	min-width:185px;
	max-width:185px;
	width: 185px;
	padding:5px 0px;
}
.jobs__table .client__table{
	width: 135px;
	max-width:135px;
	min-width: 135px;
}
.check__table{
	min-width:30px;
	max-width:30px;
	width: 30px;
	display: flex;
	align-items:center;
	justify-content:center;
	padding-left:20px;
	padding-right:20px;
}
.deadline__table>.deadline__soon>img{
	margin-right:10px;
}
.deadline__table>.deadline__soon{
	background: #E7A400;
	border: 1px solid #E7A400;
	border-radius: 100px;
	display: inline-flex;
	font-size:14px;
	font-weight:600;
	color:#fff;
	padding:2px 15px;
}
.deadline__table>.lating__deadline{
	background: #E74500;
	border-radius: 100px;
	display: inline-flex;
	padding:2px 15px;
	font-weight: 600;
	font-size: 14px;
	color:#fff;
	line-height: 21px;
}
.projects__controls.catalog__content-footer{
	margin-top: 0px;
	padding-bottom:20px;
	padding-left:20px;
	padding-right:20px;
}
.my__projects--wrapper .permissions-control-dropdown__box{
	min-width:260px;
}
.my__projects--wrapper .permissions-control-dropdown__box .actions__action .plus__icon{
	max-width:16px;
}
.my__projects--wrapper .permissions-control-dropdown__box{
	padding:0px;
}
.my__projects--wrapper{
	display: flex;
	align-items:center;
}
.my__projects--wrapper .permissions-control-dropdown__toggle{
	height: 40px;
	display: flex;
	align-items:center;
	justify-content:center;
	min-width: 44px;
	border-radius:3px;
	border:1px solid rgba(14, 125, 125, 1);
}
.my__projects--wrapper>button{
	margin-right:15px;
}
.catalog__project.catalog__wrapper{
	display: flex;
	align-items:center;
}
.elem__job.active__job>.elem__job--main>.check__table>.check__input{
	opacity:1;
}
.elem__job--main:hover>.check__table>.check__input{
	opacity:1;
}
.elem__job--main>.check__table>.check__input{
	opacity:0;
	transition:.4s ease all;
}
.elem__job--droppable>.droppable__table--head>.show__table{
	min-width:130px;
	max-width:130px;
	width: 130px;
}

.elem__job--droppable>.droppable__table--head>.droppable__double{
	display: flex;
	align-items:center;
	justify-content:flex-start;
	width: 26%;
}
.elem__job--droppable>.droppable__table--head>.droppable__status{
	min-width:235px;
	max-width:235px;
	width: 235px;
	position: relative;
	left:5px;
}
.elem__job--droppable>.droppable__table--head>.droppable__languages{
	min-width:150px;
	max-width:150px;
	width: 150px;
	position: relative;
	left: 47px;
}
.droppable__content>.droppable__languages{
	min-width:150px;
	max-width:150px;
	width: 150px;
	position: relative;
	left: 50px;
}
.droppable__content>.droppable__status>.status__inner{
	padding-right: 35px;
	display: inline-flex;
}
.droppable__content>.droppable__status{
	min-width:235px;
	max-width:235px;
	width: 235px;
	display: 	flex;
	justify-content:flex-start;
}
.elem__job--droppable>.droppable__table--head>.droppable__services{
	min-width:135px;
	max-width:135px;
	width: 135px;
}
.elem__job--droppable>.droppable__table--head>.droppable__deadline{
	min-width:180px;
	max-width: 180px;
	width: 180px;
}
.elem__job--droppable>.droppable__table--head .droppable__worker{
	min-width:235px;
	max-width:235px;
	width: 235px;
}
.elem__job--droppable>.droppable__table--head .droppable__counter{
	min-width:55px;
	display: flex;
	justify-content:center;
	align-items:center;
	max-width:55px;
	width: 55px;
}
.elem__job--droppable{
	padding-left:0px;
	width: 100%;
}
.elem__job--droppable>.droppable__table--head>.drop__empty{
	min-width:50px;
	max-width:50px;
	width: 50px;
}
.elem__job--droppable .droppable__table--head h5{
	margin:0px;
	color: #787878;
    font-size: 14px;
    font-weight: 600;
}
.elem__job--droppable>*{
	max-width:calc(100% - 20px);
	margin-left:auto;
}
.elem__job--droppable .droppable__table--head{
	display: flex;
	align-items:center;
	justify-content:space-between;
}
.droppable__worker>.worker__info .worker__image img{
	max-width:35px;
	border-radius:250px;
}
.droppable__worker>.worker__info>a:hover{
	opacity:.7;
}
.droppable__content>.droppable__double .droppable__counter{
	margin-right:15px;
	min-width:20px;
	max-width:20px;
	display: flex;
	align-items:center;
	justify-content:center;
}
.droppable__content>.droppable__double .droppable__counter h5{
	margin:0px;
	color:#3B3B3B;
	font-size:14px;
	font-weight:600;
}
.droppable__content{
	padding:7px 0px;
	border-top:1px solid #E3E3E3;
	display: flex;
	align-items:center;
	justify-content:space-between;
}
.droppable__content:last-child{
	border-bottom:1px solid #E3E3E3;
}

.droppable__content .droppable__deadline>p span{
	color:rgba(158, 158, 158, 1);
}
.droppable__content .droppable__deadline>p{
	margin:0px;
	color:#3B3B3B;
	font-size:14px;
	line-height:19px;
	font-weight:600;
}
.elem__job--droppable>.droppable__table--head{
	padding:15px 0px;
}
.droppable__content>.droppable__double{
	display: flex;
	align-items:center;
	padding-left:18px;
	width: 26%;
}
.droppable__content>.droppable__deadline{
	min-width:180px;
	max-width:180px;
	width: 180px;
}
.droppable__content>.droppable__services{
	min-width:135px;
	max-width:135px;
	width: 135px;
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.droppable__worker>.worker__info>a{
	display: inline-flex;
	text-decoration: underline;
	color:#2D2D2D;
	font-size:14px;
	font-weight:600;
	transition:.4s ease all;
}
.droppable__worker>.worker__info .worker__image{
	display: flex;
	align-items:center;
	justify-content:center;
	margin-right:10px;
}
.droppable__worker>.worker__info{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.services__wrapper .elem__service:last-child{
	margin-right: 0px;
}
.services__wrapper .elem__service{
	display: flex;
	align-items:center;
	justify-content:center;
	margin-right:8px;
}
.droppable__languages .languages__inner .languages__flags .flag__elem img{
	max-width:15px;
	border-radius:2px;
	min-width:15px;
}
.droppable__languages .languages__inner .languages__flags .flag__elem:last-child{
	margin-right: 0px;
}
.droppable__languages .languages__inner .languages__flags .flag__elem{
	display: flex;
	align-items:center;
	margin-right:7px;
	padding:3px 0px;
	justify-content:center;
}
.droppable__languages .languages__inner .languages__flags{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.droppable__languages .languages__inner>p{
	margin:0px;
	margin-right:7px;
	color:#3B3B3B;
	font-size:14px;
	font-weight:600;
}
.droppable__languages .languages__inner{
	display: flex;
	align-items: center;
	justify-content:flex-start;
}
.droppable__status .status__inner>p>span{
	color:#E7A400;
	font-size:14px;
	font-weight:600;
}
.droppable__status .status__inner>p>span:nth-child(1){
	margin-right:5px;
}
.droppable__status .status__inner>p{
	margin:0px;
	background: #FDF6E6;
	border-radius: 100px;
	min-width: 150px;
	padding:5px 20px;
	display: flex;
	align-items:center;
	width: 	100%;
	min-height: 33px;
}
.droppable__content .droppable__double .droppable__counter .check__input{
	display: none;
}
.droppable__content.active__droppable:before{
    width:5px;
}
.droppable__content{
	position: relative;
}
.droppable__content:before{
    content: "";
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    position: absolute;
    left: -1px;
    top: 0px;
     background-color: #0E7D7D;
    height: calc(100% + 2px);
    width: 0px;
    transition: .4s ease all;
    z-index: 2;
    top: -1px;
}
.droppable__content.active__droppable .droppable__counter>h5{
	display: none;
}
.droppable__content.active__droppable .droppable__counter .check__input{
	display: block;
}
.droppable__content:hover .droppable__double .droppable__counter>h5{
	display: none;
}
.droppable__content:hover .droppable__double .droppable__counter .check__input{
	display: block;
}
.elem__job--droppable{
	display: none;
}
.progress__table.full__progress>p{
	color:#00C868;
	border: 1px solid #00C868;
}
.progress__table.full__progress>p>span{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	margin-right:10px;
}
.show__main{
	transition:.4s ease all;
}
.show__main>p{
	transition:.4s ease all;
}
.show__main svg{
	transition:.2s ease all;
	position: relative;
	left: 0px;
}
.show__main svg path{
	transition:.4s ease all;
}
.show__main.active__show--main svg{
	transform:rotate(180deg);
	position: relative;
	left: 2px;
}
.show__main.active__show--main svg path{
	fill:#fff;
}
.show__table .show__main.active__show--main>p{
	color:#fff;
}
.show__main.active__show--main{
	background-color: rgba(14, 125, 125, 1);
}
.progress__table>h5{
	text-align:left;
}
.jobs__table .progress__table{
	display: flex;
	justify-content:flex-start;
}
.main__status--table>p{
	color:rgba(45, 45, 45, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
	width: 100%;
	text-align:center;
}
.show__table .show__main>p{
	color:rgba(14, 125, 125, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
}
.status__table--dropdown{
	position: 	relative;
	display: 	flex;
	justify-content: flex-start;
	align-items:center;
}
.main__status--table{
	width: 	auto;
	max-width: 100%;
	padding-right: 35px;
	min-width: 175px;
}
.status__drop .elem__status{
	margin-bottom: 12px;
}
.status__drop .elem__status:last-child{
	margin-bottom: 	0px;
}
.status__drop .elem__status p span{
	display: 	inline-flex;
	align-items:center;
	justify-content:center;
	margin-right: 10px;
}
 p.processing__label span , p.planed__label span , p.registrations__label span , p.finished__label span , p.completed__label span{
 	display: 	inline-flex;
 	margin-right: 10px;
 	align-items:center;
 	justify-content:center;
 }
 p.processing__label{
	color:#E7A400!important;
	display: 	flex;
	align-items:center;
	justify-content: flex-start;
}
 p.planed__label{
	color:#523FC5!important;
	display: 	flex;
	align-items:center;
	justify-content: flex-start;
}
 p.registrations__label{
	color:#C700E7!important;
	display: 	flex;
	align-items:center;
	justify-content: flex-start;
}
 p.finished__label{
	color:#3F88C5!important;
	display: 	flex;
	align-items:center;
	justify-content: flex-start;
}
 p.completed__label{
	color:#00C868!important;
	display: 	flex;
	align-items:center;
	justify-content: flex-start;
}
.status__drop .elem__status p{
	display: 	flex;
	align-items:center;
	justify-content:flex-start;
	margin-left: 10px;
	font-size: 14px;
	font-weight: 600;
}
.status__table--dropdown .status__drop{
	background: #FFFFFF;
	z-index: 	3;
	width: 	100%;
	max-width: 205px;
	min-width: 205px;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	padding:20px 25px;
	position: absolute;
	left: 0px;
	top:40px;
}
 .main__status--table.processing__status ,  .main__status--table.planed__status ,  .main__status--table.registrations__status,
 .main__status--table.finished__status ,  .main__status--table.completed__status{
	border-color:transparent;
}
.main__status--table.registrations__status path{
	fill:rgba(199, 0, 231, 1);
}
.main__status--table.processing__status path{
	fill:rgba(231, 164, 0, 1);
}
.main__status--table.planed__status path{
	fill:rgba(82, 63, 197, 1);
}
.main__status--table.registrations__status path{
	fill:rgba(199, 0, 231, 1);
}
.main__status--table.processing__status{
	background: #FDF6E6;
}
.main__status--table.planed__status{
	background: #EEECFA;
}
.main__status--table.registrations__status{
	background: rgba(250, 230, 253, 1);
}
.main__status--table.finished__status{
	background: #ECF4FA;
}
.main__status--table.completed__status path{
	fill:rgba(0, 200, 104, 1);
}
.main__status--table.completed__status{
	background: #E6FAF0;
}
.droppable__worker .comment__dropdown>a.comment__button{
	transition: .4s ease all;
}
.comment__dropdown .comment__inner .comment__buttons>input[type="submit"]:hover{
	opacity:.7;
}
.comment__dropdown .comment__inner .comment__buttons>input[type="submit"]:hover{
	opacity:.7;
}
.comment__dropdown .comment__inner .comment__buttons>input[type="submit"]{
	display: 	inline-flex;
	font-size: 14px;
	color:#fff;
	line-height: 14px;
	font-weight: 600;
	min-height: 40px;
	background: #0E7D7D;
	border: 2px solid #0E7D7D;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
	cursor:pointer;
	justify-content:center;
	align-items:center;
	transition: .4s ease all;
	border-radius: 3px;
}
.comment__dropdown .comment__inner  .reply__comment input::-webkit-input-placeholder { 
  color: rgba(130, 130, 130, 1);
}
.comment__dropdown .comment__inner  .reply__comment input::-moz-placeholder { 
  color: rgba(130, 130, 130, 1);
}
.comment__dropdown .comment__inner  .reply__comment input:-ms-input-placeholder { 
  color: rgba(130, 130, 130, 1);
}
.comment__dropdown .comment__inner  .reply__comment input:-moz-placeholder {
  color: rgba(130, 130, 130, 1);
}
.comment__dropdown .comment__inner  .reply__comment input:focus{
	border-color:#0E7D7D;
}
.comment__dropdown .comment__inner .comment__user .comment__head{
	display: 	flex;
	align-content:center;
	justify-content:space-between;
}
.comment__dropdown .comment__inner .comment__user .comment__head>a .comment__image img{
	max-width: 30px;
	border-radius: 250px;
	width: 	100%;
}
.comment__dropdown .comment__inner .comment__user .comment__head>a>p{
	margin:0px;
	font-size: 14px;
	color:#2D2D2D;
	font-weight: 600;
	display: 	flex;
	align-items:center;
	text-decoration: underline;
}
.comment__dropdown .comment__inner .comment__user .comment__desc{
	margin-bottom: 15px;
}
.comment__dropdown .comment__inner .comment__user .comment__desc p{
	margin:0px;
	color:#2D2D2D;
	font-size: 14px;
	font-weight: 400;
}
.comment__dropdown .comment__inner .comment__user .comment__head{
	margin-bottom: 15px;
}
.comment__dropdown .comment__inner .comment__user .comment__head>a .comment__image{
	display: 	flex;
	margin-right: 15px;
	align-items:center;
	justify-content:center;
}
.comment__dropdown .comment__inner .comment__user .comment__head>span{
	color:#9E9E9E;
	font-size: 14px;
	font-weight: 400;
	display: 	flex;
	align-items:center;
}
.comment__dropdown .comment__inner .comment__user .comment__head>a:hover{
	opacity:.7;
}
.comment__dropdown .comment__inner .comment__user .comment__head>a{
	display: 	flex;
	align-content:center;
	justify-content:flex-start;
	transition: .4s ease all;
}
.comment__dropdown .comment__inner  .reply__comment input{
	width: 	100%;
	height: 	40px;
	padding-left: 20px;
	padding-right: 20px;
	border: 1px solid #BDBDBD;
	border-radius: 3px;
	color:#000;
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;
	transition: .4s ease all;
}
.comment__dropdown .comment__inner  .reply__comment{
	margin-bottom: 15px;
}
.comment__dropdown .comment__inner .comment__buttons>a{
	display: 	inline-flex;
	justify-content:center;
	align-items:center;
	min-height: 	40px;
	width: 	100%;
	transition: .4s ease all;
	border: 1px solid #0E7D7D;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
	font-size: 14px;
	font-weight: 600;
	line-height: 14px;
	color:#0E7D7D;
	transition: .4s ease all;
	border-radius: 3px;
}
.comment__dropdown .comment__inner .comment__buttons{
	display: 	grid;
	grid-template-columns: repeat(2 , 1fr);
	grid-column-gap: 15px;
}
.comment__dropdown .comment__inner{
	position: 	absolute;
	top:40px;
	left: 	0px;
	min-width: 270px;
	max-width: 270px;
	padding:20px;
	background: #FFFFFF;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	display: 	none;
	z-index: 	3;
}
.droppable__worker .comment__dropdown>a.comment__button:hover{
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}
.droppable__worker .comment__dropdown>a.comment__button path{
	transition: .4s ease all;
}
.droppable__worker .comment__dropdown{
	position: 	relative;
}
.comment__button{
	min-width: 	27px;
	max-width: 27px;
	min-height: 27px;
	max-height: 27px;
	display: 	inline-flex;
	align-items:center;
	justify-content: 	center;
	background: #FFFFFF;
	border: 1px solid #E3E3E3;
	border-radius: 4px;
	margin-left: 10px;
}
.comment__dropdown .comment__inner .comment__user:last-child{
	margin-bottom: 	0px;
}
.comment__dropdown .comment__inner .comment__users{
    max-height: 200px;
    overflow-y: auto;
    width: calc(100% + 20px);
    padding-right: 20px;
}
.comment__dropdown .comment__inner .comment__users::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
.comment__dropdown .comment__inner .comment__users::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.comment__dropdown .comment__inner .comment__users::-webkit-scrollbar-thumb {
  background: #EBEBEF;
  border-radius:5px;
}
.comment__dropdown .comment__inner .comment__user{
	margin-bottom: 15px;
	border-bottom:1px solid #E3E3E3;
}
.comment__dropdown>.comment__button.comment__active path{
	fill:#fff;
}
.comment__dropdown>.comment__button.comment__active{
	background-color: #0E7D7D;
}
.comment__dropdown .comment__inner .comment__buttons{
	display: 	none;
}
.projects__page.employers-page__wrap{
	overflow:hidden;
}
.deadline__droppable p{
	display: 	flex;
	align-items:center;
	justify-content:flex-start;
	padding:5px 12px;
	border-radius: 100px;
	border: 1px solid #E3E3E3;
	cursor:pointer;
}
.deadline__droppable{
	display: 	flex;
	justify-content:flex-start;
}
.deadline__droppable.today__deadline>p>span{
	display: 	inline-flex;
	align-items:center;
	justify-content:center;
	margin-left: 10px;
}
.deadline__droppable.today__deadline>p{
	color: #E7A400;
	font-size: 14px;
	line-height: 21px;
	font-weight: 600;
	margin:0px;
}
.deadline__droppable.set__deadline p{
	color:#3F88C5;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	margin:0px;
}
.deadline__droppable.empty__deadline p path{
	fill:#9E9E9E;
}
.deadline__droppable.empty__deadline p span:last-child{
	display: 	inline-flex;
	margin-left: 10px;
}
.deadline__droppable.empty__deadline p span:nth-child(1){
	margin-left: 4px;
}
.deadline__droppable.empty__deadline p span{
	color:#9E9E9E;
	font-size: 12px;
	line-height: 21px;
	font-weight: 600;
}
.deadline__droppable.empty__deadline p{
	color:#3B3B3B;
	font-size: 12px;
	line-height: 21px;
	white-space: nowrap;
	font-weight: 600;
}
.deadline__droppable.set__deadline span{
	display: 	inline-flex;
	margin-left: 10px;
}
.deadline__droppable.set__deadline svg path{
	fill:#3F88C5;
}
.custom__worker{
	display: 	flex;
	align-items:center;
	justify-content:flex-start;
}
.custom__worker>a{
	display: 	inline-flex;
	align-items:center;
	justify-content:center;
	min-width: 35px;
	max-width: 35px;
	min-height: 35px;
	max-height: 35px;
	border: 2px solid #0E7D7D;
	border-radius: 250px;
	margin-right: 15px;
	transition: .4s ease all;
}
.custom__worker>p{
	margin:0px;
	color:rgba(158, 158, 158, 1);
	font-size: 14px;
	line-height: 21px;
	font-weight: 400;
}
.custom__worker>a.active__worker rect{
	fill:#fff;
}
.custom__worker{
	position: 	relative;
}
 .custom__drop form{
	display: 	flex;
	flex-direction: column;
	justify-content:center;
	align-items:center;
}
 .custom__drop form>input[type="submit"]:hover{
	color:#fff;
	background-color: #0E7D7d;
}
 .custom__drop form>input[type="submit"]{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    width: 100%;
    transition: .4s ease all;
    border: 2px solid #0E7D7D;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
	border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    color: #0E7D7D;
    transition: .4s ease all;
    border-radius: 3px;
    cursor:pointer;
    background-color: transparent;
}
 .custom__drop form>input[type="text"]:focus ,  .custom__drop form>input[type="text"]:active {
    border-color: #0E7D7D;
}
 .custom__drop form>input[type="text"]{
	width: 	100%;
	height: 	40px;
	padding-left: 20px;
	padding-right: 20px;
	background: #FFFFFF;
	border: 1px solid #BDBDBD;
	border-radius: 3px;
	transition: .4s ease all;
	font-size: 14px;
	font-weight: 400;
	color:#000;
	margin-bottom: 20px;
}
 .custom__drop{
	position: 	absolute;
	left: 	0px;
	top:45px;
	margin-top:40px;
	padding:20px;
	z-index: 3;
	background-color: #fff;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	display: 	none;
	min-width:300px;
	max-width: 300px;
}
.custom__worker>a.active__worker{
	background-color: #0E7D7D
}
.custom__worker>a path{
	transition: .4s ease all;
}
.custom__worker>a:hover{
	box-shadow:0px 3px 10px rgba(0, 0, 0, 0.1);
}
.deadline__droppable>p{
	transition: .4s ease all;
}
.deadline__droppable.today__deadline:hover>p{
	border: 1px solid #E7A400;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}
.deadline__droppable.empty__deadline:hover>p{
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
	border: 1px solid #2D2D2D;
}
.deadline__droppable.set__deadline:hover>p{
	border: 1px solid #3F88C5;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}
.job__projects--buttons{
	display: flex;
	align-items:center;
	justify-content:flex-end;
}
.job__projects--buttons>.create__job span{
	margin-left:15px;
	display: inline-flex;
	align-items:center;
	justify-content:center;
}
.job__projects--buttons>.create__job{
	margin-right:15px;
	display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 140px;
    border:1px solid rgba(14, 125, 125, 1);
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: rgba(14, 125, 125, 1);
    min-height: 40px;
    padding: 5px 10px;
    background: #FFFFFF;
    transition: .4s ease all;
    border-radius: 3px;
}
.employers__projects .catalog__header{
	width: 100%;
}
.job__projects--buttons>.create__job:focus , .job__projects--buttons>.create__job:active{
	border-color: rgba(14, 125, 125, 0.3);
}
.controls__projects>p span{
	display: inline-flex;
	margin-right:5px;
}
.controls__projects>.archive__button span{
	display: inline-flex;
	margin-right:10px;
}
.controls__projects .delete__button{
	display: flex;
	align-items: center;
	justify-content:center;
	min-width:105px;
	min-height:37px;
	font-size:14px;
	line-height:21px;
	font-weight:600;
	color:#C1272D;
	border: 1px solid #C1272D;
	border-radius:4px;
	transition:.4s ease all;
}
.controls__projects .delete__button span{
	display: inline-flex;
	margin-right:10px;
}
.controls__projects>.archive__button{
	display: flex;
	color:rgba(45, 45, 45, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
	align-items:center;
	justify-content:center;
	min-width:110px;
	min-height:37px;
	transition:.4s ease all;
	border: 1px solid #2D2D2D;
	border-radius:4px;
	margin-right:15px;
}
.controls__projects>.archive__button:hover ,.controls__projects .delete__button:hover{
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}
.controls__projects>p{
	color:rgba(255, 255, 255, 1);
	font-size:12px;
	line-height:18px;
	font-weight:600;
	display: inline-flex;
	margin:0px;
	padding:5px 15px;
	background: #3F88C5;
	margin-right:15px;
	border-radius: 100px;
}
.controls__projects{
	display: flex;
	align-items:center;
	justify-content:flex-end;
	width: 100%;
	padding-right:35px;
	margin-bottom:20px;
}
.registrations__modal>a{
	position: absolute;
    top: 70px;
    right: 880px;
    transition:.4s ease all;
    opacity:0;
    display: inline-flex;
    align-items:center;
    justify-content:center;
    min-width:50px;
	max-width:50px;
	min-height:50px;
	max-height:50px;
	transition:.4s ease all;
	border:1px solid transparent;
	border-radius:5px;
}
.registrations__modal>a:active , .registrations__modal>a:focus{
	border-color:#fff;
}
.registrations__modal>a:hover{
	background: #5C5A64;
}
.registrations__modal .inner__registrations{
	    width: 100%;
    max-width: 865px;
    right:-875px;
    height: calc(100% - 60px);
    margin-left: auto;
    margin-top: 60px;
    background: #FFFFFF;
    box-shadow: 5px 10px 30px rgb(0 0 0 / 10%);
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    position: relative;
    overflow-y: auto;
    padding: 50px 45px;
    transition:.4s ease all;
}
.registrations__modal{
	background-color:rgba(0,0,0,.7);
	position: fixed;
	z-index: 15;
	top:0px;
	left: 0px;
	width: 100%;
	height: 100%;
}
.registrations__modal>.inner__registrations>form .registrations__table>p span{
	color:rgba(63, 136, 197, 1);
}
.registrations__modal>.inner__registrations>form .registrations__table>p{
	margin:0px;
	margin-bottom:25px;
	color:rgba(59, 59, 59, 1);
	font-size:16px;
	line-height:24px;
	font-weight:600;
}
.registrations__modal>.inner__registrations>form>h2{
	margin-bottom:20px;
	margin-top: 0px;
	color: #0E7D7D;
    font-size: 25px;
}
.registrations__modal .inner__registrations>form .registrations__table table tr td , .registrations__modal .inner__registrations>form .registrations__table table tr th{
	border-bottom:1px solid #E3E3E3;
	padding:0px 15px;
}
.registrations__modal .inner__registrations>form .registrations__table table tr td{
	padding:10px 15px;
}
.registrations__modal .inner__registrations>form .registrations__table table tr th{
	padding:15px;
}
.registrations__modal .inner__registrations>form .registrations__table table tr th{
	color:#787878;
	font-size:14px;
	font-weight:600;
	line-height:18px;
}
.registrations__modal .inner__registrations>form .registrations__table table{
	width: 100%;
}
.registrations__modal .inner__registrations>form .registrations__table{
	width: 100%;
}
.registrations__modal .inner__registrations>form{
	width: 100%;
}
.registrations__modal .inner__registrations{
	width: 100%;
}
.deadline__registrations p span{
	color:#9E9E9E;
}
.projects__registrations{
	display: flex;
	justify-content:center;
	align-items:center;
}
.projects__registrations p{
	text-align:center;
	margin:0px;
	color:#3B3B3B;
	font-size:14px;
	font-weight:600;
}
.controls__registrations .controls__unviewed .reject__button>a:hover{
	border-color: #0E7D7D;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
}
.controls__registrations .controls__unviewed .assign__button a span{
	display: inline-flex;
	margin-left:15px;
}
.controls__registrations .controls__unviewed .assign__button a:focus , .controls__registrations .controls__unviewed .assign__button a:active{
    box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
}
.controls__registrations .controls__unviewed .assign__button a{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	min-width:150px;
	color:#fff;
	transition:.4s ease all;
	font-size:14px;
	line-height:21px;
	font-weight:600;
	min-height:30px;
	background: #0E7D7D;
	border: 1px solid #0E7D7D;
	border-radius: 4px;
}
.controls__registrations .controls__unviewed .assign__button{
	display: flex;
	align-items:center;
	justify-content:center;
}
.controls__registrations .controls__unviewed .reject__button{
	margin-right:10px;
}
.controls__registrations .controls__unviewed .reject__button>a{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	min-width:72px;
	min-height:30px;
	background: #FFFFFF;
	border: 1px solid #E3E3E3;
	border-radius: 4px;
	color:rgba(193, 39, 45, 1);
	font-size:14px;
	font-weight:600;
	line-height:21px;
	transition:.4s ease all;
}
.controls__registrations .controls__unviewed{
	display: flex;
	align-items:center;
}
.center__registrations{
	text-align:center;
}
.deadline__registrations p{
	margin:0px;
	color:#3B3B3B;
	font-size:14px;
	font-weight:600;
}
.worker__registrations .regis__image img{
	max-width:35px;
	border-radius:250px;
	min-width:35px;
}
.worker__registrations .regis__text p{
	margin:0px;
	color:#2D2D2D;
	font-size:14px;
	font-weight:600;
}
.worker__registrations .regis__image{
	display: flex;
	align-items:center;
	margin-right:15px;
	justify-content:center;
}
.worker__registrations{
	display: flex;
	align-items:center;
}
.controls__registrations .controls__viewed .seen__info>p>img{
	margin-right:5px;
}
.controls__registrations .controls__viewed .seen__info>p{
	display: flex;
	align-items:center;
	color:#0E7D7D;
	font-size:14px;
	line-height:21px;
	font-weight:600;
}
.controls__registrations .controls__viewed .seen__details p span{
	color:#9E9E9E;
}
.controls__registrations .controls__viewed .seen__details p{
	margin:0px;
	color:#2D2D2D;
	font-size:14px;
	font-weight:600;
}
.controls__registrations .controls__viewed .seen__details{
	min-width:150px;
	text-align:center;
}
.controls__registrations .controls__viewed .seen__info{
	min-width:72px;
	margin-right:10px;
	display: flex;
	justify-content:center;
}
.controls__registrations .controls__viewed{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.registrations__modal .registrations__close{
	margin-top:40px;
}
.registrations__modal .registrations__close>a{
	min-width:120px;
}
.registrations__modal .inner__registrations{
	min-height:calc(100% - 100px);
	display: flex;
	flex-direction:column;
	justify-content:space-between;
}
.modal__client .inner__modal--client .elem__client .input , .modal__client .inner__modal--client .form-group .input{
	height: 50px;
}
.create__project--modal .form-group .input{
	height: 50px;
}
.controls__create>input[type="submit"] , .controls__create>button{
	height: 45px;
}
.create__project--modal .project__date--elem .input--border-alto{
	min-height:40px;
}
.filled__status.main__status--table .status__arrow{
	display: none;
}
.jobs__table.projects__table .progress__table.full__progress p svg path{
	fill:#fff;
}
.jobs__table.projects__table .progress__table.full__progress p{
	background-color:#00C868;
}
.jobs__table.projects__table .progress__table p{
	background-color:#3F88C5;
	color:#fff;
}
.order__wrapper{
	display: flex;
	align-items:flex-start;
	justify-content:space-between;
}
.order__wrapper .order__settings .order__settings--main{
	background: #FFFFFF;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05), 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
}
.order__wrapper .order__settings .head__order--settings>p.not__created{
	margin:0px;
	background: #FFFFFF;
	border-radius: 3px;
	color:rgba(120, 120, 120, 1);
	font-size:12px;
	line-height:18px;
	font-weight:600;
	padding:4px 14px;
}
.order__wrapper .order__settings  .content__order--settings{
	padding:15px;
}
.order__wrapper .order__settings  .content__order--settings>form>div:last-child{
	margin-bottom: 0px;
}
.order__wrapper .order__settings  .content__order--settings>form>div{
	margin-bottom:7px;
}
.order__wrapper .order__settings .head__order--settings>h2{
	margin:0px;
	color:rgba(255, 255, 255, 1);
	font-size:16px;
	line-height:24px;
	font-weight:700;
}
.order__wrapper .order__settings .head__order--settings{
	display: flex;
	align-items:center;
	justify-content:space-between;
	background-color:#0E7D7D;
	width: 100%;
	border-top-left-radius:5px;
	border-top-right-radius:5px;
	padding:10px 15px;
}
.order__container .order__heading{
	padding-right:245px;
}
.order__wrapper .order__settings{
	margin-left:25px;
	margin-top:0px;
	min-width:260px;
	max-width:260px;
	border-top-left-radius:5px;
	border-top-right-radius:5px;
}
.order__settings--main .double__settings .price__group{
	position: relative;
}
 .add__plan a span{
	display: inline-flex;
	align-items:center;
	justify-content:center;
}
 .add__plan a:hover{
	opacity:.7;
}
 .add__plan a{
	display: flex;
	align-items:center;
	justify-content:space-between;
	padding-left:30px;
	padding-right:30px;
	background: #FFFFFF;
	border-radius: 3px;
	min-height:45px;
	font-size:14px;
	line-height:21px;
	font-weight:600;
	color:rgba(45, 45, 45, 1);
	transition:.4s ease all;
}
 .add__plan{
	margin-top:10px;
	box-shadow:5px 10px 40px rgb(0 0 0 / 5%), 5px 10px 40px rgb(0 0 0 / 5%);
	width: 100%;
}
.order__settings--main .select button>span{
	color:rgba(158, 158, 158, 1);
}
.order__settings--main .select.chosen__select button>span{
	color:#444444;
}
.order__settings--main .input{
	background-color:#F7F7F7;
	border-radius:3px;
	border:0px;
}
.order__settings--main .double__settings .price__group label{
	position: absolute;
	left: 10px;
	top:9px;
	color:rgba(68, 68, 68, 1);
	font-size:14px;
	font-weight:400;
	line-height:21px;
}
.order__settings--main .double__settings .price__group input{
	text-align:right;
}
.order__settings--main .double__settings{
	display: grid;
	grid-template-columns:repeat(2 , 1fr);
	grid-column-gap:10px;
}
.order__heading.employers-page__heading{
	display: flex;
	justify-content:space-between;
	align-items:center;
}
.order__main{
	width: 100%;
}
.order__create--form{
	width: 100%;
	padding:25px 20px;
	background: #FFFFFF;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	border-top:3px solid #3F88C5;
	margin-bottom:25px;
}
.order__create--form:last-child{
	margin-bottom: 0px;
}
.order__create--form .head__create--controls .create__buttons .table__buttons{
	margin-right:15px;
}
.order__create--form .head__create--controls .create__buttons>a{
	min-width:20px;
	max-width:20px;
	min-height:20px;
	max-height:20px;
	display: inline-flex;
	align-items:center;
	justify-content:center;
	transition:.4s ease all;
	margin-right:15px;
}
.order__create--form .head__create--controls .create__buttons>a:last-child{
	margin-right: 0px;
}
.order__create--form .head__create--controls .create__buttons>a:hover{
	opacity:.7;
}
.order__create--form .head__create--controls .create__buttons{
	display: flex;
	justify-content:flex-end;
	align-items:center;
}
.order__create--form .head__create--controls{
	display: flex;
	align-items:center;
	justify-content:space-between;
}
.order__create--form .head__create--controls .create__info .services__dropdown .service__main .service__elem p{
	margin:0px;
	text-decoration: none;
	display: flex;
	align-items:center;
}
.order__create--form .head__create--controls .create__info .services__dropdown .service__main .service__elem> img{
	position: absolute;
	right: 15px;
}
.order__create--form .head__create--controls .create__info .services__dropdown .service__main .service__elem>.serv__info--text p>span>img{
	max-width:20px;
}
.order__create--form .head__create--controls .create__info .services__dropdown .service__main .service__elem>.serv__info--text p{
	color:#2D2D2D;
	font-size:16px;
	font-weight:600;
}
.order__create--form .head__create--controls .create__info .services__dropdown .service__main .service__elem>.serv__info--text p>span{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	margin-right:15px;
}
.order__create--form .head__create--controls .create__info .services__dropdown .service__main .service__elem{
	display: flex;
	padding-right:50px;
	align-items:center;
	border-bottom:1px solid #DADADA;
	padding-bottom:10px;
	padding-left:20px;
	position: relative;
}
.order__create--form .head__create--controls .create__info .services__dropdown{
	margin-left:30px;
}
.order__create--form .head__create--controls .create__info>a:hover{
	opacity:.7;
}
.order__create--form .head__create--controls .create__info>a{
	text-decoration: underline;
	color:rgba(14, 125, 125, 1);
	font-size:16px;
	line-height:21px;
	font-weight:600;
	transition:.4s ease all;
}
.order__create--form .head__create--controls .create__info .check__input{
	margin-right:20px;
}
.order__create--form .head__create--controls .create__info{
	display: flex;
	align-items:center;
}
.create__settings--inner{
	display: flex;
	align-items:flex-start;
	justify-content:space-between;	
}
.create__settings--inner .file__row{
	width: calc(100% - 8%);
}
.create__settings--inner>.inputs__row{
	width: 100%;
	margin-right:40px;
}
.order__create--element .create__settings--inner>.inputs__row>.double__settings{
	margin-bottom:20px;
}
.order__create--element .create__settings--inner .double__settings{
	display: grid;
	grid-template-columns: repeat(2 , 1fr);
	grid-column-gap: 25px;
}
.order__create--element .create__settings--inner{
	padding-left:40px;
	padding-right:40px;
	padding-bottom:20px;
	margin-top:40px;
}
.create__settings--inner .settings__info .input-animation--required:before{
	content:none;
}
.create__settings--inner .settings__info .datepicker .input{
	background-color:#F7F7F7;
	border:0px;
	height: 40px;
	font-size:12px;
}
.create__settings--inner .settings__info .input-animation__label{
	font-size:12px;
}
.create__settings--inner .inputs__row .settings__info>textarea{
	height: 105px;
	resize:none;
	width: 100%;
	background: #F7F7F7;
	border-radius: 3px;
	padding:20px;
	font-size:14px;
	line-height:21px;
	font-weight:400;
	color:rgba(59, 59, 59, 1);
}
.create__settings--inner .inputs__row .settings__info>p{
	margin-top: 0px;
	margin-bottom:10px;
	color:rgba(45, 45, 45, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
}
.create__settings--inner>.inputs__row>.settings__info:last-child{
	margin-bottom: 0px;
}
.create__settings--inner>.inputs__row>.settings__info{
	margin-bottom: 20px;
}
.create__settings--inner .settings__requirement.settings__info .select button>span{
	font-size:12px;
}
.create__settings--inner .file__row .file__head{
	margin-bottom:15px;
}
.create__settings--inner .file__row .file__head>a{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	transition:.4s ease all;
}
.create__settings--inner .file__row .file__head>a:hover{
	opacity:.7;
}
.create__settings--inner .file__row .file__head>.form-group input.input{
	border:0px;
	background-color:#F7F7F7;
}
.create__settings--inner .file__row .file__head>.form-group{
	width: 100%;
	margin-right:20px;
}
.create__settings--inner .file__row .file__head{
	display: flex;
	align-items:center;
}
.order__wrapper .upload__block.upload-content__drag-drop__old{
	padding:25px 0px;
}
.order__create--form .more__package>a span{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	margin-right:10px;
}
.order__create--form .more__package>a:focus , .order__create--form .more__package>a:active{
	border-color: rgba(14, 125, 125, 0.3);
}
.order__create--form .more__package>a:hover{
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}
.order__create--form .more__package>a{
	margin-right: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 140px;
    border: 1px solid rgba(14, 125, 125, 1);
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: rgba(14, 125, 125, 1);
    min-height: 40px;
    padding: 5px 10px;
    background: #FFFFFF;
    transition: .4s ease all;
    border-radius: 3px;
}
.order__create--form .more__package{
	margin-top:15px;
	display: flex;
	justify-content:flex-start;
	align-items:center;
}
.order__create--form .upload__block .upload-content__files-upload__button{
	width:185px;
}
.order__create--form .upload-content__files.upload__block{
	flex-direction:column;
}
.order__create--form .upload__block .upload-content__files-list{
	width: calc(100% - 15px);
	max-height:192px;
	margin-bottom: 0px;
	padding-left:10px;
	padding-right:10px;
}
.order__create--form .upload__block.upload-content__files{
	padding-top: 0px;
}
.order__create--form .switch__height>a:hover{
	opacity:.7;
}
.order__create--form .switch__height>a>span{
	display: inline-flex;
	margin-left:5px;
}
.order__create--form .switch__height>a{
	display: inline-flex;
	font-size:12px;
	align-items:center;
	font-weight:600;
	color:#0E7D7D;
	text-decoration: none;
	transition:.4s ease all;
}
.order__create--form .switch__height{
	display: flex;
	justify-content:flex-end;
	align-items:center;
}
.add__service--after{
	margin-top:20px;
	display: flex;
	justify-content:flex-start;
	align-items:center;
}
.add__service--after>a span{
	display: inline-flex;
	margin-left:20px;
}
.add__service--after>a:focus , .add__service--after>a:active{
    border-color: rgba(14, 125, 125, 0.3);
}
.add__service--after>a:hover{
	box-shadow:0px 3px 10px rgb(0 0 0 / 10%);
}
.add__service--after>a{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 140px;
    border: 1px solid rgba(14, 125, 125, 1);
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: rgba(14, 125, 125, 1);
    min-height: 40px;
    padding: 5px 10px;
    background: #FFFFFF;
    transition: .4s ease all;
    border-radius: 3px;
}
.collapse__order.active__collapse img{
	transform:rotate(-180deg);
}
.collapse__order img{
	transition:.4s ease all;
}

.create__settings--inner .datepicker:after{
	min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    background-color: #FFFFFF;
    background-size: 22px;
    box-shadow: 0px 4px 5px rgb(0 0 0 / 5%);
    border-radius: 3px;
    right: 0px;
}
.btn-create__file{
	background: url('../images/fileicon.svg')10px center no-repeat;
	background-size:16px!important;
}
div.upload__block .btn-add__input-label{
	background:url('../images/uploadicon.svg') 10px center no-repeat;
	background-size:18px!important;
}
.upload__block .btn-add__link:hover{
	background-color:rgba(14, 123, 123, 0.1)!important;
}
.upload__block .btn-disabled__link.btn-disabled__link:before{
	content:none;
}
.upload__block  .btn-disabled__link.btn-disabled__link{
	pointer-events: none;
	background: url('../images/disabledicon.svg')10px center no-repeat;
	background-size:18px!important;
	color:#9E9E9E!important;
	font-size:14px;
	font-weight:600;
}
.requirement__dropdown{
	padding:20px;
}
.requirement__dropdown>.requirement__search .search__requirement--field>input[type="submit"]:hover{
	opacity:.7;
}
.requirement__dropdown>.requirement__search .search__requirement--field>input[type="submit"]{
	min-width:20px;
	cursor:pointer;
	position: absolute;
	right: 15px;
	max-width:20px;
	min-height:20px;
	max-height:20px;
	background:url("../images/searchicon.svg");
	background-repeat: no-repeat;
	background-size:auto;
	background-position:center;
	transition:.4s ease all;
}
.requirement__dropdown>.requirement__search .search__requirement--field>input[type="text"]{
	width: 100%;
	height: 40px;
	background: #FFFFFF;
	border: 1px solid #F7F7F7;
	border-radius: 3px;
	padding-left:20px;
	padding-right:20px;
	color:rgba(59, 59, 59, 1);
	font-size:12px;
	font-weight:600;
	padding-right:40px;
}
.requirement__dropdown .requirement__list>.list__inner{
	max-height:95px;
	width: 100%;
	overflow-y:auto;
	padding-right:15px;
	min-height:95px;
}
.requirement__dropdown .requirement__list>.list__inner::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
.requirement__dropdown .requirement__list>.list__inner::-webkit-scrollbar-track {
  background: #E9E9E9;
}

/* Handle */
.requirement__dropdown .requirement__list>.list__inner::-webkit-scrollbar-thumb {
  background: #AEAEAE;
  border-radius:5px;
}
.requirement__dropdown .requirement__list>p{
	margin:0px;
	margin-bottom:10px;
	color:rgba(68, 68, 68, 1);
	font-size:14px;
	font-weight:600;
}
.requirement__dropdown .requirement__list{
	margin-top:12px;
}
.requirement__dropdown>.requirement__search .search__requirement--field{
	display: flex;
	position: relative;
	width: 100%;
	align-items:center;
}
.requirement__dropdown>.requirement__search>a:focus , .requirement__dropdown>.requirement__search>a:active{
	border-color:rgba(14, 125, 125, 0.3);
}
.requirement__dropdown>.requirement__search>a{
	display: inline-flex;
	transition:.4s ease all;
	align-items:center;
	justify-content:center;
	min-height:40px;
	min-width:65px;
	margin-left:10px;
	border: 1px solid #0E7D7D;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	font-size:14px;
	font-weight:600;
	color:#0E7D7D;
}
.requirement__dropdown>.requirement__search{
	display: flex;
	align-items:center;
}
.requirement__dropdown>p{
	margin:0px;
	margin-bottom:15px;
	color:#444444;
	font-size:14px;
	font-weight:600;
}
.upload__block .job__creation--button .btn-add__input_add .btn-add__input-label:hover{
	color:#fff;
	background:url('../images/jobplus.svg');
	background-repeat: no-repeat;
	background-size:auto;
	background-position:19px center;
	background-color:#198E8E!important;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
}
.upload__block .job__creation--button .btn-add__input_add .btn-add__input-label:focus , .upload__block .job__creation--button .btn-add__input_add .btn-add__input-label:active{
	color:#fff;
	background:url('../images/jobplus.svg');
	background-repeat: no-repeat;
	background-size:auto;
	background-position:19px center;
	border-radius: 3px;
	background-color: #198E8E!important;
	box-shadow: 0 0 0 0.2rem #86BDBD;
}
.order__container .btn-add__dropdown{
	z-index: 5;
}
.job__creation--button div.upload__block .btn-add_opened .btn-add__icon{
	background:url('../images/iconwhite.svg');
	background-repeat: no-repeat;
	background-size:auto;
	background-position:center;
    box-shadow: inset 0 0 0 2px rgba(255,255,255,.5);
}
.upload__block .job__creation--button .btn-add__icon:active , .upload__block .job__creation--button .btn-add__icon:focus{
	background:url('../images/iconwhite.svg');
	background-repeat: no-repeat;
	background-size:auto;
	background-position:center;
    box-shadow: 0 0 0 0.15rem #86bdbd;
    background-color:#198D8D;
}
.upload__block .job__creation--button .btn-add__icon:hover{
	background:url('../images/iconwhite.svg');
	background-repeat: no-repeat;
	background-size:auto;
	background-position:center;
    background-color:#198D8D;
}
.upload__block .job__create--button .btn-add__header{
	min-height:48px;
}
.upload__block .job__creation--button .btn-add__icon{
	background:url('../images/iconwhite.svg');
	background-repeat: no-repeat;
	background-size:auto;
	background-position:center;
    min-width: 45px;
    margin-right: 0px;
    margin-top: 1px;
    margin-left: 2px;
    max-width: 45px;
    min-height: 45px;
    max-height: 45px;
    transition:.4s ease all!important;
     box-shadow: 0 0 0 0rem #86bdbd;
}
.upload__block .job__creation--button.btn-add_opened .btn-add__icon{
	background:url('../images/iconwhite.svg');
	background-repeat: no-repeat;
	background-size:auto;
	background-position:center;
	background-color:#198D8D;
    box-shadow: 0 0 0 0.15rem #86bdbd;
}
.upload__block .job__creation--button .btn-add__input_add .btn-add__input-label{
	color:#fff;
	background:url('../images/jobplus.svg');
	background-repeat: no-repeat;
	background-size:auto;
	background-position:19px center;
	background-color:#0E7D7D;
}
.upload__block .job__creation--button .btn-add__header{
	background-color:#0E7D7D;
}
.upload__block .job__creation--button .btn-add__icon{
	background-color:#0E7D7D;
}
div.upload__block .btn-add__link.delete__order:active{
	border-color:rgba(255, 50, 50, .5)!important;	
}
div.upload__block .btn-add__link.delete__order:hover{
	background-color:rgba(255, 214, 214, 1)!important;
}
.upload__block .btn-add__link.delete__order{
	color:rgba(255, 50, 50, 1);
	background:url('../images/binicon.svg');
	background-repeat: no-repeat;
	background-size:auto;
	background-position:14px center;
}
div.upload__block .btn-add__link.cancel__order:active{
	border-color:rgba(45, 45, 45, .5)!important;	
}
div.upload__block .btn-add__link.cancel__order:hover{
	background-color:rgba(224, 224, 224, 1)!important;
}
.upload__block .btn-add__link.cancel__order{
	background:url('../images/cancelicon.svg');
	color:rgba(45, 45, 45, 1);
	background-repeat: no-repeat;
	background-size:auto;
	background-position:9px center;
}
.upload__block .btn-add__link.plus__order{
	background:url('../images/plus.svg');
	background-repeat: no-repeat;
	background-size:auto;
	background-position:13px center;
}
.task__heading .task__heading .task__group>span{
	display: inline-flex;
	margin-left:10px;
	padding:5px 10px;
	color:#2D2D2D;
	font-size:20px;
	font-weight:bold;
	background: #FFFFFF;
	border-radius: 5px;
}
.task__heading .task__heading .task__group>a:active, .task__heading .task__heading .task__group>a:focus{
	box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
}
.task__heading .task__heading .task__group>a{
	min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    background: #FFFFFF;
    border: 1px solid #0E7D7D;
    border-radius: 4px;
    transition: .4s ease all;
    background-color: #0E7D7D;
}
.task__heading .task__heading .task__group>a path{
	fill:#fff;
}
.task__heading .task__heading .task__group>h2{
	color:#9E9E9E;
	font-size: 25px;
	font-weight:700;
	margin:0px;
}
.task__heading .task__person>span{
	display: inline-flex;
	justify-content:center;
	align-items:center;
	margin-right:10px;
}
.task__heading .task__person>h2{
	margin:0px;
	color:#0E7D7D;
	font-size:25px;
	font-weight:bold;
}
.task__heading .task__person{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.task__heading .task__heading .task__group{
	display: flex;
	align-items:center;
	margin-right:5px;
}
.task__heading .task__heading{
	display: flex;
	align-items:center;
	flex-wrap:wrap;
	justify-content:flex-start;
}
.task__heading.employers-page__heading{
	margin-bottom:15px;
}
.source__table{
	padding:25px;
	background: #FFFFFF;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
}
.source__table .source__tags{
	width: 	40%;
	padding-left: 10px;
	padding-right: 10px;
}
.source__table .head__source{
	padding-bottom: 10px;
	border-bottom:1px solid #C4C4C4;
	width: 	100%;
	margin-bottom: 10px;
}
.content__source .source__main .source__file>span img{
	max-width: 24px;
}
.source__table .head__source .source__tags>p , .source__table .head__source .source__status>p{
	color:rgba(59, 59, 59, 1);
	font-size: 12px;
	font-weight: 400;
}
.content__source .source__main .source__file p{
	margin:0px;
	color:#3B3B3B;
	font-size: 12px;
	font-weight: 400;
	white-space: nowrap;
	  overflow: hidden;
	  min-width: 150px;
	  max-width: 150px;
	  text-overflow: ellipsis;
}
.content__source .source__main .source__file>span{
	display: 	inline-flex;
	margin-right: 10px;
	align-items:center;
	justify-content:center;
}
 .source__picker>span{
	color:#9E9E9E;
	font-size: 12px;
	font-weight: 400;
	margin-right: 5px;
	display: 	inline-flex;
}
 .source__picker .small__drop>a span{
	display: 	inline-flex;
	margin-right: 5px;
	color:rgba(63, 136, 197, 1);
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
}
.small__drop.inactive__small a img{
	display: 	none;
}
.small__drop.inactive__small>a span{
	color:rgba(158, 158, 158, 1)!important;
}
.small__drop.inactive__small>a{
	pointer-events: 	none;
}
 .source__picker{
	min-width: 120px;
	margin-right: 10px;
}
 .source__picker:last-child{
	margin-right: 	0px;
}
.content__source .source__tags{
	padding-right: 30px;
}
.content__source{
	position: 	relative;
	margin-bottom: 7px;
}
.content__source:last-child{
	margin-bottom: 	0px;
}
.content__source .remove__source>a:hover{
	opacity:.7;
}
.content__source .remove__source>a{
	display: 	inline-flex;
	align-items:center;
	justify-content:center;
	transition: .4s ease all;
}
.content__source .remove__source{
	position: 	absolute;
	z-index: 	2;
	right: 	5px;
}
.content__source .elem__tag a:hover{
	opacity:.7;
}
.content__source .source__tags{
	position: 	relative;
	top:5px;
}
.content__source .elem__tag a{
	display: 	inline-flex;
	align-items:center;
	justify-content:center;
	transition: .4s ease all;
}
.tags__wrapper .elem__tag a{
	display: 	inline-flex;
	align-items:center;
	justify-content:center;
	transition: .4s ease all;
}
.tags__wrapper .elem__tag{
	display: 	inline-flex;
	align-items:center;
	margin-right: 5px;
	padding:10px;
	background: #FFFFFF;
	border: 1px solid #E3E3E3;
	border-radius: 3px;
	margin-bottom: 5px;
}
.tags__wrapper .elem__tag p{
	margin:0px;
	color:rgba(63, 136, 197, 1);
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	margin-right: 10px;
}
.content__source .elem__tag p{
	margin:0px;
	color:rgba(63, 136, 197, 1);
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	margin-right: 10px;
}
.content__source .elem__tag{
	display: 	inline-flex;
	align-items:center;
	margin-right: 5px;
	padding:10px;
	background: #FFFFFF;
	border: 1px solid #E3E3E3;
	border-radius: 3px;
	margin-bottom: 5px;
}
.content__source{
	display: 	flex;
	flex-wrap:wrap;
	justify-content:flex-start;
	align-items:center;
}
 .source__picker .small__drop>a{
	display: 	flex;
	align-items:center;
}
 .source__picker>input{
	text-align: center;
	min-width: 44px;
	max-width: 44px;
	border-radius: 3px;
	margin-right: 5px;
	min-height: 30px;
	font-size: 12px;
	border:1px solid #DADADA;
}
 .source__picker>p{
	margin:0px;
	margin-right: 5px;
	text-decoration: 	underline;
	cursor: pointer;
	color:#2D2D2D;
	font-size: 12px;
	font-weight: 600;
}
.source__picker{
	display: 	flex;
	align-items:center;
	justify-content:flex-start;
}
.content__source .source__main .source__file{
	margin-right: 15px;
}
.content__source .source__main .source__file{
	display: 	flex;
	align-items:center;
	justify-content:flex-start;
}
.content__source .source__main>.check__input{
	margin-right: 20px;
}
.content__source .source__main{
	display: 	flex;
	align-items:center;
	justify-content:flex-start;
}
.source__table .head__source .source__main h6{
	margin:0px;
	margin-left: 20px;
	color:#0E7D7D;
	font-size: 18px;
	font-weight: bold;
}
.source__table .head__source .source__main{
	display: 	flex;
	align-items:center;
	justify-content:flex-start;
}
.source__table .source__main{
	width: 	60%;
}
.source__table .head__source{
	display: flex;
	align-items: center;
	justify-content:flex-start;
}
.employers-page__heading.task__heading>.task__heading{
	margin-right: 10px;
}
.task__heading.employers-page__heading .upload__block .wrap-btn-add{
	padding-right: 	0px;
	min-width: 180px;
	padding-left: 	0px;
}
.upload__block .job__creation--button .btn-add__input_add .btn-add__input-label{
	padding-left: 50px;
    padding-right: 15px;
    width: calc(100% - 2px);
    height: calc(100% - 4px);
    margin-left: 2px;
    white-space: nowrap;
    margin-top: 2px;
}
.source__table{
	margin-bottom: 20px;
}
.source__table:last-child{
	margin-bottom: 	0px;
}
.source__table.submitted__files  .source__status{
	width: 21%;
	padding-right: 30px;
}
.source__status .status__element>p{
	background-color:#F2F2F2;
	border-radius: 100px;
	display: 	inline-flex;
	margin:0px;
	padding:3px 10px;
	font-size: 10px;
	line-height: 18px;
	color:#787878;
	font-weight: 	600;
}
.source__table.submitted__files .source__tags{
	width: 19%;
	padding-right: 	10px;
	padding-left: 10px;
}
.source__table.submitted__files .head__source{
	position: 	relative;
}
.source__table.submitted__files .head__source>.dropdown .dropdown__box {
	min-width: 240px;
}
.source__table.submitted__files .actions{
	padding:0px;
}
.source__table.submitted__files .choose-actions-dropdown__box .actions__action{
	margin: 0px;
	width: 	100%;
}
.source__table.submitted__files .head__source>.dropdown{
	position: 	absolute;
	right: 0px;
	top:-10px;
}
.source__box{
	display: 	flex;
	align-items: flex-start;
}
.source__box .source__settings{
	min-width: 260px;
	max-width: 260px;
	margin-left: 25px;
}
.task__content>.task__el .requirements__text p{
	margin:0px;
	color:rgba(59, 59, 59, 1);
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
}
.task__content>.task__el.task__start{
	align-items:flex-start;
}
.source__box>.source__tables--container{
	width: 	calc(100% - 285px);
}
.employers-page__heading.task__heading{
	width:calc(100% - 285px);
	flex-wrap:nowrap;
}
.source__settings .task__info .head__task>p{
	margin:0px;
	color:rgba(255, 255, 255, 1);
	font-size: 16px;
	line-height: 24px;
	font-weight: 700;
}
.source__settings .task__info .task__content .task__el>p{
	margin:0px;
	color:rgba(45, 45, 45, 1);
	font-size: 12px;
	line-height: 18px;
	font-weight: 600;
}
.source__settings .task__info .task__content{
	padding:12px 15px;
}
.source__settings .task__info{
	background-color: #fff;
	background: #FFFFFF;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05), 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
}
.source__settings .task__info .head__task .task__status{
	display: 	inline-flex;
	align-items:center;
	justify-content:center;
}
.source__settings .task__info .head__task .task__status>p{
	margin:0px;
	border: 1px solid #E74500;
	border-radius: 3px;
	padding:3px 12px;
	color:rgba(231, 69, 0, 1);
	font-size: 12px;
	line-height: 18px;
	font-weight: 600;
	background-color: #fff;
}
.source__settings .task__info .head__task{
	width: 	100%;
	background: #0E7D7D;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	padding:9px 15px;
	display: 	flex;
	align-items:center;
	justify-content:space-between;
}
.source__settings .task__info{
	width: 	100%;
}
.task__content>.task__el{
	margin-bottom: 10px;
}
.task__content>.task__el:last-child{
	margin-bottom: 	0px;
}
.task__content .task__el .task__employee .employee__image img{
	max-width: 30px;
	border-radius: 250px;
}
.custom__select .select{
	width: 	auto;
	padding-right: 10px;
}
.custom__select .select__label:before{
	content:none;
}
.custom__select .select__label:after{
	right: 	-10px;
	    background-image: url(../images/bottomarrow.svg);
    background-size: 11px 6px;
    background-position: center;
    background-repeat: no-repeat;
}
.custom__select .select__label{
	background-color:transparent;
	overflow:visible;
	height: 	auto;
	border:0px;
	padding:0px;
	padding-right: 10px;
}
.task__content .task__el .price__dropdown>p{
	margin:0px;
	margin-right: 7px;
	font-size: 12px;
	line-height: 18px;
	font-weight: 600;
	min-width: 14px;
	color:rgba(68, 68, 68, 1);
}
.task__content .task__el .price__dropdown{
	display: 	flex;
	align-items:center;
	justify-content:flex-start;
}
.task__content .task__el .task__employee .employee__info p{
	margin:0px;
	color:rgba(59, 59, 59, 1);
	font-size: 12px;
	line-height: 18px;
	text-align: left;
	font-weight: 400;
}
.task__content .task__el .task__employee .employee__image{
	display: 	inline-flex;
	margin-right: 10px;
	align-items:center;
	justify-content:center;
}
.task__content .task__el .task__employee{
	display: 	flex;
	align-items:center;
	justify-content:flex-start;
}
.task__content>.task__el .size__task>input{
	height: 	35px;
	width: 	60px;
	text-align: right;
	padding-left: 15px;
	padding-right: 15px;
	background: #F7F7F7;
	border-radius: 3px;
	font-size: 12px;
	line-height: 12px;
	font-weight: 600;
	color:rgba(68, 68, 68, 1);
	margin-right: 10px;
}
.task__content>.task__el .size__task{
	display: 	flex;
	align-items:center;
	justify-content:flex-start;
}
.task__content>.task__el>div{
	width: 100%;
}
.task__content>.task__el>p{
	min-width: 100px;
}
.task__content .deadline__droppable>p>span{
	margin-left: 10px!important;
}
.task__content>.task__el{
	display: 	flex;
	align-items:center;
	justify-content:space-between;
}
.task__content .task__el .price__dropdown.total__price>p{
	color:rgba(14, 125, 125, 1);
	font-size: 16px;
	line-height: 18px;
	font-weight: 700;
}
.task__el.task__tags>p>a svg{
	max-width: 12px;
}
.task__el.task__tags>p>a rect{
	transition: .4s ease all;
}
.task__el.task__tags>p>a:hover svg rect{
	fill:#fff;
}
.task__el.task__tags>p>a:hover{
	background-color: #0E7D7D;
}
.task__el.task__tags>p>a{
	display: 	inline-flex;
	align-items:center;
	justify-content:center;
	min-width: 26px;
	max-width: 26px;
	min-height: 26px;
	max-height: 26px;
	border: 1px solid #0E7D7D;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	transition: .4s ease all;
	margin-left: 15px;
}
.task__el.task__tags>p{
	display: 	flex;
	align-items:center;
	justify-content:flex-start;
}
.comment__task>.elem__comment:last-child{
	margin-bottom: 	0px;
}
.comment__task>.elem__comment .head__comment>a{
	display: 	inline-flex;
	align-items:center;
	justify-content:center;
	position: 	absolute;
	right: 	20px;
	top:20px;
}
.comment__task>.elem__comment .head__comment{
	display: 	flex;
	align-items:center;
	justify-content:flex-start;
	padding-right: 50px;
	margin-bottom: 0px;
}
.comment__task>.elem__comment .comment__text{
	margin-top: 12px;
	margin-bottom: 18px;
}
.comment__task>.elem__comment .comment__text p{
	margin:0px;
	color:rgba(59, 59, 59, 1);
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
}
.comment__task .comment__controls{
	display: 	flex;
	justify-content:flex-end;
	margin-top: -18px;
}
.comment__task .comment__controls a span{
	display: 	inline-flex;
	align-items:center;
	justify-content:center;
	margin-left:5px;
}
.comment__task .comment__controls a:hover{
	opacity:.7;
}
.comment__task .comment__controls a{
	display: 	flex;
	align-items:center;
	justify-content:flex-start;
	color:rgba(14, 125, 125, 1);
	font-size: 12px;
	line-height: 18px;
	font-weight: 600;
	transition: .4s ease all;
}
.comment__task>.elem__comment .head__comment .comment__number{
	margin-right: 15px;
}
.comment__task>.elem__comment .head__comment .comment__user>h6 a:hover{
	opacity:.7;
}
.comment__task>.elem__comment .head__comment .comment__user>p{
	margin:0px;
	color:rgba(156, 156, 156, 1);
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;
}
.comment__task>.elem__comment .head__comment .comment__user>h6 a{
	text-decoration:none;
	color:#3B3B3B;
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;
	transition: .4s ease all;
}
.comment__task>.elem__comment .head__comment .comment__user>h6{
	margin:0px;
	color:rgba(156, 156, 156, 1);
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;
}
.comment__task>.elem__comment .head__comment .comment__number>span{
	display: 	inline-flex;
	color:rgba(156, 156, 156, 1);
	font-size: 20px;
	line-height: 20px;
	font-weight: 700;
}
.comment__task>.elem__comment{
	margin-bottom: 10px;
	background: #FFFFFF;
	box-shadow: 3px 5px 30px rgba(0, 0, 0, 0.05);
	padding:20px;
	position: 	relative;
	border-radius: 5px;
}
.comment__task{
	margin-top: 10px;
}
.comment__controls>a .arrow__comment--status{
	transform: rotate(-180deg);
	transition: .2s ease all;
}
.comment__controls.active__comment>a .arrow__comment--status{
	transform:rotate(0deg);
}
.source__table{
	/*overflow-x: auto;*/
}
.small__drop.custom__select.inactive__select .select__label:after{
	content:none;
}
.small__drop.custom__select.inactive__select .select>button{
	color:rgba(158, 158, 158, 1);
	pointer-events: none;
}
.small__drop.custom__select .select>button{
	font-size: 12px;
	color:rgba(63, 136, 197, 1);
	padding-right: 3px;
}
.small__drop.custom__select .select__label:after{
	width: 	7px;
	height: 	3.9px;
}
.upload__block .btn-add__dropdown{
	z-index: 	3;
}
.correction__modal>a{
	display: 	inline-flex;
	align-items:center;
	justify-content:center;
	position: 	absolute;
	top:70px;
	right:625px;
	transition:.4s ease all;
	min-width:50px;
    max-width:50px;
    opacity:0;
    min-height:50px;
    max-height:50px;
    border-radius:5px;
    display: inline-flex;
    align-items:center;
    justify-content:center;
    border:1px solid transparent;
    transition:.4s ease all;
}
.correction__modal>a:active , .correction__modal>a:focus{
	border-color:#fff;
}
.correction__modal>a:hover{
	background: #5C5A64;
}
.correction__modal .inner__modal--correction{
	width: 100%;
    max-width: 615px;
    right: -625px;
    height: calc(100% - 60px);
    margin-left: auto;
    margin-top: 60px;
    background: #FFFFFF;
    box-shadow: 5px 10px 30px rgb(0 0 0 / 10%);
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    position: relative;
    overflow-y: auto;
    transition:.4s ease all;
    padding: 50px 45px;
}
.correction__modal .inner__modal--correction>h2 , .modal__cancelation .inner__modal--cancelation>h2{
	color: #0E7D7D;
    margin: 0px;
    font-size: 25px;
    margin-bottom: 20px;
}
 .time__correction>p{
	margin:0px;
	margin-bottom: 10px;
}
.correction__modal{
	position: 	fixed;
	top:0px;
	right: 	0px;
	width: 	100%;
	height: 	100%;
	background-color:rgba(0,0,0,.7);
	z-index: 	15;
}
 .time__correction{
	max-width: 190px;
	width: 	100%;
	margin-bottom: 30px;
}
 .time__correction .input--border-alto {
    background: #F7F7F7;
    border: 0px;
    border-radius: 3px;
    height: 40px;
}
.correction__message{
	margin-bottom: 30px;
}
 .corrections__controls>input[type="submit"]:hover{
    background-color: #0a5858;
}
 .corrections__controls>input[type="submit"]:focus{
    color: #FFFFFF;
    background-color: #0a5858;
    -webkit-box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
    box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
}
 .corrections__controls>input[type="submit"]:active{
	color: #FFFFFF;
    background-color: #094f4f!important;
}
 .corrections__controls>a{
	min-width: 125px;
	min-height: 45px;
}
 .corrections__controls>input[type="submit"]{
	min-width: 210px;
	max-width: 210px;
	min-height: 45px;
	background: #0E7D7D;
	border: 2px solid #0E7D7D;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	font-size: 14px;
	color:#fff;
	font-weight: 600;
	transition: .4s ease all;
	margin-right: 15px;
	cursor:pointer;
}
 .corrections__controls{
	margin-top: 50px;
	display: 	flex;
	justify-content:flex-start;
	align-items:center;
}
 .correction__files>p{
	margin:0px;
	margin-bottom: 10px;
	color:rgba(45, 45, 45, 1);
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
}
 .correction__message .message__fields .message__area>textarea{
	height: 	94px;
	resize:none;
	padding-top: 10px;
	width: 	100%;
	background: #F7F7F7;
	color:rgba(59, 59, 59, 1);
	font-size: 14px;
	font-weight: 400;
	padding-left: 20px;
	padding-right: 	20px;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}
 .correction__message .message__fields>input[type="text"]{
	height: 	40px;
	padding-top: 10px;
	width:100%;
	background: #F7F7F7;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	color:rgba(59, 59, 59, 1);
	font-size: 14px;
	font-weight: 600;
	padding-left: 20px;
	padding-right: 	20px;
}
 .correction__message>p{
	margin:0px;
	margin-bottom: 10px;
	color:rgba(45, 45, 45, 1);
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
}
.correction__modal .inner__modal--correction .datepicker:after {
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    background-color: #FFFFFF;
    background-size: 22px;
    box-shadow: 0px 4px 5px rgb(0 0 0 / 5%);
    border-radius: 3px;
    right: 0px;
}
.time__correction>p{
	color:rgba(45, 45, 45, 1);
	font-size: 14px;
	font-weight: 600;
	line-height: 24px;
}
.time__correction .input-animation--required:before{
	content:none;
}
.modal__cancelation{
	position: 	fixed;
	top:0px;
	right: 	0px;
	width: 	100%;
	height: 	100%;
	background-color:rgba(0,0,0,.7);
	z-index: 	15;
}
.modal__cancelation>a{
	display: 	inline-flex;
	align-items:center;
	justify-content:center;
	position: 	absolute;
	top:70px;
	right:625px;
	transition:.4s ease all;
	min-width:50px;
    max-width:50px;
    min-height:50px;
    max-height:50px;
    border-radius:5px;
    display: inline-flex;
    align-items:center;
    justify-content:center;
    border:1px solid transparent;
    transition:.4s ease all;
}
.modal__cancelation>a:active , .modal__cancelation>a:focus{
	border-color:#fff;
}
.modal__cancelation>a:hover{
	background: #5C5A64;
}
.modal__cancelation .inner__modal--cancelation{
	width: 100%;
    max-width: 615px;
    right: -625px;
    height: calc(100% - 60px);
    margin-left: auto;
    margin-top: 60px;
    background: #FFFFFF;
    box-shadow: 5px 10px 30px rgb(0 0 0 / 10%);
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    position: relative;
    overflow-y: auto;
    padding: 50px 45px;
    transition:.4s ease all;
}
.modal__cancelation .inner__modal--cancelation .reason__cancelation{
	display: 	flex;
	justify-content:flex-start;
	align-items:center;
	margin-top: 20px;
}
.reason__cancelation .elem__cancelation>p{
	margin:0px;
	color:rgba(59, 59, 59, 1);
	font-size: 14px;
	line-height: 19px;
	font-weight: 600;
	transition: .4s ease all;
}
.reason__cancelation .elem__cancelation.active__cancelation{
	background-color: rgba(14, 125, 125, 1)
}
.reason__cancelation .elem__cancelation.active__cancelation p{
	color:#fff;
}
.reason__cancelation .elem__cancelation{
	margin-right: 15px;
	cursor:pointer;
}
.reason__cancelation .elem__cancelation:last-child{
	margin-right: 	0px;
}
.reason__cancelation .elem__cancelation:hover p{
	color:#fff;
}
.reason__cancelation .elem__cancelation:hover{
	background-color: rgba(14, 125, 125, 1)
}
.reason__cancelation .elem__cancelation{
	padding:10px 25px;
	background: #F7FBFB;
	transition: .4s ease all;
	border-radius: 4px;
}
.cancelation__box{
	padding-top: 30px;
}
.modal__cancelation .corrections__controls>input[type="submit"]{
	min-width: 150px;
	max-width: 150px;
}
.losses__message{
	margin-bottom: 30px;
}
.losses__message .losses__inner .loss__input input{
	width: 	100%;
	height: 	40px;
	background: #F7F7F7;
	border-radius: 3px;
	text-align:right;
	padding-right: 30px;
	padding-left: 30px;
	color:rgba(59, 59, 59, 1);
	font-size: 14px;
	font-weight: 600;
}
.losses__message .losses__inner .loss__input{
	width: 	100%;
	max-width: 160px;
	margin-right: 20px;
}
.losses__message .losses__inner{
	display: 	flex;
	justify-content:flex-start;
	align-items:center;
}
.losses__message .custom__select button>span{
	color:rgba(63, 136, 197, 1);
	font-size: 14px;
	line-height: 14px;
	font-weight: 600;
}
.losses__message>p{
	margin:0px;
	margin-bottom: 10px;
	color:rgba(45, 45, 45, 1);
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
}
.custom__select .select__dropdown{
	margin-top: 10px;
}
.projects__page.employers-page__wrap{
	overflow:inherit;
}
.services__table--row{
	display: flex;
	justify-content:center;
	flex-wrap:wrap;
}
.services__table--row>.row-group__item{
	padding:5px;
	margin-right: 0px!important;
}
.services__table--row>.row-group__item  img{
	min-width:20px;
	min-height:23px;
}


.project__surname .select  .select__label{
	height: 50px;
}
.task__heading .task__heading .task__group.task__group--active>h2{
	color:rgba(14, 125, 125, 1);
}
.order__table{
	padding-top:20px;
	padding-bottom:20px;
	background-color:#fff;
	background: #FFFFFF;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
}
.order__table .head__order--table .head__order--col p{
	margin:0px;
	color:rgba(120, 120, 120, 1);
	font-size:12px;
	line-height:16px;
	font-weight:600;
}
.order__table .head__order--table{
	display: flex;
	align-items:center;
	justify-content:space-between;
	margin-bottom:10px;
}
.order__table .title__order--table{
	padding-left:60px;
	margin-bottom:15px;
}
.order__table .title__order--table>h2{
	margin:0px;
	color:rgba(14, 125, 125, 1);
	font-size:18px;
	font-weight:700;
	line-height:24px;
}
.head__order--table .service__col , .content__order--table .service__col{
	min-width:145px;
	max-width:145px;
	width: 145px;
	position: relative;
	left: -20px;
}
.head__order--table .languages__col , .content__order--table .languages__col{
	min-width:105px;
	max-width:105px;
	width: 105px;
}
.head__order--table .deadline__col , .content__order--table .deadline__col{
	min-width:110px;
	max-width:110px;
	width: 110px;
}
.head__order--table .quantity__col , .content__order--table .quantity__col{
	min-width:75px;
	max-width:75px;
	width: 75px;
}
.head__order--table .measure__col , .content__order--table .measure__col{
	min-width:65px;
	max-width:65px;
	width: 65px;
}
.head__order--table .rate__col , .content__order--table .rate__col{
	min-width:60px;
	max-width:60px;
	width: 60px;
}
.head__order--table .sum__col , .content__order--table .sum__col{
	min-width:50px;
	max-width:50px;
	width: 50px;
}
.head__order--table .total__col , .content__order--table .total__col{
	min-width:65px;
	max-width:65px;
	width: 65px;
}

.head__order--table .total__col , .content__order--table .total__col{
	min-width:65px;
	max-width:65px;
	width: 65px;
}
.head__order--table .files__col , .content__order--table .files__col{
	min-width:110px;
	max-width:110px;
	width: 110px;
}
.head__order--table .checkbox__col , .content__order--table .checkbox__col{
	min-width:50px;
	max-width:50px;
	width: 50px;
	display: flex;
	justify-content:center;
	align-items:center;
}
.content__order--table .elem__order--table{
	display:flex;
	justify-content:space-between;
	align-items:center;
}
.content__order--table .service__col>span{
	color:rgba(158, 158, 158, 1);
	font-size:12px;
	line-height:18px;
	font-weight:600;
}
.content__order--table .service__col>p{
	margin:0px;
	color:rgba(45, 45, 45, 1);
	font-size:12px;
	line-height:18px;
	margin-bottom:-3px;
	font-weight:600;
}
.content__order--table .total__col>p{
	color:rgba(14, 125, 125, 1);
	font-size:12px;
	line-height:18px;
	font-weight:700;
	margin:0px;
}
.content__order--table .files__col .show__main:hover{
	    border-color: #0E7D7D;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
}
.content__order--table .files__col .show__main>span{
	    display: inline-flex;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
}
.content__order--table .files__col .show__main>p{
	    color: rgba(14, 125, 125, 1);
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
}
.content__order--table .files__col .show__main{
	display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    min-width: 85px;
    max-width: 85px;
    min-height: 30px;
    cursor: pointer;
    transition: .4s ease all;
}
.content__order--table .sum__col>p{
	color:rgba(44, 44, 44, 1);
	margin:0px;
	font-size:12px;
	line-height:18px;
	font-weight:400;
}
.content__order--table .rate__col>p{
	color:rgba(44, 44, 44, 1);
	margin:0px;
	font-size:12px;
	line-height:18px;
	font-weight:400;
}
.content__order--table .measure__col>p{
	color:rgba(44, 44, 44, 1);
	margin:0px;
	font-size:12px;
	line-height:18px;
	font-weight:400;
}
.content__order--table .quantity__col>p{
	margin:0px;
	color:rgba(44, 44, 44, 1);
	font-size:12px;
	line-height:18px;
	font-weight:400;
}
.content__order--table .deadline__col>p span{
	color:rgba(158, 158, 158, 1);
}
.content__order--table .deadline__col>p{
	font-size:12px;
	line-height:18px;
	font-weight:600;
	margin:0px;
}
.employers__table--content .button__action{
	padding-top:1px;
}
.remove__file{
	min-width:40px;
	max-width:40px;
	min-height: 40px;
	max-height:40px;
	transition:.4s ease all;
	border-radius: 3px;
	border: 1px solid transparent;
}
.remove__file:focus , .remove__file:active{
	border-color:#FF3232;
	background-color:#FFEBEB;
}
.remove__file:hover{
	background: #FFEBEB;
	opacity:1!important;
}
.controls__employee__settings>.column-group__item>.link{
	min-width:150px;
	min-height:40px;
	border: 1px solid #E3E3E3;
	box-sizing: border-box;
	border-radius: 3px;
	display: inline-flex;
	align-items:center;
	justify-content:center;
	transition:.4s ease all;
}
.controls__employee__settings>.column-group__item>.link:focus , .controls__employee__settings>.column-group__item>.link:active{
	box-shadow: 0 0 0 0.2rem rgb(218 218 218 / 50%);
}
.controls__employee__settings>.column-group__item>.link:hover{
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	opacity:1;
    background-color: #E3E3E3;
}

.controls__employee__settings>.column-group__item{
	margin-left:15px;
}
.controls__employee__settings{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.datepicker .input-animation__label{
	display: none!important;
}
.files__order{
	display: flex;
	align-items:center;
	justify-content:flex-start;
	position: relative;
	padding-left:15px;
}
.files__order .remove__files a{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	transition:.4s ease all;
}
.files__order .remove__files a:hover{
	opacity:.7;
}
.files__order .file__column--order .file__inner--element .file__image  img{
	max-width:24px;
}
.files__order .file__column--order .file__inner--element >p{
	margin:0px;
	color:rgba(59, 59, 59, 1);
	font-size:12px;
	font-weight:400;
	line-height:14px;
	min-width:170px;
	white-space: nowrap;
	  overflow: hidden;
	text-overflow: ellipsis;
}
.files__order .file__column--order .file__inner--element .file__image{
	display: flex;
	align-items:center;
	justify-content:center;
	margin-right:10px;
}
.files__order .file__column--order .file__inner--element{
	display: flex;
	align-items:center;
	min-width:210px;
	margin-right:10px;
}
.files__order .file__column--order .check__input{
	margin-right:10px;
}
.files__order .file__column--order{
	display: flex;
	align-items:center;
}
.files__order .remove__files{
	position: absolute;
	right: 25px;
}
.files__order--wrapper>.files__order:last-child{
	margin-bottom: 0px;
}
.files__order--wrapper>.files__order{
	margin-bottom:10px;
}
.files__order--wrapper{
	margin-top:10px;
	display: none;
	margin-bottom:10px;
}
.order__wrapper--element:last-child{
	border-bottom:1px solid #E3E3E3;
}
.content__order--table{
	padding-left:7px;
	padding-right:7px;
}

.jobs__table--element{
	position: relative;
}
.elem__order--table{
	position: relative;
	padding:7px 0px;
	border-top:1px solid #E3E3E3;
}
.elem__order--table:before , .jobs__table--element:before{
	content:"";
	position: absolute;
	left: 0px;
	top:0px;
	width: 0px;
	transition:.2s ease all;
	height: 100%;
	background-color:#0E7D7D;
	border-top-left-radius:3px;
	border-bottom-left-radius:3px;
}
.elem__order--table.active__order--table:before , .jobs__table--element.active__table--element:before{
	width: 5px;
}
.active__show--main.show__main p{
	color:#fff!important;
}
.source__tables--container .jobs__table--container .title__jobs--table{
	padding-left:60px;
}
.source__tables--container .jobs__table--container{
	margin-top:20px;
    padding-top: 20px;
    padding-bottom: 100px;
    background-color: #fff;
    background: #FFFFFF;
    box-shadow: 5px 10px 40px rgb(0 0 0 / 5%);
    border-radius: 3px;
}
.content__order--table .service__col{
	left:-24px;
}
.head__order--table .measure__col{
	margin-right: -5px;
	position: relative;
	left: -5px;
}
.head__order--table .quantity__col{
	position: relative;
	left:-3px;
}
.head__order--table .rate__col{
	position: relative;
	left:-2px;

}
.head__order--table .sum__col{
	position: relative;
	left: -3px;
}
.head__order--table .total__col{
	position: relative;
	left:-5px;
}
.jobs__table--container>.title__jobs--table{
	margin-bottom: 20px;
}
.jobs__table--container>.title__jobs--table>h2{
	margin:0px;
	color:rgba(14, 125, 125, 1);
	font-size: 18px;
	line-height:24px;
	font-weight:700;
}
.droppable__services .services__wrapper .elem__service{
	margin-bottom:5px;
}
.droppable__services .services__wrapper .elem__service img{
	min-width:25px;
}
.droppable__services .services__wrapper{
	display: flex;
	/*flex-wrap:wrap;*/
	position: relative;
	top:5px;
}

.jobs__table--element{
	display: flex;
	align-items:center;
	justify-content:space-between;
	border-top:1px solid #E3E3E3;
	padding:9px 0px;
}
.jobs__table--element .show__files--job{
	min-width:110px;
	max-width: 110px;
	width: 110px;
}
.jobs__table--element:last-child{
	border-bottom:1px solid #E3E3E3;
}
.jobs__table--box{
	padding-left:7px;
	padding-right:7px;
}
.jobs__table--box .jobs__table--element .joins__table--ordinary .jobs__table--check{
	min-width:50px;
	display: flex;
	justify-content:center;
}
.jobs__table--box .jobs__table--element  .jobs__table--services .elem__service{
	margin-right:5px;
	margin-bottom:5px;
}
.jobs__table--box .jobs__table--element  .jobs__table--services{
	display: flex;
	align-items: center;
	flex-wrap:wrap;
	position: relative;
	top:5px;
	justify-content:flex-start;
}
.jobs__table--box .time__job .time__job--info.due__time{
	border-color:#E7A400;
	position: relative;
	cursor:pointer;
}
.jobs__table--box .time__job .time__job--dropdown>p:last-child{
	margin-bottom: 0px;
}
.jobs__table--box .time__job .time__job--dropdown>p span{
	color:#3B3B3B;
}
.time__inn{
	margin:0px 3px;
}
.jobs__table--box .time__job .time__job--dropdown>p .edit__time{
	margin-left:10px;
}
.jobs__table--box .time__job .time__job--dropdown>p{
	width: 100%;
	min-height: 28px;
	padding:5px 15px;
	border: 1px solid #E3E3E3;
	border-radius: 100px;
	font-size:12px;
	line-height:18px;
	font-weight:600;
	display: flex;
	align-items:center;
	color:#9E9E9E;
	margin-bottom:5px;
}
.jobs__table--box .time__job .time__job--dropdown{
	background: #FFFFFF;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	position: absolute;
	left: 50%;
    transform: translate(-50%, 0);
    min-width:200px;
    padding:10px;
    top:40px;
    display: none;
}
.jobs__table--box .time__job{
	position: relative;
}
.jobs__table--box .time__job .time__job--info.due__time>p>span.icon__due{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	margin-right:10px;
}
.jobs__table--box .time__job .time__job--info.due__time>p .due__arrow{
	position: absolute;
	right: 0px;
	transition:.4s ease all;
	display: inline-flex;
	align-items:center;
	justify-content:center;
}
.jobs__table--box .time__job .time__job--info.due__time>p{
	display: flex;
	padding-right:35px;
	position: relative;
	color:rgba(255, 255, 255, 1);
	font-size:12px;
	line-height:21px;
	font-weight:600;
	align-items:center;
	justify-content:flex-start;
}
.jobs__table--box .time__job .time__job--info.due__time{
	background: #E7A400;
}
.jobs__table--box .time__job .time__job--info p{
	color:rgba(45, 45, 45, 1);
	font-size:12px;
	line-height:18px;
	font-weight:600;
	margin:0px;
}
.jobs__table--box .time__job .time__job--info.today__time>p{
	color:rgba(231, 164, 0, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
	margin:0px;
}
.jobs__table--box .show__files--job .show__main>span{
	    display: inline-flex;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
}
.jobs__table--box .show__files--job .show__main>p{
	    color: rgba(14, 125, 125, 1);
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
}
.jobs__table--box .show__files--job .show__main{
	display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    min-width: 85px;
    max-width: 85px;
    min-height: 30px;
    cursor: pointer;
    transition: .4s ease all;
}
.jobs__table--box .status__job--static .main__status--table{
	min-width:0px;
	padding-left:10px;
	padding-right:10px;
}
.jobs__table--box .time__job .time__job--info{
	min-width:150px;
	border: 1px solid #E3E3E3;
	border-radius: 100px;
	min-height:30px;
	display: inline-flex;
	align-items:center;
	justify-content:center;
}
.jobs__table--box .jobs__table--element .joins__table--ordinary .jobs__table--person .person__info a{
	display: inline-flex;
    text-decoration: underline;
    color: #2D2D2D;
    font-size: 14px;
    font-weight: 600;
    transition: .4s ease all;
}
.jobs__table--box .jobs__table--element .joins__table--ordinary .jobs__table--person .person__info a:hover{
	opacity:.7;
}
.jobs__table--box .jobs__table--element .joins__table--ordinary .jobs__table--person .person__info .person__image img{
	max-width:35px;
	width:35px;
	border-radius: 250px;
	height: 35px;
	object-fit:cover;
}
.jobs__table--box .jobs__table--element .joins__table--ordinary .jobs__table--person .person__info .person__image{
	display: flex;
	align-items:center;
	justify-content:center;
	margin-right:15px;
}
.jobs__table--box .jobs__table--element .joins__table--ordinary .jobs__table--person .person__info{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.jobs__table--box .jobs__table--element .joins__table--ordinary .jobs__table--number{
	margin-right:10px;
}
.jobs__table--box .jobs__table--element .joins__table--ordinary .jobs__table--number>p{
	margin:0px;
	color:rgba(59, 59, 59, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
	min-width:30px;
}
.jobs__table--box .jobs__table--element .joins__table--ordinary{
	display: flex;
	align-items:center;
	justify-content:flex-start;
	min-width:305px;
	max-width:305px;
}
.jobs__table--box .droppable__languages{
	min-width:100px;
	max-width:100px;
}
.droppable__languages .languages__inner>p{
	padding:5px 0px;
}
.jobs__table--box .droppable__languages .languages__inner>p{
	white-space: nowrap;
}
.jobs__table--box .droppable__languages .languages__inner{
	flex-wrap:wrap;
}
.jobs__table--box .jobs__table--element .jobs__table--services{
	min-width:50px;
	max-width:120px;
}
.jobs__table--box .time__job{
	min-width:150px;
	max-width:150px;
}
.jobs__table--box .status__job--static{
	min-width:150px;
	max-width:150px;
	display: flex;
	justify-content:center;
}
.jobs__table--box>.jobs__table--wrapper:last-child{
	border-bottom:1px solid #E3E3E3;
}
.content__order--table .checkbox__col , .jobs__table--box .jobs__table--element .joins__table--ordinary .jobs__table--check{
	opacity:0;
	transition:.4s ease all;
}
.elem__order--table:hover .checkbox__col{
	opacity:1;
}
.elem__order--table.active__order--table .checkbox__col{
	opacity:1;
}
.jobs__table--box .jobs__table--wrapper:hover .jobs__table--check{
	opacity:1;
}
.jobs__table--element.active__table--element .jobs__table--check{
	opacity:1!important;
}
.task__el .created__time p span{
	color:#9E9E9E;
}
.task__el.response__task{
	align-items:flex-start;
}
.task__el.response__task .elem__responsible{
	margin-bottom:7px;
}
.task__el.response__task .elem__responsible:last-child{
	margin-bottom: 0px;
}
.task__el.response__task .elem__responsible  .responsible__name p{
	margin:0px;
	font-size:12px;
	line-height:18px;
	font-weight:400;
	color:rgba(59, 59, 59, 1);
}
.task__el.response__task .elem__responsible .responsible__image img{
	max-width:30px;
	object-fit:cover;
	border-radius:250px;
	height: 30px;
}
.task__el.response__task .elem__responsible .responsible__image{
	display: flex;
	align-items:center;
	justify-content:center;
	margin-right:10px;
}
.task__el.response__task .elem__responsible{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.task__el .created__time p{
	margin:0px;
	color:#3B3B3B;
	font-size:12px;
	line-height:18px;
	font-weight:600;
}
.task__el.response__task>p{
	padding-top:7px;
}
.task__el .client__task .client__image img{
	max-width:30px;
	object-fit:cover;
	border-radius:250px;
	height: 30px;
}
.task__el .client__task .client__name p{
	margin:0px;
	font-size:12px;
	line-height:18px;
	font-weight:400;
	color:rgba(59, 59, 59, 1);
}
.task__el .client__task .client__image{
	display: flex;
	margin-right:10px;
	align-items:center;
	justify-content:center;
}
.task__el .client__task{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.task__el .profit__task p{
	margin:0px;
	color:rgba(68, 68, 68, 1);
	font-size:12px;
	line-height:18px;
	font-weight:600;
	margin-right:10px;
}
.task__el .profit__task span{
	padding:4px 13px;
	background: #00D06C;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 100px;
	color:rgba(255, 255, 255, 1);
	font-size:12px;
	font-weight:600;
	line-height:18px;
}
.task__el .profit__task{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.task__el.task__profit>p{
	padding-top:7px;
}
.task__el.task__profit{
	align-items:flex-start;
}
.task__el .documents__task .elem__document a{
	text-decoration: underline;
	padding:7px 10px;
	display: inline-flex;
	align-items:center;
	justify-content:center;
	color:rgba(14, 125, 125, 1);
	font-size:12px;
	line-height:16px;
	font-weight:600;
	border: 1px solid #E3E3E3;
	border-radius: 4px;
}
.task__el .documents__task .elem__document{
	margin-right:5px;
	margin-bottom:5px;
}
.task__el .documents__task{
	display: flex;
	flex-wrap:wrap;
	position: relative;
	top:0px;
	align-items:center;
	justify-content:flex-start;
}
.employers__table--content{
	width: 100%;
}
.employers__table--content>.table-holder{
	max-width:1410px;
}
.table-holder.table__stat table{
	max-width: 1410px;
}

.table-holder.table__stat{
	width: 100%;
	overflow-x: auto;
}

.droppable__languages .languages__inner>p{
	min-width:40px;
}

.elem__job--droppable>.droppable__table--head>.droppable__languages , .droppable__content>.droppable__languages{
	left: 0px;
}
.jobs__table--box{
	width: 100%;
}
.create__settings--inner.create__settings--longer>.inputs__row{
	width: 100%;
	max-width:405px;
}
.create__settings--inner.create__settings--longer .upload__block.upload-content__drag-drop__old{
	max-width:100%;
}
.employers-page__heading.order__status{
	max-width:100%;
	width: 100%;
}
.employers-page__heading.order__status>.form__progress{
	padding:10px;
	background: #FFFFFF;
	border-radius: 6px;
}
.employers-page__heading.order__status>.form__progress{
	position: relative;
	clip-path: polygon(0 0, 95% 0%, 100% 50%, 95% 100%, 0 100%, 0 95%);
	padding-right:50px;
}
.employers-page__heading.order__status>.form__progress:before{
	content:"";
	width: 100%;
	height: 3px;
	border-bottom-left-radius:6px;
	border-bottom-right-radius:6px;
	position: absolute;
	left:0px;
	background-color:#0E7D7D;
	bottom:0px;
}
.employers-page__heading.order__status .upload__block .wrap-btn-add{
	padding-right: 0px;
}
.order__status .form__progress .elem__progress.done__progress p span img{
	
}
.order__status .form__progress .elem__progress{
	margin-right:30px;
}
.order__status .form__progress .elem__progress:last-child{
	margin-right: 0px;
}
.order__status .form__progress .elem__progress.done__progress>p>span{
	border:0px;
}
.order__status .form__progress .elem__progress.done__progress>p{
	color:#3B3B3B;
	font-weight: 600;
}
.order__status .form__progress .elem__progress>p{
	color:#0E7D7D;
	font-size:14px;
	font-weight: bold;
	margin:0px;
}
.order__status .form__progress .elem__progress>p>span{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	border-radius:250px;

	border: 1px solid #0E7D7D;
	font-size:14px;
	line-height:19px;
	color:rgba(14, 125, 125, 1);
	font-weight:700;
	margin-right:10px;
	min-width:26px;
	max-width:26px;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
	min-height:26px;
	max-height:26px;
}
.order__status .form__progress .elem__progress>p>span>img{
	max-width:100%;
}
.order__status .form__progress .elem__progress>p{
	display: flex;
	align-items:center;
}
.order__status .form__progress .elem__progress{
	display: flex;
	align-items:center;
}
.order__status .form__progress{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.upload__block .job__creation--button .btn-add__input_add.btn__next .btn-add__input-label{
	background: url(../images/nexticon.svg);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 19px center;
}
.btn-add__link.start__btn{
    background: url(../images/nextdrop1.svg);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 10px center;
}
.btn-add__link.update__btn{
    background: url(../images/nextdrop2.svg);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 10px center;
}
.btn-add__link.delegate__btn{
    background: url(../images/nextdrop3.svg);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 10px center;
}
.btn-add__link.draft__btn{
    background: url(../images/nextdrop4.svg);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 10px center;
}
.projects__dropdown--container .search__projects>form>input[type="submit"]:hover{
	opacity:.7;
}
.projects__dropdown--container .search__projects>form>input[type="submit"]{
	position: 	absolute;
	min-width: 18px;
	max-width: 18px;
	min-height: 18px;
	max-height: 18px;
	right: 	10px;
	background:url("../images/searchproject.svg");
}
.projects__dropdown--container .search__projects>form{
	display: 	flex;
	align-items: center;
}
.projects__dropdown--container .search__projects>form>input[type="text"]{
	height: 50px;
	padding-left:20px;
	padding-right:40px;
	width: 	100%;
	border: 1px solid #BDBDBD;
	border-radius: 3px;
}
.projects__dropdown--container .search__projects{
	width: 100%;
	margin-bottom:20px;
	position: 	relative;
}
.service__drop--wrapper{
	position: relative;
}
.service__drop--wrapper .service__dropdown>.search__drop--fields>input[type="submit"]{
	position: absolute;
    min-width: 18px;
    max-width: 18px;
    min-height: 18px;
    max-height: 18px;
    right: 10px;
    background: url(../images/searchproject.svg);
}
.service__drop--wrapper .service__dropdown>.search__drop--fields>input[type="text"]{
	height: 50px;
    padding-left: 20px;
    padding-right: 40px;
    width: 100%;
    border: 1px solid #BDBDBD;
    border-radius: 3px;
}
.service__drop--wrapper .service__dropdown>.search__drop--fields{
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom:20px;
}
.service__drop--wrapper .service__dropdown{
	display: none;
	position: absolute;
	left: 0px;
	background: #FFFFFF;
	box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	min-width:265px;
	top:40px;
	z-index: 3;
	padding:15px 10px;
}
.service__drop--wrapper .service__list ul li a span{
	display: inline-flex;
	align-items:center;
	margin-right:15px;
	justify-content:center;
}
.service__drop--wrapper .service__list ul li a{
	transition:.4s ease all;
	border-radius: 4px;
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}
.service__drop--wrapper .service__list ul li a path{
	transition:.4s ease all;
}
.service__drop--wrapper .service__list ul li a:hover path{
	fill:#fff;
}
.service__drop--wrapper .service__list ul li a:hover{
	background-color:#0E7D7D;
	color:#fff;
}
.service__drop--wrapper .service__list{
	width:calc(100% + 10px);
	padding-right:5px;
	max-height: 240px;
	overflow-y:auto;
}
.service__drop--wrapper .service__list::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
.service__drop--wrapper .service__list::-webkit-scrollbar-track {
  background: #E9E9E9;
  border-radius:3px;
}

/* Handle */
.service__drop--wrapper .service__list::-webkit-scrollbar-thumb {
  background: rgba(174, 174, 174, 1);
  border-radius:5px;
}
.service__drop--wrapper .service__list ul li a{
	display: flex;
	width: 100%;
	align-items:center;
	padding:12px 15px;
}
.order__create--form .head__create--controls .create__info .services__dropdown .service__main .service__elem{
	cursor:pointer;
}
.service__drop--wrapper .service__dropdown{
	cursor:initial;
}
.service__drop--wrapper.service__elem>.serv__info--text p>span path{
	fill:#3F88C5;
}
.task__el .tags__wrapper{
	position: relative;
	top:3px;
}
.jobs__assignation .head__assignation .assignation__controls{
	display: flex;
	align-items:center;
	justify-content:flex-end;
}
.jobs__assignation .head__assignation .assignation__controls>.play__style>span{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	margin-left:15px;
}
.jobs__assignation .head__assignation .assignation__controls>.play__style:focus , .jobs__assignation .head__assignation .assignation__controls>.play__style:active{
	    box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
}
.jobs__assignation .head__assignation .assignation__controls>.assign__button:active , .jobs__assignation .head__assignation .assignation__controls>.assign__button:focus{
	border-color: rgba(14, 125, 125, 0.3);
}
.jobs__assignation .head__assignation{
	padding-right:15px;
	margin-bottom:20px;
}
.jobs__assignation .head__assignation .assignation__controls>.assign__button span{
	display: inline-flex;
	margin-left: 10px;
}
.jobs__assignation .head__assignation .assignation__controls>.assign__button{
	display: flex;
	align-items:center;
	justify-content:center;
	min-height:40px;
	padding:5px 20px;
	border: 2px solid #0E7D7D;
	border-radius: 3px;
	font-size:14px;
	line-height:19px;
	font-weight:600;
	transition:.4s ease all;
	text-decoration: none;
	color:#0E7D7D;
}
.jobs__assignation .head__assignation .assignation__controls>.play__style{
	min-width:110px;
	min-height:40px;
	background: #0E7D7D;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	display: flex;
	align-items:center;
	justify-content:center;
	color:#FFFFFF;
	font-size:14px;
	line-height:19px;
	padding:10px 20px;
	font-weight:600;
	transition:.2s ease all;
	margin-left:15px;
}
.application-form__item>.button:hover{
	background-color: #0a5858;
}
.application-form__item>.button:focus{
	color: #FFFFFF;
    background-color: #0a5858;
    -webkit-box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
    box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
}
.application-form__item>.button:active{
	color: #FFFFFF;
    background-color: #094f4f!important;
}
.jobs__assignation .head__assignation .assignation__controls>.play__style:hover{
    background-color: #0a5858;
}
.jobs__assignation .head__assignation .assignation__controls>.play__style:focus{
    color: #FFFFFF;
    background-color: #0a5858;
    -webkit-box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
    box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
}
.jobs__assignation .head__assignation .assignation__controls>.play__style:active{
	color: #FFFFFF;
    background-color: #094f4f!important;
}
.jobs__assignation .head__assignation>h2{
	margin:0px;
	color:rgba(14, 125, 125, 1);
	font-size:18px;
	line-height:24px;
	font-weight:700;
}
.jobs__assignation .head__assignation{
	display: flex;
	align-items:center;
	justify-content:space-between;
	padding-left:15px;
}
.jobs__assignation{
	padding-top:30px;
	padding-bottom:20px;
	background: #FFFFFF;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__filters {
	min-width:160px;
	max-width:160px;
	width: 160px;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__languages{
	min-width:110px;
	max-width:110px;
	width: 110px;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__date{
	min-width:100px;
	max-width:100px;
	width: 100px;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__main{
	display: flex;
	align-items:center;
	min-width:240px;
	max-width:240px;
	justify-content:flex-start;
}
.assignation__table .assignation__wrapper .assignation__elem{
	padding:7px 0px;
	padding-left:10px;
	padding-right:20px;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__main>.assign__radio{
	min-width:20px;
	margin-right:10px;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__date>p{
	margin:0px;
	color:rgba(59, 59, 59, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
}
.assignation__table .assignation__wrapper .assignation__elem:nth-child(1){
	border-top:1px solid #E3E3E3;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__main .assignation__profile .assignation__info.assigned__user>a:hover{
	opacity:.7;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__main .assignation__profile .assignation__info.assigned__user>a{
	color:rgba(45, 45, 45, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
	text-decoration: underline;
	transition:.4s ease all;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__main .assignation__profile .assignation__info.empty__assignment>p{
	color:rgba(158, 158, 158, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__main .assignation__profile .assignation__info.planned__assignation{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__main .assignation__profile .assignation__info.planned__assignation>a:hover{
	opacity:.7;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__main .assignation__profile .assignation__info.planned__assignation>a{
	display: inline-flex;
	margin-left:20px;
	justify-content:center;
	align-items:center;
	transition:.4s ease all;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__main .assignation__profile .assignation__info.planned__assignation>p{
	color:rgba(45, 45, 45, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__main .assignation__profile .assignation__image{
	margin-right:10px;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__main .assignation__profile .assignation__image img{
	min-width:35px;
	max-width:35px;
	min-height:35px;
	max-height:35px;
	border-radius:250px;
	object-fit:cover;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__main .assignation__profile .assignation__image{
	display: flex;
	align-items:center;
	justify-content:center;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__main .assignation__profile{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__main>.number__assignation>p{
	margin:0px;
	color:rgba(59, 59, 59, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
	min-width:27px;
	margin-right:10px;
}
.assignation__table .assignation__wrapper .assignation__elem{
	display: flex;
	align-items:center;
	justify-content:space-between;
	border-bottom:1px solid #E3E3E3;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__filters.clear__assign--filter>a span img{
	width: 12px;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__filters.clear__assign--filter>a span{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	margin-right:20px;
	position: absolute;
	left: 20px;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__filters.filter__chosen>a span{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	margin-right:20px;
	position: absolute;
	left: 20px;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__filters.filter__chosen>a{
	min-width:160px;
	min-height:30px;
	font-size:14px;
	padding-left:55px;
	line-height:21px;
	font-weight:600;
	display: inline-flex;
	align-items:center;
	justify-content:flex-start;
	background: #00C868;
	border-radius: 100px;
	color:#fff;
	transition:.4s ease all;
	border-radius: 100px;
	position: relative;
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__filters.clear__assign--filter>a:hover , .assignation__table .assignation__wrapper .assignation__elem .assignation__filters.filter__chosen>a:hover{
	box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
}
.assignation__table .assignation__wrapper .assignation__elem .assignation__filters.clear__assign--filter>a{
	min-width:160px;
	min-height:30px;
	padding-left:55px;
	position: relative;
	color:rgba(45, 45, 45, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
	display: inline-flex;
	align-items:center;
	justify-content:flex-start;
	background: #FFFFFF;
	border: 1px solid #E3E3E3;
	transition:.4s ease all;
	border-radius: 100px;
}
.note__modal , .url__modal{
	position: fixed;
	top:0px;
	left: 0px;
	z-index: 10;
	width: 100%;
	height: 100%;
	background-color:rgba(0,0,0,.75);
}
/*.note__modal>.note__inner .note__form .controls__note>input[type="submit"]:active , .note__modal>.note__inner .note__form .controls__note>input[type="submit"]:focus , .controls__url>input[type="submit"]:active ,  .controls__url>input[type="submit"]:focus{
	box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
}*/
.note__modal>.note__inner .note__form .controls__note>a ,  .controls__url>a{
	min-width:95px;
	min-height:35px;
	background: #FFFFFF;
	border: 2px solid #0E7D7D;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	display: inline-flex;
	align-items:center;
	justify-content:center;
	color:rgba(14, 125, 125, 1);
	font-size:14px;
	font-weight:600;
	transition:.4s ease all;
	line-height:14px;
}
.note__modal>.note__inner .note__form .controls__note>a:focus , .note__modal>.note__inner .note__form .controls__note>a:active ,  .controls__url>a:focus ,  .controls__url>a:active{
	    border-color: rgba(14, 125, 125, 0.3);
}
.note__modal>.note__inner .note__form .controls__note>input[type="submit"] ,  .controls__url>input[type="submit"]{
	min-width: 95px;
	min-height:35px;
	margin-right:10px;
	background: #0E7D7D;
	box-sizing: border-box;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	font-size:14px;
	line-height:14px;
	color:#fff;
	font-weight:600;
	cursor:pointer;
	transition: 0.2s;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-delay: 0s;
}
.note__modal>.note__inner .note__form .controls__note>input[type="submit"]:hover , .controls__url>input[type="submit"]:hover{
	background-color: #0a5858;
}
.note__modal>.note__inner .note__form .controls__note>input[type="submit"]:active , .controls__url>input[type="submit"]:active{
	    color: #FFFFFF;
    background-color: #094f4f!important;
}
.note__modal>.note__inner .note__form .controls__note>input[type="submit"]:focus , .controls__url>input[type="submit"]:focus{
    color: #FFFFFF;
    background-color: #0a5858;
    -webkit-box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
    box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
}
.note__modal>.note__inner .note__form{
	width: 100%;
}
.note__modal>.note__inner .note__form .title__note>input{
	height: 40px;
	width: 100%;
	background: #F7F7F7;
	border-radius: 3px;
	padding-left:50px;
	color:rgba(59, 59, 59, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
}
.note__modal>.note__inner .note__form .title__note>img{
	position: absolute;
	left: 0px;
	height: 40px;
}
.note__modal>.note__inner .note__form .title__note{
	display: flex;
	align-items:center;
	justify-content:flex-start;
	height: 40px;
	margin-bottom:10px;
	position: relative;
}
.note__modal>.note__inner .note__form .message__note>textarea{
	width: 100%;
	height: 200px;
	resize:none;
	padding:15px 20px;
	background: #F7F7F7;
	border-radius: 3px;
	font-size:14px;
	line-height:21px;
	color:rgba(59, 59, 59, 1);
	font-weight:400;
}
.note__modal>.note__inner .note__form .message__note{
	width: 100%;
}
.note__modal>.note__inner .note__form .controls__note , .controls__url{
	display: flex;
	align-items:center;
	justify-content:flex-start;
	margin-top:10px;
}
.note__modal>.note__inner .note__form>h2{
	margin-top: 0px;
	margin-bottom:15px;
	color:rgba(59, 59, 59, 1);
	font-size:16px;
	line-height:24px;
	font-weight:600;
}
.note__modal>.note__inner .note__form{
	width: 100%;
	max-width:890px;
	background: #FFFFFF;
	border: 1px solid #0E7D7D;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	padding:40px;
}
.note__modal>.note__inner{
	width: 100%;
	padding-left: 15px;
	padding-right:15px;
	height: 100%;
	display: flex;
	align-items:center;
	justify-content:center;
	padding-top:60px;
}
.quick__assign .quick__assign--search>form{
	width: 100%;
}
.quick__assign .quick__assign--search input{
	width: 100%;
	height: 50px;
	border: 1px solid #BDBDBD;
	border-radius: 3px;
	padding-left: 20px;
	padding-right:20px;
}
.quick__assign .quick__assign--search{
	display: flex;
	align-items:center;
	width: 100%;
	margin-bottom:20px;
}
.quick__assign>p{
	margin:0px;
	margin-bottom:10px;
	color:rgba(45, 45, 45, 1);
	font-size:14px;
	line-height:24px;
	font-weight:600;
}
.quick__assign .elem__worker .worker__left .worker__image img{
	min-width: 35px;
	min-height: 35px;
	max-width:35px;
	max-height:35px;
	border-radius:250px;
	object-fit:cover;
}
.quick__assign .elem__worker .worker__left .worker__name>a:hover{
	opacity:.7;
}
.quick__assign .elem__worker .worker__button>a span{
	display: inline-flex;
	margin-left:10px;
}
.quick__assign .elem__worker{
	margin-bottom:12px;
}
.quick__assign .elem__worker:last-child{
	margin-bottom:0px;
}
.quick__assign .worker__list{
	max-height:200px;
	width: calc(100% + 10px);
	padding-right:10px;
	overflow-y:auto;
}
.quick__assign .worker__list::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
.quick__assign .worker__list::-webkit-scrollbar-track {
  background: #E9E9E9;
  border-radius:3px;
}

/* Handle */
.quick__assign .worker__list::-webkit-scrollbar-thumb {
  background: rgba(174, 174, 174, 1);
  border-radius:5px;
}
.quick__assign .elem__worker .worker__button>a:focus , .quick__assign .elem__worker .worker__button>a:active{
	border-color:rgba(14, 125, 125, 0.3);
}
.quick__assign .elem__worker .worker__button>a{
	min-width:120px;
	min-height:40px;
	display: inline-flex;
	align-items:center;
	justify-content:center;
    border: 2px solid #0E7D7D;
    border-radius: 3px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    transition: .4s ease all;
    text-decoration: none;
    color: #0E7D7D;
}
.quick__assign .elem__worker .worker__left .worker__name>a{
	color:rgba(45, 45, 45, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
	text-decoration:underline;
	transition:.4s ease all; 
}
.quick__assign .elem__worker .worker__left .worker__image{
	display: flex;
	align-items:center;
	margin-right:10px;
	justify-content:center;
}
.quick__assign .elem__worker .worker__left{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.quick__assign .elem__worker{
	display: flex;
	align-items:center;
	justify-content:space-between;
}
.quick__assign{
	position: absolute;
	right: 0px;
	top:50px;
	min-width:410px;
	max-width:410px;
	background: #FFFFFF;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	z-index: 3;
	padding:15px 20px;
}











/*.workflow__table .workflow__head h5{
	color:rgba(157, 157, 157, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
	margin:0px;
}
.workflow__table .workflow__head{
	display: flex;
	align-items:center;
	justify-content:space-between;
}*/
.workflow__table--block{
	background: #FFFFFF;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	padding-top:0px;
}
/*.workflow__table>.catalog__filters {
	margin-bottom:40px;
}
.workflow__table .workflow__element{
	display: flex;
	align-items:center;
	justify-content:space-between;
	position: relative;
}
.workflow__table .workflow__element:before{
    content: "";
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    position: absolute;
    left: -1px;
    top: 0px;
    height: calc(100% + 2px);
    width: 0px;
    background-color: #0E7D7D;
    transition: .2s ease all;
    z-index: 2;
    top: -1px;
    opacity:0;
}
.workflow__table .workflow__element.active--workflow:before{
	width: 5px;
	opacity:1;
}
.workflow__table .workflow__employee .employee__profile .employee__image img{
	max-width:35px;
	min-width:35px;
	max-height:35px;
	min-height:35px;
	border-radius:250px;
	object-fit:cover;
}
.workflow__table .workflow__filter{
	min-width:130px;
	max-width:130px;
}
.workflow__table .workflow__head{
	margin-bottom:30px;
}
.workflow__table .workflow__registration{
	min-width:150px;
	max-width:150px;
	display: flex;
	justify-content:center;
	align-items:center;
}
.workflow__table .workflow__languages{
	min-width:120px;
	max-width:120px;
}
.workflow__table .workflow__projects{
	min-width:60px;
	max-width:60px;
	text-align:center;
}
.workflow__table .workflow__projects>p{
	color:rgba(45, 45, 45, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
	margin:0px;
}
.workflow__table .workflow__languages>p{
	color:rgba(45, 45, 45, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
	margin:0px;
}
.workflow__table .workflow__services{
	min-width:200px;
	max-width:200px;
}
.workflow__table .workflow__employee{
	min-width:200px;
	max-width:200px;
}
.workflow__table .workflow__employee .employee__profile .employee__name>a:hover{
	opacity:.7;
}
.workflow__table .workflow__employee .employee__profile .employee__name>a{
	text-decoration: underline;
	color:rgba(45, 45, 45, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
	transition:.4s ease all;
}
.workflow__table .workflow__employee .employee__profile .employee__image{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	margin-right:10px;
}
.workflow__table .workflow__employee .employee__profile{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.workflow__table .workflow__check{
	min-width:50px;
	max-width:50px;
	display: flex;
	justify-content:center;
	align-items:center;
}
*/


/*.workflow__wrapper{
	display: none;
}*/

.workflow__element{
	padding:7px 0px;
}
.workflow__elements--container>.workflow__element:nth-child(odd){
	background-color:#fff;
}
.workflow__elements--container>.workflow__element:nth-child(even){
	background-color:#F9F9F9;
}
.start__head--button>a span{
	display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
}
.start__head--button>a{
	min-width: 115px;
    min-height: 45px;
    background: #0E7D7D;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 19px;
    padding: 10px 20px;
    font-weight: 600;
    transition: .2s ease all;
    margin-left: 15px;
}
.start__head--button>a:hover{
    background-color: #0a5858;
}
.start__head--button>a:focus{
    color: #FFFFFF;
    background-color: #0a5858;
    -webkit-box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
    box-shadow: 0 0 0 0.2rem rgb(14 125 125 / 50%);
}
.start__head--button>a:active{
	color: #FFFFFF;
    background-color: #094f4f!important;
}
.workflow__element:hover>.workflow__check{
	opacity:1;
}
.workflow__element.active--workflow>.workflow__check{
	opacity:1!important;
}
.workflow__element>.workflow__check{
	opacity:0;
	transition:.4s ease all;
}
.workflow__registration .registration__button>a>span{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	margin-right:9px;
}
.workflow__registration .registration__button>a:focus , .workflow__registration .registration__button>a:active{
	border-color: rgba(14, 125, 125, 0.3);
}
.workflow__registration .registration__button>a{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	background: #FFFFFF;
	border: 1px solid #0E7D7D;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	min-width:65px;
	transition:.4s ease all;
	min-height:40px;
}
.registration__button .btn-add__dropdown .btn-add__link{
	display: block;
    padding: 8px 10px 9px 40px;
    border-radius: 3px;
    border: 1px solid #FFF;
    transition: all 0.4s ease;
    position: relative;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #0E7D7D;
    padding-left: 10px;
    text-decoration: none;
}
.registration__button .btn-add__dropdown .btn-add__link:focus , .registration__button .btn-add__dropdown .btn-add__link:active{
	background-color: #FFF;
    border-color: #B7D8D8;
    text-decoration: none;
}
.registration__button .btn-add__dropdown .btn-add__link:hover{
	    background-color: rgba(14, 123, 123, 0.1)!important;
}
.registration__button{
	position: relative;
}
.workflow__registration{
	position: relative;
}
.workflow__registration>.registration__button:hover+h5{
	opacity:1;
	top:-30px;
}
.workflow__registration>h5{
	position: absolute;
	min-width:150px;
	min-height:40px;
	background: #FFFFFF;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 10px 10px 10px 0px;
	display: flex;
	align-items:center;
	justify-content:center;
	z-index: 2;
	top:-40px;
	color:rgba(61, 61, 61, 1)!important;
	font-size:13px;
	line-height:17px;
	pointer-events: none;
	font-weight:400;
	opacity:0;
	transition:.3s ease all;
}
.registration__button .btn-add__dropdown{
	position: absolute;
    right: 0;
    top: 100%;
    background: #FFF;
    box-shadow: 0 4px 5px rgb(0 0 0 / 5%);
    border-radius: 3px;
    min-width: 100%;
    padding: 5px;
    margin-top: 7px;
    white-space: nowrap;
    min-width:100px;
    display: none;
}
.table-holder.project__holder{
	padding-bottom:220px;
}
.projects__catalog--container{
	margin-bottom:-220px;
}
.order__table .table__order--box{
	padding-bottom:220px;
	margin-bottom: -220px;
}
.source__padding--remover{
	padding-bottom:220px;
	margin-bottom: -220px;
}
.employers__table--content>.table-holder{
	padding-bottom:220px;
}
.controls__employee__settings>.column-group__item:nth-child(1){
	margin-left: 0px!important;
}

.url__modal .url__form>h2{
	margin:0px;
	margin-bottom:20px;
	color:rgba(59, 59, 59, 1);
	font-size:14px;
	line-height:21px;
	font-weight:600;
}
.url__modal .url__form{
	width: 100%;
    max-width: 890px;
    background: #FFFFFF;
    border: 1px solid #0E7D7D;
    box-shadow: 5px 10px 40px rgb(0 0 0 / 5%);
    border-radius: 10px;
    padding: 40px;
}
.url__modal>.url__inner{
	display: flex;
	align-items:center;
	justify-content:center;
	padding-top:60px;
	width: 100%;
	padding-left: 15px;
	padding-right:15px;
	height: 100%;
}
.url__list .elem__url>img{
	position: absolute;
	left: 0px;
	height: 40px;
}
.url__list{
	margin-bottom:15px;
	max-height:220px;
	overflow-y:auto;
	width:calc(100% + 10px);
	padding-right:10px;
}
.url__list::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
.url__list::-webkit-scrollbar-track {
  background: #E9E9E9;
}

/* Handle */
.url__list::-webkit-scrollbar-thumb {
  background: #AEAEAE;
  border-radius:5px;
}
.url__list .elem__url{
	margin-bottom:15px;
	position: relative;
}
.url__list .elem__url a{
	position: absolute;
	right: 10px;
	display: inline-flex;
	align-items:center;
	justify-content:center;
}
.url__list .elem__url:last-child{
	margin-bottom: 0px;
}
.url__list .elem__url>input{
	width: 100%;
	height: 40px;
	padding-left:50px;
	background: #F7F7F7;
	border-radius: 3px;
	color:rgba(59, 59, 59, 1);
	font-size:12px;
	line-height:18px;
	font-weight:600;
	padding-right:30px;
}
.url__modal .url__form .url__box >a>span{
	position: absolute;
	right: 15px;
}
.url__modal .url__form .url__box{
	min-height: 300px;
}
.url__modal .url__form .url__box>a:focus , .url__modal .url__form .url__box>a:active{
    border-color: rgba(14, 125, 125, 0.3);
}
.url__modal .url__form .url__box>a{

	display: flex;
	position: relative;
	align-items:center;
	justify-content:flex-start;
	padding-left:15px;
	color:rgba(68, 68, 68, 1);
	font-size:12px;
	line-height:24px;
	font-weight:600;
	background: #FFFFFF;
	border: 1px solid #0E7D7D;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	min-width:185px;
	min-height:40px;
	max-width:185px;
	transition:.4s ease all;
}
.url__list .elem__url{
	display: flex;
	align-items:center;
	justify-content:center;
	position: relative;
}
.menu__main--side:last-child{
	border-bottom:0px;
}
.workflow__table .workflow__employee{
	margin-left:-60px;
}
.order__wrapper{
	margin-bottom:15px;
}
.order__wrapper:last-child{
	margin-bottom: 0px;
}
.order__wrapper.hidden__order .collapse__order.active__collapse img{
	    transform: rotate(
-180deg
);
}
.finish__files>p span{
	font-weight:700;
}
.finish__files .finish__info>span{
	display: flex;
	justify-content:center;
	align-items:center;
	text-align:center;
	color:rgba(63, 136, 197, 1);
	font-size:16px;
	line-height:24px;
	font-weight:600;
}
.finish__files .finish__info>p{
	text-align:center;
	color:rgba(157, 157, 157, 1);
	font-size:16px;
	line-height:24px;
	font-weight:400;
	margin:0px;
}
.finish__files .finish__info>.finish__icon{
	display: flex;
	align-items:center;
	justify-content:center;
	margin-bottom:20px;
}
.finish__files .finish__info{
	display: flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
}
.finish__files>p{
	margin-top: 0px;
	margin-bottom:5px;
	font-size:14px;
	line-height:24px;
	color:rgba(68, 68, 68, 1);
}
.finish__files{
	width: 100%;
	padding:10px 15px;
	background: #F7F7F7;
	border-radius: 10px;
}
.advanced__drop{
	margin-left: 140px;
	position: relative;
}
.advanced__drop .advanced__drop--box>h6{
	margin-bottom:20px;
	margin-top: 0px;
	font-size:14px;
	line-height:21px;
	font-weight:600;
	color:rgba(14, 125, 125, 1);
}
.advanced__drop .advanced__drop--box{
	position: absolute;
	right: 0px;
	top:50px;
	min-width:355px;
	max-width:355px;
	padding:20px;
	background: #FFFFFF;
	z-index: 3;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
}
.advanced__drop .advanced__main>img{
	position: absolute;
	right: 15px;
}
.advanced__drop .advanced__main p{
	margin:0px;
	color:rgba(45, 45, 45, 1);
	font-size:12px;
	line-height:16px;
	font-weight:600;
}
.advanced__drop .advanced__main{
	min-width:200px;
	min-height:40px;
	background: #FFFFFF;
	border: 1px solid #0E7D7D;
	cursor:pointer;
	border-radius: 3px;
	position: relative;
	display: inline-flex;
	align-items:center;
	justify-content:flex-start;
	padding-left: 20px;
}
.advanced__drop .method__field>p{
	margin-bottom:10px;
	margin-top: 0px;
	color:rgba(59, 59, 59, 1);
	font-size: 12px;
	line-height:18px;
	font-weight:600;
}
.drop__box--sizes .drop__task .field__size>input{
	width: 90px;
	height: 35px;
	background: #F7F7F7;
	border-radius: 3px;
	padding-left: 20px;
	padding-right:20px;
	color:#000;
	font-size:12px;
	line-height:18px;
	font-weight:600;
	margin-right:10px;
}
.drop__box--sizes .drop__task .field__size{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.drop__box--sizes .drop__task .small__drop .select button{
	color:rgba(63, 136, 197, 1);
	font-weight:600!important;
	font-size:12px!important;
}
.drop__box--sizes .drop__task>p{
	margin:0px;
	margin-bottom:10px;
	color:rgba(59, 59, 59, 1);
	font-size:12px;
	line-height:18px;
	font-weight:600;
}
.drop__box--sizes{
	margin-top:20px;
	display: grid;
	grid-template-columns:repeat(2 , 1fr);
	grid-column-gap:15px;
}
.advanced__controls{
	display: flex;
	align-items: center;
	justify-content:flex-start;
	margin-top:15px;
}
.advanced__controls>a:last-child{
	margin-right: 0px;
}
.advanced__controls>a.active__advanced{
	background-color:#0E7D7D;
}
.advanced__controls>a.active__advanced path , .advanced__controls>a.active__advanced rect{
	fill:#fff;
}
.advanced__controls>a path, .advanced__controls>a rect{
	transition:.4s ease all;
}
.advanced__controls>a{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	min-width:30px;
	max-width:30px;
	min-height: 30px;
	max-height:30px;
	background: #FFFFFF;
	border: 1px solid #0E7D7D;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	margin-right:10px;
	transition:.4s ease all;
}
.submit__advanced{
	display: flex;
	justify-content:center;
	align-items:center;
	margin-top:30px;
}
.submit__advanced>input:hover{
    box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
}
.submit__advanced>input:active , .submit__advanced>input:focus{
    border-color: rgba(14, 125, 125, 0.3);
}
.submit__advanced>input{
	width:100%;
	cursor:pointer;
	height: 40px;
	background: #FFFFFF;
	border: 2px solid #0E7D7D;
	border-radius: 3px;
	color:rgba(14, 125, 125, 1);
	font-size:14px;
	font-weight:600;
	line-height:19px;
	transition:.4s ease all;
}
.advanced__picker--checkboxes .elem__advanced--picker>p{
	margin:0px;
	color:rgba(59, 59, 59, 1);
	font-size:12px;
	line-height:18px;
	font-weight:600;
	margin-bottom:10px;
}
.advanced__picker--checkboxes .elem__advanced--picker:last-child{
	margin-right: 0px;
}
.advanced__picker--checkboxes .elem__advanced--picker{
	margin-right:20px;
}
.advanced__picker--checkboxes{
	display: flex;
	margin-top:30px;
	align-items:center;
	justify-content:flex-start;
}

.elem__advanced--picker .switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 15px;

}

.elem__advanced--picker .switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.elem__advanced--picker .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #E3E3E3;
border-radius: 3px;
  -webkit-transition: .4s;
  transition: .4s;
}

.elem__advanced--picker .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: -3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  background: #0E7D7D;
border-radius: 3px;
}

/*.elem__advanced--picker input:checked + .slider {
  background-color: #2196F3;
}

.elem__advanced--picker input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}*/

.elem__advanced--picker .switch__adv--elem>label{
	margin:0px 15px;
}
.elem__advanced--picker .switch__adv--elem>p.active__switch--adv{
	font-weight:700;
	color:rgba(14, 125, 125, 1);
}
.elem__advanced--picker .switch__adv--elem>p{
	color:rgba(157, 157, 157, 1);
	font-size:14px;
	font-weight:600;
	line-height:18px;
	margin:0px;
}
.elem__advanced--picker .switch__adv--elem{
	display: flex;
	align-items:center;
}
.elem__advanced--picker input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}



.add__plan{
	position: relative;
}
.add__plan .add__plan--dropdown textarea{
	width: 100%;
	height: 100px;
	background: #FFFFFF;
	border: 1px solid #BDBDBD;
	border-radius: 3px;
	padding:15px 20px;
	color:#000;
	font-size:14px;
	line-height:24px;
	font-weight:400;
	resize:none;
	margin-bottom:20px;
}
.add__plan .add__plan--dropdown input[type="submit"]:focus , .add__plan .add__plan--dropdown input[type="submit"]:active{
	border-color:rgba(14, 125, 125, 0.3);
}
.add__plan .add__plan--dropdown input[type="submit"]{
	width: 100%;
	height: 40px;
	border: 2px solid #0E7D7D;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
	border-radius: 6px;
	background-color:transparent;
	color:rgba(14, 125, 125, 1);
	font-size:14px;
	line-height:19px;
	font-weight:600;
	transition:.4s ease all;
	cursor:pointer;
}
.add__plan .add__plan--dropdown{
	position: absolute;
	left: 0px;
	width: 100%;
	top:55px;
	background: #FFFFFF;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	padding:20px;
	display: none;
}
.note__wrapper{
	margin-bottom:20px;
	margin-top:20px;
}
.note__wrapper .elem__note .head__profile .head__prof--info>a{
	text-decoration: underline;
	color:rgba(45, 45, 45, 1);
	font-size:14px;
	line-height: 21px;
	font-weight:600;
}
.note__wrapper .elem__note .note__content p{
	margin:0px;
	color:rgba(45, 45, 45, 1);
	font-size:14px;
	line-height:19px;
	font-weight:400;
}
.note__wrapper .elem__note .note__head{
	display: flex;
	align-items:center;
	justify-content:space-between;
	margin-bottom:20px;
}
.note__wrapper .elem__note .head__profile {
	display: flex;
	align-items:center;
	justify-content:flex-start;
	margin-right:15px;
	margin-bottom:0px;
}
.note__wrapper .elem__note .head__profile .head__prof--image img{
	max-width:35px;
	min-width:35px;
	min-height:35px;
	max-height:35px;
	border-radius: 250px;
	object-fit: cover;
}
.note__wrapper .elem__note .head__profile .head__prof--image{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	margin-right:10px;
}
.note__wrapper .elem__note{
	background: #FFFFFF;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	width: 100%;
	padding:20px;
}
.note__wrapper .elem__note .note__head{
	display: flex;
	align-items:center;
	justify-content:space-between;
}
.create__settings--inner .file__row{
	width: 50%;
}
.create__settings--inner>.inputs__row{
	width: 100%;
}
.create__settings--inner .file__row{
	width: 100%;
}
.create__settings--inner.create__settings--longer>.inputs__row{
	width: 50%;
	max-width:100%;
}
.file__row .upload__block.upload-content__files{
	max-width:100%;
}
.select__responsible{
	padding:15px 20px;
}
.select__responsible .responsible__search input{
	width: 100%;
	height: 50px;
	padding-left: 20px;
	padding-right:20px;
	background: #FFFFFF;
	border: 1px solid #BDBDBD;
	border-radius: 3px;
	font-size:14px;
	line-height:24px;
	font-weight:400;
}
.select__responsible .responsible__search{
	width: 100%;
}
.select__responsible>p{
	margin-bottom:10px;
	margin-top: 0px;
	color:rgba(45, 45, 45, 1);
	font-size:14px;
	line-height:24px;
	font-weight:600;
}
.select__response--picker .responsible__search{
	margin-bottom:10px;
}
 .responsible__list .el__responsible--list .response__image img{
 	min-width:45px;
 	max-width:45px;
 	min-height:45px;
 	max-height:45px;
 	border-radius:250px;
 	border: 3px solid transparent;
 	padding:3px;
 	transition:.4s ease all;
 }
 .responsible__list .el__responsible--list .response__image{
 	display: inline-flex;
 	align-items:center;
 	margin-right:10px;
 	justify-content:center;
 }
 .responsible__list .el__responsible--list.active__responsible--picked .response__controls>a>span:nth-child(2){
 	opacity:0;
 }
 .responsible__list .el__responsible--list.active__responsible--picked .response__controls>a>span:nth-child(1){
 	background-color:#fff;
 }
 .responsible__list .el__responsible--list.active__responsible--picked .response__image img{
 	border-color:#0E7D7D;
 }
 .responsible__list .el__responsible--list.active__responsible--picked .response__controls>a{
 	background-color:rgba(14, 125, 125, 1);
 }
  .responsible__list .el__responsible--list .response__name>a:hover{
  	opacity:.7;
  }
  .el__responsible--list .response__list--left{
  	display: flex;
  	align-items:center;
  }
   .responsible__list .el__responsible--list{
   	margin-bottom:10px;
   }
    .responsible__list .el__responsible--list:last-child{
    	margin-bottom: 0px;
    }
  .responsible__list .el__responsible--list .response__controls{
  	margin-left:10px;
  	display: flex;
  	align-items:center;
  	justify-content:center;
  }
    .responsible__list .el__responsible--list .response__controls>a{
    	min-width:24px;
    	max-width:24px;
    	min-height:24px;
    	display: inline-flex;
    	align-items:center;
    	justify-content:center;
    	max-height:24px;
    	border: 2px solid #0E7D7D;
		border-radius: 3px;
		transition:.3s ease all;
		position:relative;
    }
    .responsible__list .el__responsible--list .response__controls>a>span:nth-child(1){
    	width: 12px;
    	height:2px;
    	position: absolute;
    	left: 0px;
    	right: 0px;
    	margin-left:auto;
    	margin-right:auto;
    	background-color:#0E7D7D;
    	transition:.3s ease all;
    }
     .responsible__list .el__responsible--list .response__controls>a>span:nth-child(2){
    	width: 2px;
    	height:12px;
    	position: absolute;
    	left: 0px;
    	right: 0px;
    	margin-left:auto;
    	margin-right:auto;
    	background-color:#0E7D7D;
    	transition:.3s ease all;
    }
  .responsible__list .el__responsible--list .response__name>a{
  	text-decoration: underline;
  	color:rgba(45, 45, 45, 1);
  	font-size:14px;
  	line-height:21px;
  	font-weight:600;
  	transition:.4s ease all;
  }
 .responsible__list .el__responsible--list{
	display: flex;
	align-items:center;
	justify-content:space-between;
}
.select__response--picker{
	min-width:310px;
	right: 0px;
}

.select__responsible .responsible__list{
	padding-right:10px;
	overflow-y:auto;
	max-height:280px;
}
.select__responsible .responsible__list::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
.select__responsible .responsible__list::-webkit-scrollbar-track {
  background: #E9E9E9;
}

/* Handle */
.select__responsible .responsible__list::-webkit-scrollbar-thumb {
  background: #AEAEAE;
  border-radius:5px;
}
.responsible__list .el__responsible--list .response__controls>a:hover{
    box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
}
.upload__block .btn-add__dropdown{
	padding:10px!important;
}
.upload__block .btn-add__link, .upload__block .btn-add__dropdown .btn-add__input-label{
	font-size:16px!important;
	border-radius:4px!important;
}

.actions__action-icon{
	max-width: 16px;
}

.choose-actions-dropdown__box .actions__action{
	margin-bottom: 3px;
}
.choose-actions-dropdown__box .actions__action:last-child{
	margin-bottom: 	0px;
}
.upload__block  .btn-add__item{
	margin-bottom: 3px;
}
.upload__block  .btn-add__item:last-child{
	margin-bottom: 	0px;
}
.upload__block .btn-add__link{
	padding-left: 45px!important;
}
.actions__action-icon{
	min-width: 18px;
	max-width: 18px;
	margin-right: 15px;
	display: 	flex;
	justify-content:center;
	align-items:center;
}
.actions__action-icon img{
	width: 	auto;
}
.choose-actions-dropdown__box .actions__action-title{
	white-space: nowrap;
}


.time__job--info.due__time{
	transition:.4s ease all;
	border:1px solid #E7A400;
}
.time__job--info.due__time:hover{
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}
.time__job--info.due__time.active__due{
	border: 1px solid #916F1E!important;
}

/*TEST*/
.choose-actions-dropdown__box .actions__action-title{
	font-size: 16px;
}
.registration__button .btn-add__dropdown{
	padding:10px!important;
}
.registration__button .btn-add__dropdown .btn-add__link{
	font-size:16px!important;
	border-radius:4px!important;
}

.find__client--default .client__input{
	margin-bottom:20px;
}
.find__client--default .client__list{
	max-height:260px;
	overflow-y:auto;
	padding-right:10px;
}
.find__client--default .client__list::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
.find__client--default .client__list::-webkit-scrollbar-track {
  background: #E9E9E9;
}

/* Handle */
.find__client--default .client__list::-webkit-scrollbar-thumb {
  background: #AEAEAE;
  border-radius:5px;
}
.find__client--default .client__list .elem__client--list{
	margin-bottom:3px;
	width: 100%;
}
.find__client--default .client__list .elem__client--list:last-child{
	margin-bottom: 0px;
}
.find__client--default .client__list .elem__client--list>a{
	padding:10px 15px;
	border-radius: 4px;
	transition:.4s ease all;
	display: flex;
	width:100%;
	align-items:center;
	justify-content:flex-start;
	font-size:16px;
	color:rgba(14, 125, 125, 1);
	font-weight:600;
}
.find__client--default .client__list .elem__client--list>a:hover{
	background-color:#EBF1F1;
}
.find__client--default .client__input>a:hover{
    box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
}
.find__client--default .client__input>a:focus , .find__client--default .client__input>a:active{
	border-color: rgba(14, 125, 125, 0.3);
}
.find__client--default .client__input>a span{
	display: inline-flex;
	align-items:center;
	margin-right:10px;
}
.find__client--default{
	padding:20px;
}
.dropdown__client.select__dropdown{
	right: 0px;
}
.find__client--default .client__input>a{
	display: inline-flex;
	color:rgba(14, 125, 125, 1);
	font-size:16px;
	font-weight:600;
	height: 50px;
	border: 1px solid #0E7D7D;
	border-radius: 4px;
	margin-left:10px;
	align-items:center;
	justify-content:center;
	min-width:95px;
	transition:.4s ease all;
	min-height:50px;
}
.find__client--default .client__input>input{
	height: 50px;
	min-width:175px;
	max-width:175px;
	padding-left: 20px;
	padding-right:20px;
	font-size:14px;
	line-height:24px;
	font-weight:400;
	border-radius:3px;
	border:1px solid #BDBDBD;
	color:#000;
}
.find__client--default .client__input{
	display: flex;
	align-items:center;
	justify-content:space-between;
}
.workflow__employee{
	position: relative;
}
.workflow__employee .search__workflow>form{
	display: flex;
	align-items:center;
}
.workflow__employee .search__workflow>form>input[type="submit"]:hover{
	opacity:.7;
}
.workflow__employee .search__workflow>form .employee__destroy a:hover{
	opacity:.7;
}
.workflow__employee .search__workflow>form .employee__destroy a{
	min-width:27px;
	margin-left:10px;
	max-width:27px;
	min-height:27px;
	max-height:27px;
	background: #F6F6F6;
	border-radius: 3px;
	display: inline-flex;
	align-items:center;
	justify-content:center;
	transition:.4s ease all;
}
.workflow__employee .search__workflow>form>input[type="submit"]{
	min-width:16px;
	max-width:16px;
	min-height:16px;
	max-height:16px;
	background:url('../images/vectorsearchgrey.svg');
	background-repeat: no-repeat;
	background-size:auto;
	background-position:center;
	position: absolute;
	right: 50px;
	cursor:pointer;
	transition:.4s ease all;
}
.workflow__employee .search__workflow>form>input[type="text"]{
	height: 40px;
	width:100%;
	padding-left:15px;
	padding-right:35px;
	font-size:12px;
	line-height:24px;
	font-weight:400;
	border:1px solid #BDBDBD;
	border-radius:3px;
}
.workflow__employee .search__workflow{
	position: absolute;
	left: 0px;
	height: 100%;
	background-color:#fff;
	width: 100%;
	top:-4px;
}
.workflow__employee>h5>a:hover{
    border-color: #0E7D7D;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
}
.workflow__employee>h5>a:focus ,.workflow__employee>h5>a:active{
	border-color: rgba(14, 125, 125, 0.3);
}
.workflow__employee>h5>a{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	min-width:30px;
	max-width:30px;
	min-height:30px;
	max-height:30px;
	border: 1px solid #E3E3E3;
	margin-left:10px;
	transition:.4s ease all;
	border-radius: 4px;
}
.workflow__employee>h5{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.service__placeholder{
	color:#9E9E9E!important;
	font-weight:600!important;
	font-size:14px!important;
}
.order__create--form .head__create--controls .create__info .services__dropdown .service__main .service__elem{
	min-width:170px;
	min-height:33px;
}
.currency__picker .select  button:after{
	right: 8px;
}
.currency__picker .val__currency>input{
	width: 85px;
	height:35px;
	background: #F7F7F7;
	border-radius: 3px;
	padding-right:15px;
	padding-left:15px;
	text-align:right;
	font-weight:600;
	font-size:14px;
}
 .currency__dropdown ul li{
	width: 100%;
	display: flex;
}
 .currency__dropdown ul::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
.currency__picker .currency__dropdown ul::-webkit-scrollbar-track {
  background: #E9E9E9;
}

/* Handle */
.currency__picker .currency__dropdown ul::-webkit-scrollbar-thumb {
  background: #AEAEAE;
  border-radius:5px;
}
 .currency__dropdown ul{
	max-height: 240px;
	overflow-y:auto;
	width:calc(100% + 5px);
	padding-right:5px;
}
 .currency__dropdown ul li a:hover{
	background: #EBF1F1;
}
 .currency__dropdown ul li a span{
	display: inline-flex;
	margin-right:5px;
}
 .currency__dropdown ul li a{
	min-height: 40px;
	font-size:14px;
	line-height:21px;
	font-weight:600;
	color:rgba(45, 45, 45, 1);
	display: flex;
	border-radius:4px;
	width:100%;
	align-items:center;
	padding:0px 15px;
	transition:.4s ease all;
}
 .currency__dropdown{
	min-width:260px;
	background: #FFFFFF;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	padding:10px;
}
 .select__dropdown {
	left: 50%;
	margin-top:10px;
    transform: translate(-50%,0%);
}
.currency__picker .select  button:before{
	background-color:#fff;
	width: 10px;
}
.currency__picker .select  button{
	padding-left:10px;
	max-width:50px;
	height: 35px;
	background: #FFFFFF;
	border: 1px solid #E3E3E3;
	border-radius: 3px;
}
.currency__picker{
	margin-left:20px;

	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.select__label:after{
	background-image: url(../images/servicearrow.svg);
    background-size: 11px 6px;
    background-position: center;
    background-repeat: no-repeat;
}
.content__order--settings .double__settings>.currency__picker{
	margin-left: 0px;
}
.content__order--settings .double__settings  .select {
	max-width:95px;
}
.currency__picker  .select button>span{
	color:#444444;
}
.content__order--settings .select__label{
	height: 35px;
}
.double__settings .currency__picker .val__currency{
	width: 100%;
}
.double__settings .currency__picker .val__currency>input{
	min-width: 75px;
	max-width:100%;
	width: 100%;
}
.content__order--settings .double__settings .currency__picker .select{
	max-width:50px;
}
.vat__inside--ordering button{
	padding-left: 7px;
}
.order__create--form .head__create--controls .create__info{
	width: 100%;
	padding-right:20px;
}
.order__create--form .head__create--controls .create__info .services__dropdown .service__main .service__elem p{
	white-space: nowrap;
}
.head__create--controls .select.ex__head{
	width: 100%;
	max-width: 105px;
}
.head__create--controls .select.invoicing__head{
	max-width:140px;
	width: 100%;
	margin-left: 10px;
}
.order__create--form .head__create--controls .create__info .services__dropdown{
	margin-left: 20px;
}
.order__create--form .head__create--controls .create__info>a{
	white-space: nowrap;
}
.head__create--controls .currency__picker{
	margin-right:10px;
}
.head__create--controls .select__label{
	height: 35px;
}
.head__create--controls .row-group--sm > .row-group__item:not(:last-child){
	margin-right:0px;
}
.order__create--form .head__create--controls .create__buttons .table__buttons{
	margin-right: 5px;
}
.head__part--control{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.list__inner .elem__used>a:hover{
	background-color:#EBF1F1;
}
.list__inner .elem__used>.remove__req>a{
	min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    transition: .4s ease all;
    border-radius: 3px;
    border: 1px solid transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: .4s ease all;
}
.list__inner .elem__used.current__req>.remove__req{
	display: block;
}
.list__inner .elem__used>.remove__req{
	margin-left:10px;
	display: none;
}
.list__inner .elem__used>.remove__req>a:hover{
    background: #FFEBEB;
    opacity: 1!important;
}
.list__inner .elem__used>.remove__req>a:active , .list__inner .elem__used>.remove__req>a:focus{
	border-color: #FF3232;
    background-color: #FFEBEB;
}
.list__inner .elem__used>a{
	min-height:40px;
	padding:10px 20px;
	border: 1px solid #D2D2D2;
	border-radius: 6px;
	display: flex;
	align-items:center;
	justify-content:flex-start;
	transition:.4s ease all;
	color:rgba(59, 59, 59, 1);
	font-size:12px;
	line-height:20px;
	width: 100%;
	font-weight:600;
}
.new__req>.inner__new--req .button__faq>a.create__faq{
    background: #0E7D7D;
    border: 1px solid #0E7D7D;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 19px;
    padding: 5px 10px;
    font-weight: 600;
    transition: .4s ease all;
}
.new__req>p{
	margin:0px;
	margin-bottom:10px;
	color:rgba(68, 68, 68, 1);
	font-size:14px;
	line-height:16px;
	font-weight:600;
}
.new__req>.inner__new--req .button__faq>a.cancel__faq{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(14, 125, 125, 1);
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: rgba(14, 125, 125, 1);
    padding: 5px 10px;
    background: #FFFFFF;
    transition: .4s ease all;
    border-radius: 3px;
}
.new__req>.inner__new--req .button__faq>a{
	margin-right:5px;
	min-width:75px;
	max-width:75px;
	min-height:40px;
	max-height:40px;
	display: inline-flex;
	align-items:center;
	justify-content:center;
}
.new__req>.inner__new--req .button__faq>a:last-child{
	margin-right: 0px;
}
.new__req>.inner__new--req .button__faq{
	display: flex;
	align-items:center;
}
.new__req>.inner__new--req>input{
	width: 100%;
	margin-right:10px;
	height: 40px;
	padding-left:15px;
	padding-right:15px;
	color:rgba(59, 59, 59, 1);
	font-size:12px;
	font-weight:600;
	line-height:18px;
	border-radius: 3px;
	border: 1px solid #0E7D7D;
}
.new__req>.inner__new--req{
	display: flex;
	align-items:center;
	justify-content:flex-start;
}
.list__inner .elem__used{
	display: flex;
	align-items:center;
	justify-content:space-between;
}
.list__inner .elem__used span{
	display: none;
}
.list__inner .elem__used span.req__span{
	display: block!important;
}
.list__inner .elem__used.current__req>a{
	border-color:#EBF1F1;
}
.list__inner .elem__used.current__req>a{
	background-color:#EBF1F1;
	border-radius:6px;
}
.duplicate__elem--used{
	display: none!important;
}
.duplicate__elem{
	display: none!important;
}
.list__inner .elem__used.current__req span{
	display: inline-flex;
	align-items:center;
	justify-content:center;
	margin-right:10px;
	min-width:24px;
}
.list__inner .elem__used{
	width: 100%;
	margin-bottom:10px;
}
.list__inner .elem__used:last-child{
	margin-bottom: 0px;
}
.new__req{
	margin-top:20px;
	display: none;
}
.selected__req{
	display: flex;
	justify-content:flex-start;
	align-items:flex-start;
	flex-wrap:wrap;
}
.selected__req .elem__selected p span{
	display: inline-flex;
	margin-left:3px;
}
.selected__req .elem__selected{
	margin-top:10px;
	margin-right:10px;
}
.selected__req .elem__selected p a{
	display: inline-flex;
	margin-left:5px;
}
.selected__req .elem__selected p{
	margin:0px;
	display: flex;
	padding:5px 10px;
	font-size:12px;
	line-height:20px;
	font-weight:600;
	color:rgba(14, 125, 125, 1);
	align-items:center;
	background: #EBF1F1;
	border-radius: 4px;
}
.counter__req.active__req{
	padding:5px 10px;
	border-radius:250px;
	background-color: #fff;
	color:rgba(14, 125, 125, 1);
	font-size:12px;
	font-weight:600;
	line-height:15px;
	display: inline-flex;
}
.assigned__info{
	position: 	relative;
}

.assigned__info .status__hint{
	left:0px;
	right: 	0px;
	margin-left: auto;
	margin-right: auto;
}
.freelance__task--progress .assigned__info>p{
		background-color: transparent!important;
}
.serv__info--text>a>p>span{
	display: 	none!important;
}
.duplicated__list{
	display: none!important;
}
.education__modal.modal__wrapper .education__inner--modal>h2{
	    font-size: 25px;
    font-weight: 700;
    color: rgba(14, 125, 125, 1);
    margin: 0px;
    margin-bottom: 30px;
}
.education__modal.modal__wrapper .education__inner--modal{
	    width: 100%;
    max-width: 660px;
    right: -670px;
    height: calc(100% - 60px);
    margin-left: auto;
    margin-top: 60px;
    background: #FFFFFF;
    box-shadow: 5px 10px 30px rgb(0 0 0 / 10%);
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    position: relative;
    overflow-y: auto;
    transition:.4s ease all;
    padding: 50px 45px;
}
.education__modal.modal__wrapper>a{
    position: absolute;
    top: 70px;
    right: 675px;
    transition:.4s ease all;
    opacity:0;
    min-width:50px;
    max-width:50px;
    min-height:50px;
    max-height:50px;
    border-radius:5px;
    display: inline-flex;
    align-items:center;
    justify-content:center;
    border:1px solid transparent;
}
.education__modal.modal__wrapper>a:active , .education__modal.modal__wrapper>a:focus{
	border-color:#fff;
}
.education__modal.modal__wrapper>a:hover{
	background: #5C5A64;
}
.education__modal.modal__wrapper{
	position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 15;
    background-color: rgba(0,0,0,.7);
}
.trash__education{
	min-width: 32px;
	max-width:32px;
	display: inline-flex;
	align-items:center;
	justify-content:center;
	min-height:32px;
	max-height:32px;
	border: 1px solid #E3E3E3;
	border-radius: 5px;
}
.number__education .trash__education{
	margin-left:10px;
}
.number__education{
	display: flex;
	align-items:center;
	justify-content:center;
}
.education__inner--modal .double__education{
	margin-bottom:15px;
}
.education__inner--modal .education__buttons>.cancel__education{
	min-width:125px;
	max-width:125px;
	min-height:45px;
}
.education__inner--modal .education__buttons>.add__education{
	margin-right:15px;
	max-width:165px;
}
.education__inner--modal .education__buttons{
	display: flex;
	margin-top:30px;
	align-items:center;
	justify-content:flex-start;
}
.education__inner--modal .education__buttons .add__education{
	min-width:165px;
	min-height:45px;
}
.education__inner--modal .double__education .form-group .input{
	height: 50px;
}
.education__inner--modal .double__education{
	display: grid;
	grid-template-columns:repeat(2 , 230px);
	grid-column-gap: 15px;
}
.modal__wrapper.education__modal .upload__block.upload-content__drag-drop__old{
	padding:0px;
	background-color:transparent;
	margin-top:30px;
	max-width:190px;
}
.modal__wrapper.education__modal .upload__block .btn-add__input_add .btn-add__input-label{
	white-space: nowrap;
}
.modal__wrapper.education__modal .upload__block .upload-content__files-upload__button{
	width: auto;
}
.modal__wrapper.education__modal .upload-content__files.upload__block{
	flex-direction:column;
}
.modal__wrapper.education__modal .upload__block .upload-content__files-list{
	max-width:100%;
	width: 100%;
}
.modal__wrapper.tax__modal>a{
	position: absolute;
    top: 70px;
    right: 675px;
    opacity:0;
    min-width:50px;
    max-width:50px;
    min-height:50px;
    max-height:50px;
    border-radius:5px;
    display: inline-flex;
    align-items:center;
    justify-content:center;
    border:1px solid transparent;
    transition:.4s ease all;
}
.modal__wrapper.tax__modal>a:active , .modal__wrapper.tax__modal>a:focus{
	border-color:#fff;
}
.modal__wrapper.tax__modal>a:hover{
	background: #5C5A64;
}
.modal__wrapper.tax__modal .tax__inner--modal>h2{
	    font-size: 25px;
    font-weight: 700;
    color: rgba(14, 125, 125, 1);
    margin: 0px;
    margin-bottom: 30px;
}
.modal__wrapper.tax__modal .tax__buttons>a.add__tax{
	max-width:195px;
	margin-right:15px;
}
.modal__wrapper.tax__modal .tax__buttons>a.cancel__tax{
	min-width:125px;
}
.modal__wrapper.tax__modal .tax__buttons{
	display: flex;
	align-items:center;
	margin-top:40px;
	justify-content:flex-start;
}
.modal__wrapper.tax__modal .tax__inner--modal .double__tax input.input{
	height: 50px;
}
.modal__wrapper.tax__modal .tax__inner--modal .double__tax{
	display: grid;
	grid-template-columns:repeat(2 , 230px);
	grid-column-gap: 15px;
}
.modal__wrapper.tax__modal .tax__inner--modal{
	   width: 100%;
    max-width: 660px;
    right: -670px;
    height: calc(100% - 60px);
    margin-left: auto;
    margin-top: 60px;
    background: #FFFFFF;
    box-shadow: 5px 10px 30px rgb(0 0 0 / 10%);
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    position: relative;
    transition:.4s ease all;
    overflow-y: auto;
    padding: 50px 45px;
}
.modal__wrapper.tax__modal{
	position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 15;
    background-color: rgba(0,0,0,.7);
}
.tag__modal.modal__wrapper>a{
	position: absolute;
    top: 70px;
    transition:.4s ease all;
    opacity:0;
    right: 675px;
    min-width:50px;
    max-width:50px;
    min-height:50px;
    max-height:50px;
    border-radius:5px;
    display: inline-flex;
    align-items:center;
    justify-content:center;
    border:1px solid transparent;
    transition:.4s ease all;
}
.tag__modal.modal__wrapper>a:active , .tag__modal.modal__wrapper>a:focus{
	border-color:#fff;
}
.tag__modal.modal__wrapper>a:hover{
	background: #5C5A64;
}
.tag__modal.modal__wrapper .tag__inner--modal .tag__search>input[type="submit"]:hover{
	opacity:.7;
}
.tag__modal.modal__wrapper .tag__buttons{
	display: flex;
	align-items:center;
	justify-content:flex-start;
	margin-top:40px;
}
.tag__modal.modal__wrapper .tag__buttons>.cancel__skill{
	min-width:125px;
}
.tag__modal.modal__wrapper .tag__buttons>a.add__tag{
	max-width:150px;
	margin-right:15px;
}
.tag__modal.modal__wrapper .tag__inner--modal .tag__search>input[type="submit"]{
	min-width:18px;
	max-width:18px;
	min-height:18px;
	max-height:18px;
	background:url('../images/searchicon.svg');
	position: absolute;
	right: 15px;
	cursor:pointer;
	transition:.4s ease all;
}
.tag__modal.modal__wrapper .tag__inner--modal .tag__search>input[type='text']{
	width: 100%;
	height: 50px;
	padding-right:40px;
    color: #444444;
    background-color: #FFFFFF;
    border-color: #DADADA;
}
.tag__modal.modal__wrapper .tag__inner--modal .tag__search{
	position: relative;
	display: flex;
	align-items:center;
}
.tag__modal.modal__wrapper .tag__inner--modal>h2{
	    font-size: 25px;
    font-weight: 700;
    color: rgba(14, 125, 125, 1);
    margin: 0px;
    margin-bottom: 30px;
}
.tag__modal.modal__wrapper .tag__inner--modal{
	 width: 100%;
    max-width: 660px;
    right: -670px;
    height: calc(100% - 60px);
    margin-left: auto;
    margin-top: 60px;
    background: #FFFFFF;
    box-shadow: 5px 10px 30px rgb(0 0 0 / 10%);
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    position: relative;
    overflow-y: auto;
    padding: 50px 45px;
    transition:.4s ease all;
}
.tag__modal.modal__wrapper{
	position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 15;
    background-color: rgba(0,0,0,.7);
}
.tag__modal .tag__list>.elem__tag a:hover{
	opacity:.7;
}
.tag__modal .tag__list>.elem__tag a{
	display: inline-flex;
	margin-left:10px;
	transition:.4s ease all;
}
.tag__modal .tag__list>.elem__tag{
	margin-right:10px;
	margin-bottom:10px;
	padding:10px 15px;
	border: 1.5px solid #0E7D7D;
	border-radius: 3px;
	display: flex;
	align-items:center;
}
.tag__modal .tag__list{
	margin-top:20px;
	margin-bottom:40px;
	display: flex;
	flex-wrap:wrap;
}
.tag__search .search__dropdown{
	display: none;
}
.tag__search .search__dropdown ul li a{
	display: flex;
	justify-content:flex-start;
	align-items:center;
	padding:10px 15px;
	color:rgba(14, 125, 125, 1);
	font-size:14px;
	line-height:21px;
	width: 100%;
	font-weight:600;
	transition:.4s ease all;
	border-radius:4px;
}
.tag__search .search__dropdown ul li a:hover{
	background: #EBF1F1;
}
.tag__search .search__dropdown ul li{
	display: flex;
	width: 100%;
}
.tag__search .search__dropdown ul{
	padding:0px;
	list-style-type: none;
	margin:0px;
	max-height:310px;
	overflow-y:auto;
	padding-right:10px;
}
.tag__search .search__dropdown ul::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;

}

/* Track */
.tag__search .search__dropdown ul::-webkit-scrollbar-track {
  background: #E9E9E9;
}

/* Handle */
.tag__search .search__dropdown ul::-webkit-scrollbar-thumb {
  background: #AEAEAE;
  border-radius:5px;
}
.tag__search .search__dropdown{
	position: absolute;
	left: 0px;
	top:60px;
	width: 100%;
	background: #FFFFFF;
	box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	z-index: 2;
	padding:20px;
	padding-right: 10px;
}
.elem__tag.duplicate__tag{
	display: none!important;
}
.placeholder__modal>.placeholder__top{
	margin-bottom:25px;
}
@keyframes shine {
	to {
	
		background-position: right -40px top 0;
	}
}
.placeholder__modal>.placeholder__top{
	margin-bottom:50px;
}
.placeholder__modal>.placeholder__bottom{
	margin-bottom:50px;
}
.placeholder__modal>.placeholder__bottom:last-child{
	margin-bottom: 0px;
}
.placeholder__modal>.placeholder__bottom .place__short{
	width: 50%;
	background-color: #e2e5e7;
	background-image: linear-gradient(90deg,rgba(255,255,255,0),rgba(255,255,255,.5),rgba(255,255,255,0));
	background-size: 40px 100%;
	background-repeat: no-repeat;
	background-position: left -40px top 0; 
	animation: shine 1s ease infinite; 
	height: 8px;
	opacity:.7;
	margin-bottom:13px;
	border-radius:5px;
}
.placeholder__modal>.placeholder__bottom .place__middle{
	width: 75%;
	background-color: #e2e5e7;
	background-image: linear-gradient(90deg,rgba(255,255,255,0),rgba(255,255,255,.5),rgba(255,255,255,0));
	background-size: 40px 100%;
	background-repeat: no-repeat;
	background-position: left -40px top 0; 
	animation: shine 1s ease infinite; 
	height: 8px;
	opacity:.5;
	margin-bottom:13px;
	border-radius:5px;
}
.placeholder__modal>.placeholder__top .place__long{
	width: 100%;
	background-color: #e2e5e7;
	background-image: linear-gradient(90deg,rgba(255,255,255,0),rgba(255,255,255,.5),rgba(255,255,255,0));
	background-size: 40px 100%;
	background-repeat: no-repeat;
	background-position: left -40px top 0; 
	animation: shine 1s ease infinite; 
	height: 8px;
	opacity:.7;
	margin-bottom:13px;
	border-radius:5px;
}
.placeholder__modal{
	position: absolute;
	left: 0px;
	top:0px;
	width: 100%;
	background-color:#fff;
	height: 100%;
	z-index: 4;
	padding:50px 45px;	
}
 /*F@{
	display: flex;
	align-items:center;
	 min-height:42px;
	 max-width:90%;
}*/
.info__remove>p a{
	position: absolute;
	right: 0px;
}
.info__remove>p{
	display: inline-flex;
	width: 100%;
	position: relative;
	padding-right:20px;
	align-items:center;
}
.invoice__info>a{
	display: inline-flex;
	margin-left:10px;
}
.invoice__info>a img{
	min-width: 10px;
}
.info__remove>p a img{
	min-width:10px;
}
.invoice__info{
	display: flex;
	align-items:center;
	max-width:105px;
}
.info-list.list__education{
	width: 100%;
}
.opened__dropdown--style svg{
	fill: #fff!important;
}
.opened__dropdown--style{
    background-color: #0a5858!important;
}
.end__buttons>.row-group__item{
	margin-right:5px!important;
}
.end__buttons>.row-group__item:last-child{
	margin-right: 0px;
}
.assignation__controls>.assign__button.disabled__assign span{
	filter: invert(72%) sepia(3%) saturate(10%) hue-rotate(314deg) brightness(89%) contrast(82%);
}
.assignation__controls>.assign__button.disabled__assign{
	pointer-events: none;
	border-color:#9E9E9E!important;
	color:rgba(158, 158, 158, 1)!important;
}
.close__filter{
	margin-left: 10px;
}
.play__style.plan__disabled{
	pointer-events: none;
	background-color:#9E9E9E!important;
}
.droppable__hover{
	position: relative;
}
.droppable__hover .droppable__small{
	display: none;
}
.droppable__hover:hover .droppable__small{
	display: block;
}
.droppable__hover .droppable__small .elem__drop--small>p{
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	font-family:"Open Sans" ,sans-serif;
}
.droppable__hover .droppable__small .elem__drop--small{
	width: 100%;
	padding:5px 15px;
	border:1px solid #E3E3E3;
	border-radius: 100px;
	display: flex;
	justify-content:center;
	align-items:center;
}
.droppable__hover .droppable__small{
	position: absolute;
	left: 50%;
    transform: translate(-50%, 0);
    min-width:200px;
    max-width:200px;
    padding:10px;
    background: #FFFFFF;
    top:35px;
    z-index: 2;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
}
.droppable__deadline>input{
	position: absolute;
	left:0px;
	top:0px;
	background-color: transparent;
	font-size:0px;
	height: 100%;
	width: 100%;
}
.empty__deadline .droppable__text--time{
	color:#3B3B3B!important;
}
.droppable__text--time{
	margin-left: 0px!important;
}
.droppable__deadline{
	position: relative;

}
.droppable__deadline .deadline__droppable.empty__deadline>p span.droppable__text--time>span:nth-child(1){
	margin-left: 0px;
	color:#3B3B3B!important;
}
.flatpickr-input.active+ .deadline__droppable.empty__deadline p span{
	opacity:1!important;
}
.deadline__droppable.today__deadline  .droppable__text--time>span:nth-child(2){
	margin-left:4px!important;
	color:#9E9E9E!important;
}
.deadline__droppable.set__deadline .droppable__text--time>span:nth-child(2){
	margin-left: 4px!important;
	color:#9E9E9E!important;
}
.droppable__deadline:hover .deadline__droppable.empty__deadline p span{
	opacity:1!important;
}
.flatpickr-input.active+ .deadline__droppable.empty__deadline p{
	padding:5px 10px!important;
    border: 1px solid #E3E3E3!important;
}
.droppable__deadline:hover .deadline__droppable.empty__deadline p{
	padding:5px 10px!important;
    border: 1px solid #E3E3E3!important;
}
.deadline__droppable.empty__deadline p span{
	transition:.3s ease all;
}
.droppable__deadline .deadline__droppable.empty__deadline>p span.droppable__text--time>span:nth-child(2){
	margin-left: 0px;
	color:#9E9E9E!important;
}
.droppable__deadline .deadline__droppable.empty__deadline>p span{
	font-size:14px;
}
.droppable__deadline .deadline__droppable.empty__deadline>p{
	border-color:transparent;
	font-size:14px!important;
	line-height: 19px!important;
	padding:0px;
	transition:.4s ease all;
}
.droppable__deadline .deadline__droppable.empty__deadline>p>span:last-child{
	opacity:0;
}
.droppable__content>.placeholder__droppable{
	position: absolute;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color:#fff;
}
.placeholder__droppable .placeholder__worker{
	padding-left: 51px;
	display: flex;
	align-items:center;
	width: 26%;
}
.placeholder__droppable .inner__placeholder{
	width: 100%;
	height: 100%;
	display: flex;
	align-items:center;
	justify-content:space-between;
}
.placeholder__droppable .placeholder__services{
	display: flex;
	justify-content:flex-start;
	align-items:center;
	min-width:135px;
	max-width:135px;
}
.placeholder__droppable .placeholder__services>.placeholder__service:last-child{
	margin-right: 0px;
}
.placeholder__droppable .placeholder__languages>.placeholder__lang:last-child{
	margin-right: 0px;
}
.placeholder__droppable .placeholder__files{
	min-width:85px;
	max-width:85px;
	margin-right:48px;
	   background-color: #e2e5e7;
    background-image: linear-gradient(
90deg
,rgba(255,255,255,0),rgba(255,255,255,.5),rgba(255,255,255,0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;
    opacity: .7;
    border-radius:4px;
    min-height:30px;
}
.placeholder__droppable .placeholder__status{
	min-width:200px;
	margin-right:35px;
	max-width:200px;
	min-height:15px;
	    background-color: #e2e5e7;
    background-image: linear-gradient(
90deg
,rgba(255,255,255,0),rgba(255,255,255,.5),rgba(255,255,255,0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;
    opacity: .7;
    border-radius:4px;
}
.placeholder__droppable .placeholder__languages>.placeholder__lang{
	min-width:15px;
	max-width:15px;
	min-height:15px;
	max-height:15px;
	border-radius:4px;
	margin-right:8px;
	    background-color: #e2e5e7;
    background-image: linear-gradient(
90deg
,rgba(255,255,255,0),rgba(255,255,255,.5),rgba(255,255,255,0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;
    opacity: .7;
}
.placeholder__droppable .placeholder__languages>.placeholder__lang--text{
	min-width:40px;
	min-height:16px;
	border-radius:4px;
	margin-right:8px;
	    background-color: #e2e5e7;
    background-image: linear-gradient(
90deg
,rgba(255,255,255,0),rgba(255,255,255,.5),rgba(255,255,255,0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;
    opacity: .7;
}

.placeholder__droppable .placeholder__languages{
	min-width:150px;
	max-width:150px;
	display: flex;
	align-items:center;
	justify-content:flex-start;
	
}
.placeholder__droppable .placeholder__services>.placeholder__service{
	min-width:25px;
	max-width:25px;
	border-radius:4px;
	margin-right:8px;
	    background-color: #e2e5e7;
    background-image: linear-gradient(
90deg
,rgba(255,255,255,0),rgba(255,255,255,.5),rgba(255,255,255,0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;
    opacity: .7;
    min-height:25px;
    max-height:25px;
}
.placeholder__droppable  .placeholder__deadline{
	min-width:160px;
	margin-right:20px;
	max-width:160px;
	height: 15px;
	border-radius:250px;
	    background-color: #e2e5e7;
    background-image: linear-gradient(
90deg
,rgba(255,255,255,0),rgba(255,255,255,.5),rgba(255,255,255,0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;
    opacity: .7;
}
.placeholder__droppable .placeholder__worker>.placeholder__info{
	width: 200px;
	height: 15px;
	border-radius:250px;
	    background-color: #e2e5e7;
    background-image: linear-gradient(
90deg
,rgba(255,255,255,0),rgba(255,255,255,.5),rgba(255,255,255,0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;
    opacity: .7;
}
.placeholder__droppable .placeholder__worker>.placeholder__image{
	min-width:35px;
	max-width:35px;
	min-height:35px;
	margin-right:10px;
	max-height:35px;
	border-radius:250px;
	    background-color: #e2e5e7;
    background-image: linear-gradient(
90deg
,rgba(255,255,255,0),rgba(255,255,255,.5),rgba(255,255,255,0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;
    opacity: .7;
}

.droppable__deadline:hover .deadline__droppable.today__deadline >p>span:last-child{
	opacity:1!important;
	margin-left: 5px!important;
	width: 13px!important;
}	
.flatpickr-input.active+.deadline__droppable.today__deadline >p>span:last-child{
	opacity:1!important;
	margin-left: 5px!important;
	width: 13px!important;
}
.flatpickr-input.active+.deadline__droppable.set__deadline >p>span:last-child{
	opacity:1!important;
	margin-left: 5px!important;
	width: 13px!important;
}
.droppable__deadline:hover .deadline__droppable.set__deadline >p>span:last-child{
	opacity:1!important;
	margin-left: 5px!important;
	width: 13px!important;
}
.deadline__droppable.today__deadline >p>span:last-child{
	opacity:0;
	margin-left: 0px;
	width: 0px;
	transition:.4s ease all;
}
.deadline__droppable.set__deadline >p>span:last-child{
	opacity:0;
	margin-left: 0px;
	width: 0px;
	transition:.4s ease all;
}
.deadline__droppable.set__deadline .droppable__text--time>span:last-child{
	margin-left: 0px!important;
}

.droppable__text--time>span:nth-child(1){
	margin-left: 0px!important;
}

.country__account .row-group__item p{
	font-weight:bold!important;
}

@media (max-width:1640px){
	.order__create--form .head__create--controls .create__info{
		padding-right:15px;
	}
	.head__create--controls .currency__picker .val__currency>input{
		width: 75px;
	}
	.workflow__table .workflow__employee{
		margin-left:-40px;
	}
	.head__order--table .service__col, .content__order--table .service__col{
		left: 0px;
	}
	.head__order--table .service__col{
		left: 5px;
	}
	.manage-skills-dropdown__box{
		right: 0px;
		left: 50%;
	    transform: translate(-50%, 0);
	}
}
@media (max-width:1440px){
	
	.workflow__table .workflow__employee{
		margin-left:-30px;
	}
	.jobs__table--box{
		overflow-x: auto;
	}
	.jobs__table--box>.jobs__table--wrapper{
		min-width:940px;
	}
	.order__table .head__order--table{
		min-width:940px;
	}
	.head__order--table .languages__col{
		position: relative;
		left: 3px;
	}
	.order__table--headwrap{
		width:100%;
		overflow-x: auto;
	}
	.order__table--headwrap .head__order--table{
		min-width:940px;
	}
	.head__order--table .rate__col{
		left: 7px;
	}
	.head__order--table .sum__col{
		left: 6px;
	}
	.head__order--table .total__col{
		left: 5px;
	}
	.head__order--table .quantity__col{
		left: 3px;
	}
	.head__order--table .deadline__col{
		position: relative;
		left: 5px;
	}
	.head__order--table .measure__col{
		left: 0px;
	}
	.content__order--table .elem__order--table , .files__order--wrapper{
		min-width:940px;
	}
	.table__order--box{
		width: 100%;
		overflow-x:auto;
	}
	.order__container .order__create--element .create__settings--inner{
		margin-top:20px;
	}
	.create__settings--inner>.inputs__row{
		margin-right: 0px;
	}
	.order__create--element .create__settings--inner{
		display: grid;
		grid-template-columns:53% 42%;
		grid-column-gap: 40px;
	}
	.create__settings--inner>.inputs__row{
		max-width:100%;
	}
	.project__holder.table-holder{
		overflow-x: auto;
	}
	.table-holder.table__stat{
		overflow-x: auto;
	}
	.wrapper__general--info .preffered__client{
		max-width:280px;
	}
	.wrapper__general--info .bank__block{
		margin-left:25px;
		margin-right:25px;
	}
	.create__settings--inner.create__settings--longer{
		display: flex;
		grid-template-columns: none;
		grid-column-gap:0px;
	}
	.create__settings--inner.create__settings--longer>.inputs__row{
		margin-right:40px;
	}
}
@media (max-width:1420px){
	.order__create--form .head__create--controls{
		flex-direction:column;
		justify-content:center;
		align-items:center;
	}
	.order__create--form .head__create--controls .create__info{
		padding-right: 0px;
		justify-content:center;
		margin-bottom:10px;
	}
}
@media (max-width:1360px){
	.workflow__table .workflow__employee{
		margin-left:-20px;
	}
	.employers__table--content{
		overflow-x:auto;
	}
	.employers__table--content>.table-holder{
		min-width:1060px;
	}
	.wrapper__general--info .preffered__client{
		max-width:240px;
	}
	.order__create--element .create__settings--inner{
		grid-template-columns:1fr;
		grid-row-gap: 20px;
	}
	.create__settings--inner .file__row{
		max-width:100%;
	}
	.order__wrapper .upload__block.upload-content__drag-drop__old{
		max-width:100%;
	}
}
@media (max-width:1280px){
	.head__part--control .currency__picker{
		margin-left: 0px;
	}
	.order__create--form .head__create--controls .create__info{
		flex-wrap:wrap;
		justify-content:center;
	}
	.head__part--control{
		width: 100%;
		justify-content:center;
		margin-top:10px;
	}
	.workflow__table .workflow__employee{
		margin-left:0px;
	}
}
@media (max-width:1200px){
	.content__order--settings .double__settings .select{
		max-width:100%;
	}
	.select__response--picker{
		max-width:100%!important;
		width: 100%!important;
		min-width: 0px!important;
	}
	.advanced__drop{
		margin-left: 25px;
	}
	.advanced__drop .advanced__main{
		min-width: 160px;
	}
	.workflow__registration>.registration__button:hover+h5{
		opacity:0;
		top:-40px;
	}
	.workflow__wrapper{
		width: 100%;
		overflow-x: auto;
	}
	.workflow__wrapper>.workflow__inner{
		min-width:940px;
	}
	.jobs__assignation .assignation__table{
		overflow-x: auto;
	}
	.jobs__assignation .assignation__wrapper{
		min-width:940px;
	}
	.employers-page__heading.order__status{
		flex-direction:column;
		justify-content:center;
		align-items:center;
	}
	.employers-page__heading.order__status>.form__progress{
		margin-bottom:25px;
	}
	.create__settings--inner.create__settings--longer{
		flex-direction:column;
	}
	.create__settings--inner.create__settings--longer>.file__row{
		max-width:100%;
		width: 100%;
	}
	.create__settings--inner.create__settings--longer>.inputs__row{
		margin-right: 0px;
		width: 100%;
		max-width:100%;
	}
	.source__box{
		flex-direction: column-reverse;
	}
	.source__box>.source__tables--container{
		width: 	100%;
		margin-top: 25px;
	}
	.source__box .source__settings{
		margin-left: 	0px;
		min-width: 	0px;
		width: 	100%;
		max-width: 100%;
	}
	.employers-page__heading.task__heading{
		width:100%;
	}
	.task__heading.employers-page__heading{
		flex-direction: column;
		justify-content:center;
		align-items:center;
	}
	.task__heading.employers-page__heading .upload__block>.wrap-btn-add{
		padding-left: 	0px;
		margin-top: 20px;
	}
	.source__table{
		overflow-x: 	auto;
	}
	.order__container .order__wrapper{
		flex-direction:column-reverse;
	}
	.order__wrapper .order__settings{
		margin-left: 0px;
		width: 100%;
		min-width: 0px;
		max-width:100%;
		margin-bottom:30px;
	}
	.order__container .order__heading{
		padding-right: 0px;
	}
	.order__container .upload__block .wrap-btn-add{
		padding-right: 0px;
		padding-left: 0px;
	}
	.table__container{
		overflow-x: scroll;
	}
	.registrations__modal>a{
		right: 655px;
	}
	.registrations__modal .inner__registrations>form .registrations__table table{
		width: 775px;
	}
	.registrations__modal .inner__registrations{
		max-width:640px;
	}
	.details__container{
		flex-direction:column;
		justify-content:flex-start;
	}
	.details__container>.element__details{
		margin-right: 0px;
		margin-bottom:40px;
	}
	.details__container>.element__details:last-child{
		margin-bottom: 0px;
	}
	.settings__head .settings__dropdown{
		margin-top:20px;
	}
	.settings-page__wrap .settings-page__heading{
		min-height:115px;
		justify-content: space-between;
	}
	.service__creation .create__service{
		margin-top:25px;
	}
	div.login-page__lang{
		margin-bottom:-40px;
	}
	.manage-skills-dropdown__box{
		min-width:320px;
	}
	.catalog__content-wrap .dropdown__box{
		right: 0px;
	}
	.wrapper__general--info{
		margin-top: 0px;
	}
	.columns__head--profile.reset__profile>.column-group__item>.link{
		margin-left: 0px;
	}
	.permissions-page__heading{
		flex-direction:column;
		justify-content:center;
		align-items:center;
	}
	.permissions-page__heading .settings-page__heading-status{
		margin-top:25px;
	}
	.wrapper__general--info{
		flex-direction:column;
		justify-content:flex-start;
	}
	.wrapper__general--info .general__block{
		max-width:100%;
		width: 100%;
	}
	.wrapper__general--info .bank__block{
		margin-left: 0px;
		margin-right: 0px;
		margin-top:30px;
		min-width: 0px;
		width: 100%;
		max-width:100%;
	}
	.wrapper__general--info .preffered__client{
		margin-top:30px;
		max-width:100%;
	}
	.settings-page__wrap .settings-page__heading{
		flex-direction:column;
		justify-content:center;
		align-items:center;
	}
	.settings-page__wrap .settings-page__heading-status{
		margin-top:25px;
	}
	.profile__head{
		flex-direction:column;
		justify-content:flex-start;
		align-items:flex-start;
	}
	.profile__head .reset__profile{
		margin-top:0px;
	}
	header .outer__header .left__header .search__header{
		max-width: 325px;
	}
	.head__create--controls.head__v--advanced{
		flex-direction:column;
		justify-content:center;
		align-items:center;
	}
	.order__create--form .head__create--controls.head__v--advanced .create__info{
		margin-bottom:10px;
	}
}
@media (max-width:991px){
	.finish__files .finish__info>p , .finish__files .finish__info>span{
		font-size:15px;
	}
	.url__modal .url__form{
		padding:25px;
	}
	.url__modal .url__form .url__box{
		min-height:200px;
	}
	.url__list{
		max-height: 120px;
	}
	.note__modal>.note__inner .note__form{
		padding:25px;
	}
	.modal__cancelation .inner__modal--cancelation{
		max-width:490px;
	}
	.modal__cancelation>a{
		right: 505px;
	}
	.correction__modal .inner__modal--correction{
		max-width: 490px;
	}
	.correction__modal>a{
		right: 	505px;
	}
	.employers-page__heading.task__heading .task__heading{
		flex-direction: column;
		justify-content:center;
		align-items:center;
	}
	.employers-page__heading.task__heading .task__heading .task__group{
		margin-right: 0px;
		margin-bottom: 5px;
	}
	.my__projects--wrapper>button{
		margin-right:5px
	}
	.heading__my--projects{
		flex-direction:column;
		justify-content:center;
		align-items:center;
	}
	.heading__my--projects>h2{
		margin-right: 0px;
		margin-bottom:25px;
	}
	.employers-page__heading-title.heading__my--projects{
		margin-bottom: 0px;
	}
	.create__project--modal>a{
		right: 500px;
	}
	.create__project--modal .inner__modal--project{
		max-width:490px;
	}
	.create__project--modal .project__dates{
		grid-template-columns:1fr;
		grid-row-gap:20px;
	}
	.create__project--modal .project__dates .input-animation{
		width: 100%;
	}
	div.login-page__lang{
		margin-bottom:20px;
		padding-right: 0px;
		max-width:500px;
		margin-left:auto;
		margin-right:auto;
	}
	.right__header .header__communication{
		margin-right: 0px;
		margin-top:20px;
	}
	.notifications-dropdown__scroll{
		max-height: 200px;
	}
	.header__notification .dropdown__box{
		min-height: 200px;
		max-height: 200px;
	}
	.container__top{
		padding-top: 15px;
		padding-left: 	0px;
		padding-right: 	0px;
	}
	.permissions-page__wrap{
		margin-top: 15px;
	}
	.permissions-page__wrap>.container{
		padding-left: 	0px;
		padding-right: 	0px;
	}
	.employers-page__wrap{
		margin-top: 	15px;
	}
	.employer-application-page__form{
		margin-top: 15px;
		padding-left: 0px;
		padding-right: 	0px;
	}
	.content__form>.container .application-page__form{
		padding-left: 0px;
		padding-right: 	0px;
	}
	.content__form>.container>.application-page__row>.application-page__form{
		margin-top: 	15px;
	}
	header .outer__header .left__header{
		margin-right:15px;
	}
	header .outer__header .left__header .search__header{
		margin-left:20px;
		max-width:240px;
	}
	.overlay{
		position: fixed;
		top:0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-color:rgba(0,0,0,.8);
		z-index: 	14;
	}
	.side__menu{
		top:0px;
		z-index: 	5;
		height: 100%;
	}
	.content__main>.menu__small{
		display: inline-flex;
	}
	header .outer__header .left__header .logo__wrapper>a{
		font-size:14px;
		white-space: nowrap;
	}
	header .outer__header .left__header .logo__wrapper>a>img{
		margin-right:8px;
		max-width:40px;
	}
	.menu__button--header{
		display: block;
		position: relative;
		z-index: 5;
	}
	.menu__button--header>a{
		display: flex;
		flex-direction:column;
		justify-content:center;
		align-items:center;
	}
	.menu__button--header>a>span:last-child{
		margin-bottom: 0px;
		top:auto;
		bottom:0px;
	}
	.menu__button--header>a.active__menu>span:nth-child(2){
		opacity:0;
	}
	.menu__button--header>a.active__menu>span:nth-child(3){
		bottom:6px;
		transform:rotate(-45deg);
	}
	.menu__button--header>a.active__menu>span:nth-child(1){
		transform:rotate(45deg);
		top:7px;
	}
	.menu__button--header>a>span{
		min-width: 24px;
		min-height:2px;
		background-color:rgba(0, 55, 55, 1);
		margin-bottom:5px;
		transition:.4s ease all;
		position: relative;
		top:0px;
		bottom:auto;
	}
	header .right__header{
		position: fixed;
		top:-100%;
		left: 0px;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction:column-reverse;
		justify-content:center;
		align-items:center;
		z-index: 3;
		background-color:#fff;
		transition:.7s ease all;
	}
	.profile__header{
		margin-bottom:20px;
	}
	header .right__header .header__notification{
		margin-right: 0px;
	}
	header .right__header .language__picker{
		margin-right: 0px;
		margin-bottom:20px;
	}
	.side__menu{
		left: -200px;
		transition:.7s ease all;
	}
	.overlay{
		display: none;
	}
	.content__main{
		padding:10px 15px;
		padding-top: 70px;
	}
	.application-page__form{
		margin-top:15px;
	}
	.manage-skills-dropdown__box{
		min-width:320px;
	}
	.settings__dropdown--wrapper{
		left: 0px;
		right: auto;
	}
}
@media (max-width: 767px){
	.invoice__info{
		max-width:100%;
	}
	.column-group__item>.list__education .info-list__item:nth-child(1) .info-list__right{
		max-width:100%;
	}
	.tag__modal.modal__wrapper .tag__inner--modal{
		max-width:420px;
	}
	.tag__modal.modal__wrapper>a{
		right: 440px;
	}
	.modal__wrapper.tax__modal .tax__inner--modal{
		max-width:420px;
	}
	.modal__wrapper.tax__modal .tax__inner--modal .double__tax{
		grid-template-columns:1fr;
		grid-row-gap:15px;
	}
	.modal__wrapper.tax__modal>a{
		right: 440px;
	}
	.education__modal.modal__wrapper .education__inner--modal{
		max-width:420px;
	}
	.education__inner--modal .double__education{
		grid-template-columns:1fr;
		grid-row-gap:15px;
	}
	.education__modal.modal__wrapper>a{
		right: 440px;
	}
	.currency__picker .select__dropdown{
		transform:none;
		left:0px;
	}
	.head__create--controls.head__v--advanced .create__info{
		flex-direction:column;
		justify-content:center;
		align-items:center;
	}
	.order__create--form .head__create--controls.head__v--advanced .create__info .check__input{
		margin-right: 0px;
		margin-bottom:15px;
	}
	.order__create--form .head__create--controls.head__v--advanced .create__info>a{
		margin-bottom:15px;
	}
	.head__v--advanced .advanced__drop{
		margin-left: 0px;
	}
	.advanced__drop .advanced__drop--box{
		left: 50%;
	    transform: translate(-50%, 0);
	}
	.order__create--form .head__create--controls.head__v--advanced .create__info .services__dropdown{
		margin-left: 0px;
		margin-bottom:15px;
	}
	.controls__employee__settings>.column-group__item:nth-child(1){
		margin-left:auto!important;
		margin-right:auto!important;
	}
	.quick__assign{
		left: 50%;
	    transform: translate(-50%, 0);
	}
	.employers-page__heading.order__status>.form__progress{
		flex-direction:column;
		justify-content:center;
		align-items:center;
	}
	.order__status .form__progress .elem__progress{
		margin-right: 0px;
		width: 100%;
		margin-bottom:15px;
	}
	.order__status .form__progress .elem__progress:last-child{
		margin-bottom: 0px;
	}
	.modal__cancelation .inner__modal--cancelation{
		padding:30px 15px;
		max-width:390px;
	}
	.modal__cancelation>a{
		right: 400px;
	}
	 .corrections__controls{
		flex-direction: column;
		justify-content:center;
		align-items:center;
	}
	 .corrections__controls>input[type="submit"]{
		max-width: 100%;
		margin-right: 	0px;
		min-width: 	0px;
		width: 	100%;
		margin-bottom: 10px;
	}
	 .corrections__controls>a{
		width: 	100%;
		max-width: 100%;
	}
	 .corrections__controls{
		margin-top: 25px;
	}
	.correction__modal .inner__modal--correction{
		max-width: 390px;
		padding:30px 15px;
	}
	.correction__modal>a{
		right: 	405px;
	}
	.order__create--form .head__create--controls{
		flex-direction:column;
		justify-content:center;
		align-items:center;
	}
	.order__create--form .head__create--controls .create__info{
		margin-bottom:20px;
	}
	.order__create--element .create__settings--inner{
		padding-left: 0px;
		padding-right: 0px;
	}
	.registrations__modal .inner__registrations{
		max-width:420px;
		padding:30px 15px;
	}
	.registrations__modal>a{
		right: 430px;
	}
	.create__project--modal .inner__modal--project{
		max-width:350px;
	}
	.create__project--modal .project__surname{
		grid-template-columns:1fr;
		grid-row-gap:20px;
	}
	.create__project--modal>a{
		right: 370px;
	}
	.create__project--modal .inner__modal--project{
		padding:30px 15px;
	}
	.settings__dropdown--wrapper{
		left: 0px;
		right: 0px;
		left: 50%;
	    transform: translate(-50%, 0);
	}
	.settings__head.column-group.profile__head{
		justify-content:center;
		align-items:center;
	}
	.settings__head .settings__dropdown{
		margin-top: 0px;
	}
	.settings__head.column-group.profile__head{
		margin-bottom:20px;
	}
	.modal__client>.inner__modal--client{
		max-width:420px;
	}
	.modal__client>a{
		right: 440px;
	}
	.modal__client form>.portal__wrapper.form-group{
		margin-bottom:50px;
	}
	.client__heading.employers-page__heading-title{
		flex-direction:column;
		justify-content:center;
		margin-bottom: 0px;
	}
	.client__heading.employers-page__heading-title>a{
		margin-top:25px;
	}
	.employers-page__heading{
		flex-direction:column;
		justify-content:center;
		align-items:center;
	}
	.employers-page__heading-title{
		margin-bottom:15px;
	}
	.left__profile>.column-group__item{
		margin-bottom: 20px;
	}
	.left__profile>.column-group__item:last-child{
		margin-bottom: 0px;
	}
	div.form-box__loading{
		min-height:250px;
	}
	div.login-page{
		padding-top:25px;
		padding-bottom:30px;
	}
	.catalog__content-item .manage-skills-dropdown__box{
		min-width:240px;
		left: 50%;
	    transform: translate(-50%, 0);
	}
	.catalog__content-item .simplebar-content{
		padding-right: 0px!important;
	}
	.catalog__content-item .manage-skills__header{
		flex-direction:column;
		justify-content:center;
	}
	.catalog__content-item .manage-skills__search{
		margin-right: 0px;
		margin-bottom:10px;
	}
	.catalog__content-item .manage-skills__item-left{
		margin-right: 0px;
	}
	.catalog__content-item .manage-skills__create>button{
		width: 100%;
	}
	.profile__head .edit-avatar{
		margin-right: 0px;
	}
	.modal__create>a{
		right: 440px;
	}
	.inner__modal{
		max-width:420px;
	}
	.inner__modal .row__form{
		grid-template-columns:1fr;
		grid-row-gap:20px;
	}
	.price__item--form{
		width: 100%;
	}
	.inner__modal .row__form.row__form--group .float__span{
		margin-top:-25px;
	}
	.inner__modal .row__form.row__form--group>div:last-child{
		margin-bottom: 0px;
	}
	.inner__modal .row__form.row__form--group>div{
		margin-bottom:20px;
	}
	.inner__modal .row__form.row__form--group{
		flex-direction:column;
		justify-content:center;
		align-items:center;
		grid-row-gap:0px;
	}
	.settings-page__heading-title>h2  ,.permissions-page__heading-title>h2{
		font-size:24px;
	}
	.settings-page__wrap .settings-page__heading .settings-page__heading-title>h2>span , .permissions-page__heading-title>h2>span{
		margin-left:15px;
		font-size:17px;
	}
	.catalog__content-wrap .upload__block.upload-content__drag-drop__old{
		max-width:100%!important;
	}
	.name__profile .head__title--profile{
		flex-direction:column;
		justify-content:center;
		align-items:center;
	}
	.name__profile .head__title--profile>p{
		margin-left: 0px;
	}
	.catalog__content .catalog__content-item .catalog__content-wrap{
		padding-left:20px;
		padding-right:20px;
		padding-bottom:25px;
	}
	.profile__head>.left__profile{
		flex-direction:column;
		justify-content:center;
		align-items:center;
		margin-bottom:30px;
	}
	.profile__head>.left__profile{
		margin-bottom: 20px;
	}
	.profile__head .reset__profile>.column-group__item{
		display: flex;
		justify-content:center;
		align-items:center;
	}
	.columns__head--profile.reset__profile>.column-group__item>.link{
		margin-left: 0px;
	}
	.profile__head{
		justify-content:center;
		align-items:center;
	}
	.profile__head .dropdown {
		display: flex;
		justify-content:center;
		align-items:center;
	}
	.form__wrapper{
		padding-top:70px;
	}
	.header__notification .dropdown__box{
		max-height: 	100%;
	}
	.notifications-dropdown__scroll{
		max-height: 100%;
	}
	.employers-page__wrap>.container{
		padding-left: 0px;
		padding-right: 0px;
	}
	.modal__cancelation .corrections__controls>input[type="submit"]{
		min-width: 0px;
		max-width:100%;
		width: 100%;
	}
}
.upload__block .job__creation--button.btn-add_opened .btn-add__dropdown{
	display: block!important;
}
@media (max-width:480px){
	.placeholder__modal{
		padding:30px 15px;
	}
	.tag__modal.modal__wrapper .tag__buttons{
		flex-direction:column;
	}
	.tag__modal.modal__wrapper .tag__buttons{
		margin-top:25px;
	}
	.tag__modal.modal__wrapper .tag__buttons>a.add__tag{
		max-width:100%;
		margin-right: 0px;
		margin-bottom:10px;
	}
	.tag__modal.modal__wrapper .tag__buttons>.cancel__skill{
		width: 100%;
		max-width:100%;
	}
	.tag__modal.modal__wrapper .tag__inner--modal{
		max-width:290px;
		padding:30px 15px;
	}
	.tag__modal.modal__wrapper>a{
		right: 300px;
	}
	.modal__wrapper.tax__modal .tax__inner--modal{
		max-width:290px;
		padding:30px 15px;
	}
	.modal__wrapper.tax__modal>a{
		right: 300px;
	}
	.education__modal.modal__wrapper>a{
		right: 300px;
	}
	.education__modal.modal__wrapper .education__inner--modal{
		max-width:290px;
		padding:30px 15px;
	}
	.education__inner--modal .education__buttons{
		flex-direction:column;
	}
	.education__inner--modal .education__buttons .add__education{
		max-width:100%;
		margin-right: 0px;
		margin-bottom:10px;
	}
	.education__inner--modal .education__buttons>.cancel__education{
		max-width:100%;
		width: 100%;
	}
	.list__inner .elem__used>a{
		padding:10px;
	}
	.list__inner .elem__used.current__req span{
		margin-right:5px;
	}
	.settings__info.settings__requirement .select button{
		white-space: initial;
		text-align:left;
		line-height:15px;
		padding-top:7px;
		padding-bottom:7px;
		height: auto;
	}
	.new__req>.inner__new--req{
		flex-direction:column;
		justify-content:center;
		align-items:center;
	}
	.new__req>.inner__new--req>input{
		margin-right: 0px;
	}
	.new__req>.inner__new--req .button__faq{
		margin-top:10px;
		width: 100%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap:10px;
	}
	.new__req>.inner__new--req .button__faq>a.cancel__faq{
		margin-right: 0px;
	}
	.new__req>.inner__new--req .button__faq>a{
		max-width:100%;
	}
	.head__part--control{
		flex-direction:column;
		justify-content:center;
		align-items:center;
		width: 100%;
	}
	.head__part--control .currency__picker .select{
		max-width:50px;
	}
	.head__part--control .currency__picker .val__currency input{
		max-width:100%;
		width: 100%;
	}
	.head__part--control .currency__picker .val__currency{
		width: 100%;
	}
	.head__part--control .currency__picker{
		width: 100%;
		margin-bottom:5px;
		margin-right: 0px;
	}
	.head__create--controls .select.invoicing__head{
		max-width:100%;
		margin-left: 0px;
	}
	.head__create--controls .select.ex__head{
		max-width:100%;
		margin-bottom:5px;
	}
	.find__client--default .client__input>input{
		min-width:150px;
		max-width:150px;
	}
	.advanced__drop .advanced__drop--box{
		min-width:290px;
		max-width:290px;
	}
	.advanced__picker--checkboxes{
		flex-direction:column;
	}
	.advanced__picker--checkboxes .elem__advanced--picker{
		margin-right: 0px;
		width: 100%;
		margin-bottom:15px;
	}
	.advanced__picker--checkboxes .elem__advanced--picker:last-child{
		margin-bottom: 0px;	
	}
	.advanced__picker--checkboxes{
		margin-top:20px;
	}
	.drop__box--sizes{
		grid-template-columns: 1fr;
		grid-row-gap:15px;
	}
	.drop__box--sizes .drop__task .field__size>input{
		width: 100%;
	}
	.drop__box--sizes .drop__task .field__size .small__drop {
		min-width:70px;
	}
	.url__modal .url__form{
		padding:20px 15px;
	}
	.quick__assign .elem__worker{
		flex-direction:column;
	}
	.quick__assign .elem__worker .worker__left{
		width: 100%;
		justify-content:center;
		margin-bottom:15px;
	}
	.note__modal>.note__inner .note__form{
		padding:20px 15px;
	}
	.controls__employee__settings{
		flex-direction:column;
		justify-content:center;
		align-items:center;
	}
	.controls__employee__settings .column-group__item{
		margin-top:15px;
		margin-left: 0px;
	}
	.create__project--modal .controls__create{
		margin-top:20px;
	}
	.modal__cancelation .inner__modal--cancelation{
		max-width:310px;
	}
	.modal__cancelation>a{
		right: 320px;
	}
	.correction__modal .inner__modal--correction{
		max-width: 310px;
	}
	.correction__modal>a{
		right: 	320px;
	}
	.employers-page__heading.task__heading>.task__heading{
		margin-right: 	0px;
	}
	.employers-page__heading.task__heading .task__heading .task__group{
		flex-direction: column;
		justify-content:center;
		align-items:center;
	}
	.employers-page__heading.task__heading .task__heading .task__group>a{
		margin-right: 	0px;
		margin-bottom: 10px;
	}
	.employers-page__heading.task__heading .task__heading .task__group>span{
		margin-left: 	0px;
		margin-top: 10px;
	}
	.requirement__dropdown{
		padding:20px 15px;
	}
	.create__settings--inner>.inputs__row{
		min-width: 0px;
	}
	.order__create--form .head__create--controls .create__info{
		flex-direction:column;
		justify-content:center;
		align-items:center;
	}
	.order__create--form .head__create--controls .create__info .check__input{
		margin-bottom: 15px;
		margin-right: 0px;
	}
	.order__create--form .head__create--controls .create__info>a{
		margin-bottom:15px;
	}
	.order__create--form .head__create--controls .create__info .services__dropdown{
		margin-left: 0px;
	}
	.order__create--element .create__settings--inner .double__settings{
		grid-template-columns:1fr;
		grid-row-gap:20px;
	}
	.registrations__modal .inner__registrations{
		max-width:310px;
	}
	.registrations__modal>a{
		right: 320px;
	}
	.controls__projects{
		flex-direction:column;
		justify-content:center;
		align-items:center;
		padding-right: 0px;
	}
	.controls__projects>.archive__button{
		margin-right: 0px;
		margin-bottom:15px;
	}
	.controls__projects>p{
		margin-right:0px;
		margin-bottom:15px
	}
	.create__project--modal .inner__modal--project{
		max-width:310px;
	}
	.create__project--modal>a{
		right: 320px;
	}
	.inner__modal--client .select__client{
		flex-direction:column;
	}
	.inner__modal--client .select__client>p{
		width: 100%;
		margin-bottom:10px;
		margin-right: 0px;
	}
	.modal__client form>.portal__wrapper.form-group{
		flex-direction:column;
	}
	.portal__wrapper.form-group .form-group__header{
		margin-right: 0px;
		width: 100%;
		margin-bottom: 10px;
	}
	.form-group.portal__wrapper  .row-group{
		width: 100%;
	}
	.modal__client form>.portal__wrapper.form-group{
		margin-bottom:25px;
	}
	.form-group.portal__wrapper  .row-group label{
		padding-left: 0px;
	}
	.inner__modal--client .select__client .select.js-select{
		max-width:100%;
	}
	.modal__client>.inner__modal--client{
		max-width:290px;
		padding:30px 15px;
	}
	.modal__client>a{
		right: 300px;
	}
	.modal__client .double__client{
		grid-template-columns:1fr;
		grid-row-gap:20px;
	}
	.controls__permissions.upload__block .btn-add__input_add .btn-add__input-label{
		padding-right: 0px!important;
	}
	.login-page .form-box{
		padding-left:25px;
		padding-right:25px;
	}
	.settings-page__wrap .settings-page__heading .settings-page__heading-title{
		flex-direction:column;
		justify-content:center;
		width: 100%;
	}
	.settings-page__wrap .settings-page__heading .settings-page__heading-title>a{
		margin-right: auto;
		margin-left:0px;
		margin-bottom:10px;
	}
	.controls__create{
		flex-direction:column;
		justify-content:center;
		align-items:center;
	}
	.controls__create>input[type="submit"]{
		margin-right: 0px;
		margin-bottom:10px;
		width: 100%;
		max-width:100%;
	}
	.controls__create>button{
		width: 100%;
		max-width: 100%;
	}
	.modal__create>a{
		right: 300px;
	}
	.inner__modal{
		padding:30px 15px;
	}
	.inner__modal{
		max-width:290px;
	}
	.settings-page__heading-title>h2 , .permissions-page__heading-title>h2{
		font-size:21px;
		display: flex;
		flex-direction:column;
		justify-content:center;
		align-items:center;
	}

	.settings-page__wrap .settings-page__heading .settings-page__heading-title>h2>span , .permissions-page__heading-title>h2>span{
		margin-left: 0px;
		margin-top:10px;
		font-size:14px;
	}
	.reset__profile .buttons__columns .column-group__item{
		flex-direction:column;
		justify-content:center;
	}
	.reset__profile .buttons__columns .js-suspend-buttons{
		margin-right: 0px;
		margin-bottom:10px;
	}
	.wrapper__general--info{
		margin-top: 0px;
	}
	.upload__block .wrap-btn-add{
		padding-left:20px!important;
		padding-right:20px!important;
	}
	.upload__block .upload-content__files-list{
		margin-bottom:20px!important;
	}
	.upload__block .btn-add__input_add .btn-add__input-label{
		padding-right:15px!important;
	}
	.upload__block.upload-content__files{
		flex-direction:column!important;
	}
	header .outer__header .left__header .search__header form input[type="text"]{
		font-size:13px;
		padding-left:15px;
		padding-right:30px;
	}
	header .outer__header .left__header .search__header{
		margin-left:10px;
	}
	header .outer__header .left__header .search__header form input[type="submit"]{
		right: 10px;
	}
	header .outer__header .left__header .logo__wrapper>a{
		font-size:13px;
	}
	.registrations__modal>.inner__registrations>form .registrations__table>p{
		font-size:15px;
		line-height:23px;
		margin-bottom:15px;
	}
	.modal__cancelation .inner__modal--cancelation .reason__cancelation{
		flex-direction:column;
		justify-content:center;
		align-items:center;
	}
	.reason__cancelation .elem__cancelation:last-child{
		margin-bottom: 0px;
	}
	.reason__cancelation .elem__cancelation{
		margin-right: 0px;
		min-width: 0px;
		max-width:100%;
		width: 100%;
		margin-bottom:10px;
	}
	.reason__cancelation .elem__cancelation>p{
		text-align:center;
	}
	.quick__assign{
		min-width:290px;
		max-width:290px;
	}
	.modal__wrapper.tax__modal .tax__buttons{
		flex-direction:column;
		justify-content:center;
		align-items:center;
	}
	.modal__wrapper.tax__modal .tax__buttons>a.add__tax{
		max-width:100%;
		width: 100%;
		margin-bottom:10px;
		margin-right: 0px;
	}
	.modal__wrapper.tax__modal .tax__buttons>a.cancel__tax{
		width: 100%;
		max-width:100%;
	}
}

@media (max-width:360px){
	.modal__wrapper.tax__modal .tax__inner--modal{
		max-width:260px;
		padding:30px 15px;
	}
	.modal__wrapper.tax__modal>a{
		right: 270px;
	}
	.education__modal.modal__wrapper .education__inner--modal{
		max-width:260px;
	}
	.education__modal.modal__wrapper>a{
		right: 270px;
	}
	.service__drop--wrapper .service__dropdown{
		left: 50%;
	    transform: translate(-50%, 0);
	}
	.modal__cancelation .inner__modal--cancelation{
		max-width:260px;
	}
	.modal__cancelation>a{
		right: 270px;
	}
	.correction__modal .inner__modal--correction{
		max-width: 260px;
	}
	.correction__modal>a{
		right: 	270px;
	}
	.registrations__modal .inner__registrations{
		max-width:260px;
	}
	.registrations__modal>a{
		right: 270px;
	}
	.modal__client>.inner__modal--client{
		max-width:260px;
	}
	.modal__client>a{
		right: 270px;
	}
	.inner__modal{
		max-width:260px;
	}
	.modal__create>a{
		right: 270px;
	}
	.create__project--modal .inner__modal--project{
		max-width:260px;
	}
	.create__project--modal>a{
		right: 270px;
	}
}